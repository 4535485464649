<template>
  <div id="sidebar">
    <h2>{{$t(title)}}</h2>
    <div id="sidemenu">
      <p class="tit jsMMenuText">
        <a href="javascript:void(0);" @click="doMobilMenu()" class="jsMMenuBtn" >{{subMenuBreadcrumbTitle != null && subMenuBreadcrumbTitle != ''?$t(subMenuBreadcrumbTitle):$t(subMenuTitle)}}</a>
      </p>
      <transition name="slide" @before-leave="onBeforeLeave" @after-leave="onAfterLeave">
        <ul v-show="isShow" class="mobileLeftMenu">
          <li v-for="(item, currentIdx, index) in leftMenu" :key="index" :class="doLinkClass(item, index)">
            <a href="javascript:void(0);" v-if="item.hiddenYn != 'Y'" @click="doLeftLink(item, index)" v-html="$sanitize($t(item.mulLangCd))"></a>
          </li>
        </ul>
      </transition>
      <ul class="pcLeftMenu">
        <li v-for="(item, currentIdx, index) in leftMenu" :key="index" :class="doLinkClass(item, index)">
          <a href="javascript:void(0);" v-if="item.hiddenYn != 'Y'" @click="doLeftLink(item, index, false)" v-html="$sanitize($t(item.mulLangCd))"></a>
        </li>
      </ul>
      <div class="sub_ban">
        <ul>
          <li>
            <a href="javascript:void(0);" @click="doContentLink('M0008', 'M0007', null)" class="icon01">{{$t('recruitInfo')}}</a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="doContentLink('M0010', 'M0006', '106')" class="icon02">{{$t('infoDisclo')}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    tabindex: {
      type: Number,
      default: -1
    }
  },
  created() {
    const upMenuId = this.$route.query?.upMenuId;
    const menuId= this.$route.query?.menuId;

    if(upMenuId != null && upMenuId != ''){
      this.searchParam.upMenuId = upMenuId
    }
    if(menuId != null && menuId != ''){
      this.searchParam.menuId = menuId
    }
    
    this.doLeftMenuList()
  },
  computed: {
    upMenuId(){
      return this.$route.query?.upMenuId;
    },
    menuId(){
      return this.$route.query?.menuId;
    },
    ...mapGetters({
      treeData: 'treeData',
      subMenuTitle: 'subMenuTitle',
      subMenuBreadcrumbTitle: 'subMenuBreadcrumbTitle',
    })
  },
  watch:{
    // 메뉴 변경 시 제목 세팅
    menuId(newVal) {
      if(newVal != null && newVal != '') {
        this.setTitle();

        if(this.isShow) {
          this.doMobilMenu();
        }
      }
    },
    // 대메뉴 변경시 사이드바메뉴리스트가 수정되야함
    upMenuId(newVal) {
      if(newVal != null && newVal != ''){
        this.searchParam.upMenuId = newVal
        this.doLeftMenuList()
      } 
    },
    // 새로고침 위해서 필요
    treeData(newVal) {
      if(newVal != null && newVal != ''){
        this.doLeftMenuList()
      } 
    }
  },
  data() {
    return {
      title: '',
      leftMenu: [],
      searchParam: {
        upMenuId: '',
        menuId: '',
      },
      isMobile: false,
      isShow: false,
    }  
  },
  mounted() {
    this.setTitle()
  },
  methods: {
    // 사이드바 메뉴 세팅
    doLeftMenuList() {
      const upMenu = this.treeData[this.upMenuId];
      this.isMobile = /Mobi/i.test(window.navigator.userAgent);
      
      if(upMenu != null){
        this.title = upMenu.mulLangCd
        this.leftMenu = upMenu.subMenu
        this.setTitle()
      }
    },
    // 상단 제목 세팅
    setTitle() {
      const upMenu = this.treeData[this.upMenuId]
      if(upMenu != null) {
        let menuInfo = upMenu.subMenu[this.menuId]

        if(this.upMenuId == this.menuId){
          menuInfo = Object.entries(upMenu.subMenu)[0][1];
        }
        
        this.$store.dispatch('setSubMenuTitle', menuInfo?.mulLangCd)
        this.$store.dispatch('setSubMenuBreadcrumbTitle', '')
        this.$store.dispatch('setSubMenuBreadcrumb', upMenu?.mulLangCd)
      }
    },
    // 링크 
    doLeftLink(item, index, isMobile=true) {
      const menuTypeCd = item.menuTypeCd;
      const param = {};
      param.query = {menuId: item.menuId, upMenuId: item.upMenuId, s0:item.firstGrpCd};

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();
        
        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }

        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:item.menuId}

      if(routeQuery != nextQuery || menuTypeCd =='CMS007.link'){
        this.$router.push(param)
      }
      
      this.setTitle()
      if(isMobile){
        this.isShow = !this.isShow
      }
    },
    doLinkClass(item, index) {
      if(this.upMenuId == this.menuId){
        if(index == 0){
          return ['on']
        }
        return []
      }else{
        if(item.menuId == this.menuId){
          return ['on']
        }else{
          return []
        }
      }
    },
    // 컨텐츠 링크
    doContentLink(menuId, upMenuId, depth) {
      this.$router.push({name:'content', query: {menuId: menuId, upMenuId: upMenuId, s0: depth}})
    },
    doMobilMenu() {      
      this.isShow = !this.isShow
    },
    onBeforeLeave(e) {
      this.isWait = true
    },
    onAfterLeave(e){
      this.isWait = false
    },
  },
}
</script>

<style scoped>
#container{text-align:center;}
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-enter-to, .slide-leave {
   max-height: 520%;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>