<template>
  <!-- 최상단 -->
  <div class="tmenu">
    <div id="header-top">
      <div class="toggle-wrap">
        <div class="toggle-head">
          <div class="inner">
            <img src="@/assets/img/common/eg_logo.png" ><span class="nuri-txt">이 누리집은 대한민국 공식 전자정부 누리집입니다.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="wsize">
      <ul class="sitelink">
        <li v-for="(item, index) in topLinkList" :key="index" v-html="$sanitize(item.content)" @click="openWindow(item.url)" >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    },
    topLinkList: {
      type: Array,
      default: ()=> []
    }
  },
  methods: {
    doLinkClick() {
      this.$emit("onOverlay", 'none');
    },
    openWindow(url){
      if(url == null || url == ''){
        return 
      }
      if(url.indexOf(window.location.origin) == 0 || url.indexOf('/') == 0){
        window.location.href = url
      }else{
        window.open(url)
      }
    },
    doKeydownLink(event, url) {
      if(event.keyCode == 13) {
        this.openWindow(url);
      }
    },
  }
}
</script>
