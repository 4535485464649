<template>
  <div>
    <!-- 주요역할 : 컨텐츠 분기(컨텐츠 / 게시판 분리) 재귀 대상 -->
    <component v-for="(item, index) in contentList" :key="index" :is="doComponent(item)" :contentNo="contentList[index].contentNo" :grpList="grpList" :depth="depth" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // 재귀여부
    subYn: {
      type: Boolean,
      default: false
    }, 
    // 컨텐츠 
    contents: {
      type: Array,
    }, 
    // 현재 깊이 s?와 연관됨
    depth: {
      type: Number,
      default: 0
    },
    // 그룹정보
    grpList: {
      type: Array,
      default: () => []
    },
    // 재조회 방지하기 위해 탑메뉴나 사이드바에서 조회 했을때 isFirst에 munuId set
    isFirst: {
      type: String,
      default: ''
    },
    tabindex: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      searchParam: {
        menuId: '',
        upMenuId: '',
      },
      contentList: null,
    }
  },
  computed: {
    upMenuId(){
      return this.$route.query?.upMenuId;
    },
    menuId(){
      return this.$route.query?.menuId;
    },
    ...mapGetters({
      treeData: 'treeData'
    })
  },
  watch: {
    '$route.query': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)){
          this.doContentList()
        }
      },
      deep: true
    },
    contents: {
      handler(newVal) {
        this.contentList = newVal
      },
      deep:true
    },
    treeData(newVal, oldVal) {
      if(newVal != null && newVal != ''){
        this.doContentList()
      } 
    }
  },
  created() {
    this.contentList = this.contents 
    this.doContentList()
  },
  methods: {
    // 컴포넌트 분기 처리(컨텐츠/게시판)
    doComponent(item) {
      const contentType = item.contentType
      if(contentType == 'C') {
        return 'cont-content'
      }else if(contentType == 'B') {
        return 'board-content'
      }else{
        return ''
      }
    }, 
    // 메뉴별 컨텐츠리스트 조회
    doContentList() {
      if(!this.subYn){
        // 탑메뉴나 사이드바에서 조회 했을때는 조회(ex 탭 변경시 재조회 되는거 방지하기 위해 isFirst에 값이 있는지 체크)
        if((this.isFirst != null && this.isFirst !='') || this.searchParam.menuId != this.menuId) {
          this.searchParam.menuId = this.menuId;
          this.searchParam.upMenuId = this.upMenuId;

          this.$set(this, "contentList", []);
  
          this.$tran('/site/external/contents/selectMenuContentList', this.searchParam).then(response => {
            this.$set(this, "contentList", response.data.menuContentList);
          }).catch(e => {
            this.$router.push("/404");
          })
        }
      }
    }
  },
}
</script>