import Browser from "./Browser";

export const Event = {
  cache: false,
  getEvent: function(evnt) {
    return (evnt) ? evnt : ((window.event) ? window.event : null);
  },
  getKey: function(evnt){
    evnt = Event.getEvent(evnt);
    return (evnt.which) ? evnt.which : evnt.keyCode;
  },
  stop: function (evnt){
    try{ 
      evnt.stopPropagation() 
    } catch(err){
    };
  
    evnt.cancelBubble = true;
    
    try{ 
      evnt.preventDefault() 
    } catch(err){
    };
  
    return (evnt.returnValue = false);
  },
  register: function(object, type, handler) {
    if (type == 'keypress' && (Browser.isKHTML || object.attachEvent)) {
      type = 'keydown';
    }
  
    if (type == 'mousewheel' && Browser.isGecko) {
      type = 'DOMMouseScroll';
    }

    if (!Event.cache) Event.cache = [];
  
    if (object.addEventListener) {
      Event.cache.push([object,type,handler]);
      object.addEventListener(type, handler, false);
    }
    else if (object.attachEvent)
    {
      Event.cache.push([object,type,handler]);
      object.attachEvent(['on',type].join(''),handler);
    }
    else
    {
      object[['on',type].join('')] = handler;
    }
  },
  deregister : function(object, type, handler) {
    if (type == 'keypress' && (Browser.isKHTML || object.attachEvent)) {
      type = 'keydown';
    }
  
    if (type == 'mousewheel' && Browser.isGecko) {
      type = 'DOMMouseScroll';
    }
  
    if (object.removeEventListener) {
      object.removeEventListener(type, handler, false);
    } else if (object.detachEvent) {
      object.detachEvent(['on',type].join(''), handler);
    } else {
      object[['on',type].join('')] = null;
    }
  },
  deregisterAll : function() {
    if (!Event.cache) {
      return
    }
  
    for (var i=0, n=Event.cache.length; i<n;i++){
      Event.deregister(Event.cache[i]);
      Event.cache[i][0] = null;
    }
    Event.cache.length=0
  }
} 
export default Event;