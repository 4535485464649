import PageSize from "./PageSize";
import PagePos from "./PagePos";
import Browser from "./Browser";
import Event from "./Event";

class LightBox{
  constructor(option){
    const self = this;
    self._imgs = new Array();
    self._sets = new Array();
    self._wrap = null;
    self._box  = null;
    self._img  = null;
    self._open = -1;
    self._page = new PageSize();
    self._pos  = new PagePos();
    self._zoomimg = null;
    self._expandable = false;
    self._expanded = false;
    self._funcs = {'move':null,'up':null,'drag':null,'wheel':null,'dbl':null};
    self._level = 1;
    self._curpos = {x:0,y:0};
    self._imgpos = {x:0,y:0};
    self._minpos = {x:0,y:0};
    self._expand = option.expandimg;
    self._shrink = option.shrinkimg;
    self._resizable = option.resizable;
    self._timer = null;
    self._indicator = null;
    self._overall = null;
    self._openedset = null;
    self._prev = null;
    self._next = null;
    self._hiding = [];
    self._first = false;
    return self._init(option);
  }

  
	_init(option) {
		const self = this;
		const d = document;

		if (!d.getElementsByTagName) return;

		if (Browser.isMacIE) return self;

		const links = option.imageList

		for (let i=0; i < links.length; i++) {
			const anchor = links[i];
			const num = self._imgs.length;
      const rel = 'lightbox';

			// initialize item
			self._imgs[num] = {
				src: anchor.imgUrl,
				w:-1,
				h:-1,
				title:'',
				cls:'',
				set:rel
			};

			// if (anchor.getAttribute("title")) {
				self._imgs[num].title = anchor.imgNm;
      // } else if  ( anchor.firstChild && anchor.firstChild.getAttribute && anchor.firstChild.getAttribute("title")) {
			// 	self._imgs[num].title = anchor.firstChild.getAttribute("title");
      // }

			// anchor.onclick = self._genOpener(num); // set closure to onclick event

			if (rel != 'lightbox') {
				if (!self._sets[rel]) self._sets[rel] = new Array();
				self._sets[rel].push(num);
			}
		}

		const body = d.getElementsByTagName("body")[0];
		self._wrap = self._createWrapOn(body,option.loadingimg);
		self._box  = self._createBoxOn(body,option);
		self._img  = self._box.firstChild;
		const zoommig = d.getElementById('actionImage');
		self._zoomimg = zoommig;

		return self;
	}

	_genOpener(num) {
		const self = this;
		return (() => { 
      self._show(num); 
      return false; 
    })()
	}

	_createWrapOn(obj,imagePath) {
		const self = this;
		if (!obj) {
      return null;
    }

		// create wrapper object, translucent background
    let wrap = document.getElementById("lightboxoverlay");
    if(wrap == null){
      wrap = document.createElement('div');
    }
    wrap.replaceChildren()
    
		obj.appendChild(wrap);
		wrap.id = 'lightboxoverlay';

		wrap.style.display = 'none';
		wrap.style.position = 'fixed';
		wrap.style.top = '0px';
		wrap.style.left = '0px';
		wrap.style.zIndex = '1005';
		wrap.style.width = '100%';
		wrap.style.height = '100%';

		if (Browser.isWinIE) {
      wrap.style.position = 'absolute';
    }

		Event.register(wrap,"click",(evt) => { self._close(evt); });
		// create loading image, animated image
		const imag = new Image;

		imag.onload = () => {
			const spin = document.createElement('img');
			wrap.appendChild(spin);
			spin.id = 'loadingImage';
			spin.src = imag.src;
			spin.style.position = 'relative';
			self._set_cursor(spin);
			Event.register(spin,'click',(evt) => { self._close(evt); });
			imag.onload = ()=>{};
		}
		
    if (imagePath != '') {
      imag.src = imagePath;
    }

		return wrap;
	}

	_createBoxOn(obj,option) {
		const self = this;
		if (!obj) {
      return null;
    } 

		// create lightbox object, frame rectangle
    let box = document.getElementById("lightbox");
    if(box == null){
      box = document.createElement('div');
    }
    box.replaceChildren()

		obj.appendChild(box);
		box.id = 'lightbox';
		box.style.display = 'none';
		box.style.position = 'fixed';
		box.style.zIndex = '1009';

		// create image object to display a target image
    let img = document.getElementById("lightboxImage");
    if(img == null){
      img = document.createElement('img');
    }

		box.appendChild(img);
		img.id = 'lightboxImage';
		self._set_cursor(img);

		self._show_action();
		// Event.register(img,'focus',() => { self._show_action(); });
		// Event.register(img,'mouseover',() => { self._show_action(); });
		// Event.register(img,'mouseout',() => { self._hide_action(); });
		Event.register(img,'click',(evt) => { self._close(evt); });
		// create hover navi - prev
		// if (option.previmg) {
    //   let prevLink = document.getElementById("lightboxprevLink");
    //   if(prevLink == null){
    //     prevLink = document.createElement('img');
    //   }
			
    //   box.appendChild(prevLink);
		// 	prevLink.id = 'lightboxprevLink';
		// 	prevLink.style.display = 'none';
		// 	prevLink.style.position = 'absolute';
		// 	prevLink.style.left = '9px';
		// 	prevLink.style.zIndex = '70';
		// 	prevLink.src = option.previmg;
		// 	self._prev = prevLink;
			
    //   Event.register(prevLink,'mouseover',() => { self._show_action(); });
		// 	Event.register(prevLink,'click',() => { self._show_next(-1); });
		// }
		// create hover navi - next
		// if (option.nextimg) {
    //   let nextLink = document.getElementById("lightboxprevLink");
    //   if(nextLink == null){
    //     nextLink = document.createElement('img');
    //   }
			
    //   box.appendChild(nextLink);
		// 	nextLink.id = 'lightboxnextLink';
		// 	nextLink.alt = ''
		// 	nextLink.style.display = 'none';
		// 	nextLink.style.position = 'absolute';
		// 	nextLink.style.right = '9px';
		// 	nextLink.style.zIndex = '70';
		// 	nextLink.src = option.nextimg;
		// 	self._next = nextLink;
			
    //   Event.register(nextLink,'mouseover',() => { self._show_action(); });
		// 	Event.register(nextLink,'click',() => { self._show_next(+1); });
		// }

		// create zoom indicator
		const zoomA = document.createElement('a');
		const zoom = document.createElement('img');

		box.appendChild(zoomA);
		zoomA.appendChild(zoom);
		zoom.alt = '원본크기로보기';
		self._set_cursor(zoom);
		zoom.src = self._expand;

		zoomA.id = 'actionImage';
		zoomA.title = '원본크기로보기';
		zoomA.href = 'javascript:void(0);';

		// Event.register(zoom,'focus',() => { self._show_action(); });
		
		Event.register(zoomA,'keydown',(evt) => { 
			if(evt.keyCode == 9) {
				evt.preventDefault();
				document.querySelector("#closeButton").focus()
			} 
		});
    Event.register(zoom,'mouseover',() => { self._show_action(); });
		Event.register(zoomA,'click', () => { self._zoom(); });
		Event.register(window,'resize',() => { self._set_size(true); });
		// create close button
		if (option.closeimg) {
			const closeA = document.createElement('a');
			const btn = document.createElement('img');
			
      closeA.appendChild(btn);
			btn.alt = '닫기';
			btn.src = option.closeimg;
			self._set_cursor(btn);

			
			closeA.href="javascript:void(0);"
			closeA.id = 'closeButton';
			closeA.title = '닫기';
			box.appendChild(closeA);
			
			Event.register(closeA,'keydown',(evt) => {
				if(evt.keyCode == 13) {
					self._close(evt);
				} else if(evt.keyCode == 9) {
					evt.preventDefault();
					self._zoomimg.focus()
				} 
			});
      Event.register(btn,'click',(evt) => { self._close(evt); });
		}
		// caption text
		const caption = document.createElement('span');
		
    box.appendChild(caption);
		caption.id = 'lightboxCaption';
		caption.style.display = 'none';
		caption.style.position = 'absolute';
		caption.style.zIndex = '80';
		// create effect image
		
    if (!option.effectpos){
      option.effectpos = {x:0,y:0};
    }	else {
			if (option.effectpos.x == '') option.effectpos.x = 0;
			if (option.effectpos.y == '') option.effectpos.y = 0;
		}
    
		// const effect = new Image;
		
    // effect.onload = () => {
		// 	const effectImg = document.createElement('img');

		// 	box.appendChild(effectImg);
		// 	effectImg.id = 'effectImage';
		// 	effectImg.src = effect.src;
			
    //   if (option.effectclass) {
    //     effectImg.className = option.effectclass;
    //   }
			
    //   effectImg.style.position = 'absolute';
		// 	effectImg.style.display = 'none';
		// 	effectImg.style.left = [option.effectpos.x,'px'].join('');;
		// 	effectImg.style.top = [option.effectpos.y,'px'].join('');
		// 	effectImg.style.zIndex = '90';
		// 	self._set_cursor(effectImg);
			
    //   Event.register(effectImg,'click',() => { effectImg.style.display = 'none'; });
		// };

		// if (option.effectimg != '') {
    //   effect.src = option.effectimg;
    // }

		if (self._resizable) {
      let overall = document.getElementById("lightboxOverallView");
      if(overall == null){
        overall = document.createElement('div');
      }
      overall.replaceChildren()

			obj.appendChild(overall);
			overall.id = 'lightboxOverallView';
			overall.style.display = 'none';
			overall.style.position = 'fixed';
			overall.style.zIndex = '1010';
			self._overall = overall;
			
      let indicator = document.getElementById("lightboxIndicator");
      if(indicator == null){
        indicator = document.createElement('div');
      }
      indicator.replaceChildren()

			obj.appendChild(indicator);
			indicator.id = 'lightboxIndicator';
			indicator.style.display = 'none';
			indicator.style.position = 'fixed';
			indicator.style.zIndex = '1011';
			self._indicator = indicator;
		}
		return box;
	}

	_set_photo_size() {
		const self = this;
    if (self._open == -1) {
      return;
    }
		const targ = { w:self._page.win.w - 30, h:self._page.win.h - 30 };
		const zoom = { x:15, y:15 };
		const navi = { p:9, n:9, y:0 };
		
    if (!self._expanded) { // shrink image with the same aspect
			const orig = { w:self._imgs[self._open].w, h:self._imgs[self._open].h };
			let ratio = 1.0;
			
      if ((orig.w >= targ.w || orig.h >= targ.h) && orig.h && orig.w) {
				ratio = ((targ.w / orig.w) < (targ.h / orig.h)) ? targ.w / orig.w : targ.h / orig.h;
      }

			self._img.width  = Math.floor(orig.w * ratio);
			self._img.height = Math.floor(orig.h * ratio);
			self._expandable = (ratio < 1.0) ? true : false;
			
      if (self._resizable) {
        self._expandable = true;
      }
			
      if (Browser.isWinIE) {
        self._box.style.display = "block";
      }
      self._imgpos.x = 0 + (targ.w - self._img.width) / 2;
			self._imgpos.y = 0 + (targ.h - self._img.height) / 2;
			navi.y = Math.floor(self._img.height / 2) - 10;
			self._show_caption(true);
			self._show_overall(false);
		} else { // zoomed or actual sized image
			const width  = parseInt(self._imgs[self._open].w * self._level);
			const height = parseInt(self._imgs[self._open].h * self._level);
      self._pos.x = 0
      self._pos.y = 0
			self._minpos.x = self._pos.x + targ.w - width;
			self._minpos.y = self._pos.y + targ.h - height;

      if (width <= targ.w) {
				self._imgpos.x = self._pos.x + (targ.w - width) / 2;
      } else {

				if (self._imgpos.x > self._pos.x) {
          self._imgpos.x = self._pos.x;
        } else if (self._imgpos.x < self._minpos.x) {
          self._imgpos.x = self._minpos.x;
        }

				zoom.x = 15 + self._pos.x - self._imgpos.x;
				navi.p = self._pos.x - self._imgpos.x - 5;
				navi.n = width - self._page.win.w + self._imgpos.x + 25;
				
        if (Browser.isWinIE) {
          navi.n -= 10;
        }
			}

			if (height <= targ.h) {
				self._imgpos.y = self._pos.y + (targ.h - height) / 2;
				navi.y = Math.floor(self._img.height / 2) - 10;
			} else {
				if (self._imgpos.y > self._pos.y) {
          self._imgpos.y = self._pos.y;
        }	else if(self._imgpos.y < self._minpos.y) {
          self._imgpos.y = self._minpos.y;
        }

				zoom.y = 15 + self._pos.y - self._imgpos.y;
				navi.y = Math.floor(targ.h / 2) - 10 + self._pos.y - self._imgpos.y;
			}

			self._img.width  = width;
			self._img.height = height;
			self._show_caption(false);
			self._show_overall(true);
		}

		self._box.style.left = [self._imgpos.x,'px'].join('');
		self._box.style.top  = [self._imgpos.y,'px'].join('');
		self._zoomimg.style.left = [zoom.x,'px'].join('');
		self._zoomimg.style.top  = [zoom.y,'px'].join('');
		self._wrap.style.left = self._pos.x;

		if (self._prev && self._next){
			self._prev.style.left  = [navi.p,'px'].join('');
			self._next.style.right = [navi.n,'px'].join('');
			self._prev.style.top = self._next.style.top = [navi.y,'px'].join('');
		}
	}

	_show_overall(visible) {
		const self = this;

		if (self._overall == null) {
      return;
    }

		if (visible) {
			if (self._open == -1) {
        return;
      }

			const base = 100;
			const outer = { w:0, h:0, x:0, y:0 };
			const inner = { w:0, h:0, x:0, y:0 };
			const orig = { w:self._img.width , h:self._img.height };
			const targ = { w:self._page.win.w - 30, h:self._page.win.h - 30 };
			let max = orig.w;

			if (max < orig.h) {
        max = orig.h;
      }

			if (max < targ.w) {
        max = targ.w;
      }

			if (max < targ.h) {
        max = targ.h;
      }

			if (max < 1) {
        return;
      }

			outer.w = parseInt(orig.w / max * base);
			outer.h = parseInt(orig.h / max * base);
			inner.w = parseInt(targ.w / max * base);
			inner.h = parseInt(targ.h / max * base);
			outer.x = self._pos.x + targ.w - base - 20;
			outer.y = self._pos.y + targ.h - base - 20;
			inner.x = outer.x - parseInt((self._imgpos.x - self._pos.x) / max * base);
			inner.y = outer.y - parseInt((self._imgpos.y - self._pos.y) / max * base);

      
      self._overall.style.left = [(window.innerWidth * 0.922),'px'].join('');
      self._overall.style.top  = [(window.innerHeight *0.84),'px'].join('');

			self._overall.style.width  = [outer.w,'px'].join('');
			self._overall.style.height = [outer.h,'px'].join('');
			self._indicator.style.left = [inner.x,'px'].join('');
			self._indicator.style.top  = [inner.y,'px'].join('');
			self._indicator.style.width  = [inner.w,'px'].join('');
			self._indicator.style.height = [inner.h,'px'].join('');
			self._overall.style.display = 'block'
			self._indicator.style.display = 'block';
		} else {
			self._overall.style.display = 'none';
			self._indicator.style.display = 'none';
		}
	}

	_set_size(onResize) {
		const self = this;
		
    if (self._open == -1) {
      return;
    }
		
    self._page.update();
		self._pos.update();
		
    const spin = self._wrap.firstChild;
		if (spin) {
			let top = (self._page.win.h - spin.height) / 2;
      
			if (self._wrap.style.position == 'absolute') {
        top += self._pos.y;
      }

			spin.style.top  = [top,'px'].join('');
			spin.style.left = [(self._page.win.w - spin.width - 30) / 2,'px'].join('');
		}

		if (Browser.isWinIE) {
			self._wrap.style.width  = [self._page.win.w,'px'].join('');
			self._wrap.style.height = [self._page.win.h,'px'].join('');
			self._wrap.style.top = [self._pos.y,'px'].join('');
		}

		if (onResize) {
      // self._set_photo_size();
    }
	}

	_set_cursor(obj) {
		if (Browser.isWinIE && !Browser.isNewIE) {
      return;
    }
		obj.style.cursor = 'pointer';
	}

	_current_setindex()	{
		const self = this;
		
    if (!self._openedset) {
      return -1;
    }

		const list = self._sets[self._openedset];

		for (let i=0, n=list.length; i<n; i++) {
			if (list[i] == self._open) {
        return i;
      }
		}

		return -1;
	}

	_get_setlength() {
		const self = this;
		if (!self._openedset) {
      return -1;
    }

		return self._sets[self._openedset].length;
	}

	_show_action() {
		const self = this;

    if (self._open == -1 || !self._expandable) {
      return;
    }

		if (!self._zoomimg) {
      return;
    }
		
    self._zoomimg.src = (self._expanded) ? self._shrink : self._expand;
		self._zoomimg.alt = (self._expanded) ? '한화면에보기' : '원본크기로보기';
		self._zoomimg.style.display = 'inline';
		
    const check = self._current_setindex();

		if (check > -1) {
			if (check > 0) {
        self._prev.style.display = 'inline';
      }

			if (check < self._get_setlength() - 1) {
        self._next.style.display = 'inline';
      }
		}
	}

	_hide_action() {
		const self = this;
		
    if (self._zoomimg) {
      // self._zoomimg.style.display = 'none';
    }

		if (self._open > -1 && self._expanded) {
      self._dragstop(null);
    }

		if (self._prev) {
      self._prev.style.display = 'none';
    }

		if (self._next) {
      self._next.style.display = 'none';
    }
	}

	_zoom()	{
		const self = this;
		const closeBtn = document.getElementById('closeButton');
		
    if (self._expanded) {
			self._reset_func();
			self._expanded = false;

			if (closeBtn) {
        closeBtn.style.display = 'inline';
      }

		} else if (self._open > -1) {
			self._level = 1;
			self._imgpos.x = self._pos.x;
			self._imgpos.y = self._pos.y;
			self._expanded = true;
			self._funcs.drag  = (evt) => { self._dragstart(evt) };
			self._funcs.dbl   = (evt) => { self._close(null) };

			if (self._resizable) {
				self._funcs.wheel = (evt) => { self._onwheel(evt) };
				Event.register(self._box,'mousewheel',self._funcs.wheel);
			}

			Event.register(self._img,'mousedown',self._funcs.drag);
			Event.register(self._img,'dblclick',self._funcs.dbl);
			
      if (closeBtn) {
        closeBtn.style.display = 'none';
      }
		}

		self._set_photo_size();
		self._show_action();
	}

	_reset_func()	{
		const self = this;
		
    if (self._funcs.wheel != null) {
      Event.deregister(self._box,'mousewheel',self._funcs.wheel);
    }

		if (self._funcs.move  != null) {
      Event.deregister(self._img,'mousemove',self._funcs.move);
    }

		if (self._funcs.up    != null) {
      Event.deregister(self._img,'mouseup',self._funcs.up);
    }

		if (self._funcs.drag  != null) {
      Event.deregister(self._img,'mousedown',self._funcs.drag);
    }

		if (self._funcs.dbl   != null) {
      Event.deregister(self._img,'dblclick',self._funcs.dbl);
    }

		self._funcs = {'move':null,'up':null,'drag':null,'wheel':null,'dbl':null};
	}

	_onwheel(evt)	{
		const self = this;
		let delta = 0;
		
    evt = Event.getEvent(evt);
		
    if (evt.wheelDelta) {
      delta = evt.wheelDelta/-120;
    } else if (evt.detail) {
      delta = evt.detail/3;
    }

		if (Browser.isOpera) {
      delta = - delta;
    }

		const step =(self._level < 1) ? 0.1 : (self._level < 2) ? 0.25 : (self._level < 4) ? 0.5 : 1;

		self._level = (delta > 0) ? self._level + step : self._level - step;
		
    if (self._level > 8) {
      self._level = 8;
    } else if (self._level < 0.5) {
      self._level = 0.5;
    }

		self._set_photo_size();
		
    return Event.stop(evt);
	}

	_dragstart(evt) {
		const self = this;

		evt = Event.getEvent(evt);
		self._curpos.x = evt.screenX;
		self._curpos.y = evt.screenY;
		self._funcs.move = (evnt) => { self._dragging(evnt); };
		self._funcs.up   = (evnt) => { self._dragstop(evnt); };
		Event.register(self._img,'mousemove',self._funcs.move);
		Event.register(self._img,'mouseup',self._funcs.up);

		return Event.stop(evt);
	}

	_dragging(evt) {
		const self = this;
		evt = Event.getEvent(evt);

		self._imgpos.x += evt.screenX - self._curpos.x;
		self._imgpos.y += evt.screenY - self._curpos.y;
		self._curpos.x = evt.screenX;
		self._curpos.y = evt.screenY;
		// self._set_photo_size();

		return Event.stop(evt);
	}

	_dragstop(evt) {
		const self = this;
		evt = Event.getEvent(evt);
		
    if (self._funcs.move  != null) {
      Event.deregister(self._img,'mousemove',self._funcs.move);
    }

		if (self._funcs.up    != null) {
      Event.deregister(self._img,'mouseup',self._funcs.up);
    }

		self._funcs.move = null;
		self._funcs.up   = null;
		self._set_photo_size();

		return (evt) ? Event.stop(evt) : false;
	}

	_show_caption(enable) {
		const self = this;
		const caption = document.getElementById('lightboxCaption');

		if (!caption) {
      return;
    }

		if (caption.innerHTML.length == 0 || !enable) {
			caption.style.display = 'none';
		} else { // now display caption
			caption.style.top = [self._img.height + 10,'px'].join(''); // 10 is top margin of lightbox
			caption.style.left = '0px';
			caption.style.width = [self._img.width + 20,'px'].join(''); // 20 is total side margin of lightbox
			caption.style.display = 'block';
		}
	}

	_toggle_wrap(flag) {
		const self = this;

		self._wrap.style.display = flag ? "block" : "none";

		if (self._hiding.length == 0 && !self._first) { // some objects may overlap on overlay, so we hide them temporarily.
			const tags = ['select','embed','object'];

			for (let i=0, n=tags.length; i<n; i++){
				const elem = document.getElementsByTagName(tags[i]);

				for (let j=0, m=elem.length; j<m; j++) { // check the original value at first. when alredy hidden, dont touch them
					let check = elem[j].style.visibility;
					if (!check) {
						if (elem[j].currentStyle) {
              check = elem[j].currentStyle['visibility'];
            }else if (document.defaultView) {
              check = document.defaultView.getComputedStyle(elem[j],'').getPropertyValue('visibility');
            }
					}

					if (check == 'hidden') {
            continue;
          }

					self._hiding.push(elem[j]);
				}
			}

			self._first = true;
		}

		for (let i=0, n=self._hiding.length; i<n; i++){
      self._hiding[i].style.visibility = flag ? "hidden" : "visible";
    }
	}

	_show(num) {
		const self = this;
		const imag = new Image;

		if (num < 0 || num >= self._imgs.length) {
      return;
    }

		const loading = document.getElementById('loadingImage');
	  const caption = document.getElementById('lightboxCaption');
		const effect = document.getElementById('effectImage');

		self._open = num; // set opened image number
		self._set_size(false); // calc and set wrapper size
		self._toggle_wrap(true);
		
    if (loading) {
      loading.style.display = 'inline';
    }
		
		imag.onload = () => {
			if (self._imgs[self._open].w == -1) { // store original image width and height
				self._imgs[self._open].w = imag.width;
				self._imgs[self._open].h = imag.height;
			}

			if (effect) {
				effect.style.display = (!effect.className || self._imgs[self._open].cls == effect.className) ? 'block' : 'none';
			}

			if (caption) {
        try { 
          caption.innerHTML = self._imgs[self._open].title; 
        } catch(e) {
        }
      }

			self._set_photo_size(); // calc and set lightbox size
			self._hide_action();
			self._box.style.display = "block";
			self._img.src = imag.src;
			self._img.setAttribute('title',self._imgs[self._open].title);
			self._timer = window.setInterval( () => { self._set_size(true) } , 100);
			
      if (loading) {
        loading.style.display = 'none';
      }

			if (self._imgs[self._open].set != 'lightbox') {
				const set = self._imgs[self._open].set;
        
				if (self._sets[set].length > 1) {
          self._openedset = set;
        }

				if (!self._prev || !self._next) {
          self._openedset = null;
        }
			}
			self._zoomimg.focus()
		};

		self._expandable = false;
		self._expanded = false;
		imag.src = self._imgs[self._open].src;
		
	}

	_close_box() {
		const self = this;
		self._open = -1;
		self._openedset = null;
		self._hide_action();
		self._hide_action();
		self._reset_func();
		self._show_overall(false);
		self._box.style.display  = "none";

		if (self._timer != null) {
 			window.clearInterval(self._timer);
 			self._timer = null;
		}
	}

	_show_next(direction)	{
		const self = this;
		
    if (!self._openedset) {
      return self._close(null);
    }

		const index = self._current_setindex() + direction;
		const targ = self._sets[self._openedset][index];

		self._close_box();
		self._show(targ);
	}

	_close(evt) {
		const self = this;
		const parentNum = self._open;
		if (evt != null) {
			evt = Event.getEvent(evt);
			const targ = evt.target || evt.srcElement;
			if (targ && targ.getAttribute('id') == 'lightboxImage' && self._expanded) {
        return;
      }
		}

		self._close_box();
		self._toggle_wrap(false);
		document.querySelector(`.content a[name="${parentNum}_img"]`).focus()
	}

}
export default LightBox