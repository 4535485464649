<template>
  <!-- //최상단 -->
  <div class="wsize" style="z-index:1">
    <h1 id="logo">
      <a href="/" >
        <img :src="require('@/assets/img/common/logo.png')" :alt="$t('koKoagiLogo')"/>
      </a>
    </h1>
    <div class="head_fr">
      <!-- 날씨 -->
      <div class="tweather">
        <div class="box" style="display: flex;justify-content: flex-end;margin-left: 3px;">
          <a href="javascript:void(0);" v-for="(item, index) in snsLinkList" :key="index" v-html="$sanitize(item.content)" @click="openWindow(item.url, item.newWinYn)" :title="`${item.title}_${item.newWinYn!='Y'?'페이지이동':'새창열림'}`">
          </a>
          <div class="topWeather" id="jsTopWeather">
            <p :class="`weater_icon ${icon}`"></p>
            <p class="cate">{{$t('sj')}}</p>
            <p class="number">{{arr_day1[3]}}</p>
          </div>
        </div>
      </div>
      <!--날씨-->
      <div class="weather_w">
        <div style="width:114px;"></div>
      </div>

      <!-- 메뉴영역 -->
      <div id="menu_navi">
        <div class="menu_cont">
          <template v-for="(item, index) in topMenu">
            <ul v-if="item.hiddenYn != 'Y'" :class="`oneD_cont menu${index+1}`" @mouseenter="doMouseEnter(item,index)" :key="index" >
              <li>
                <a href="javascript:void(0);" @click="doTopLink(item)" @focus="doTopLinkFocus(item,index)" :class="item.rinkClass!= null?item.rinkClass:['oneD']" v-html="$sanitize($t(item.mulLangCd))" ></a>
              </li>
              <li :style="subStyle" class="subMenu">
                <template v-for="(item2, index2) in item.subMenu">
                  <div v-if="item2.hiddenYn != 'Y'" class="menu_box" :key="`${index2}_div`">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" v-if="item2.hiddenYn != 'Y'" @click="doSubLink(item2)" v-html="$sanitize($t(item2.mulLangCd))" ></a>
                      </li>
                    </ul>
                  </div>
                </template>
                
                <!-- <template v-for="(item2, index2) in item">
                  <div v-if="item2.hiddenYn != 'Y'" class="menu_box" :key="`${index2}_div`">
                    <dl>
                      <dt v-for="(subItem, subIndex) in item2.subMenu" :key="subIndex+'-div'">
                        <a href="javascript:void(0);" v-if="subItem.hiddenYn != 'Y'" @click="doSubLink(subItem)" v-html="$sanitize($t(subItem.mulLangCd))" ></a>
                      </dt>
                    </dl>
                  </div>
                </template> -->
              </li>
            </ul>
          </template>
          <div id="twoD" class="twoD_cont" :style="subStyle" @mouseleave="doMouseLeave(true, true, $event)">
            <div class="wsize">
              <div class="con_fl">
                <p class="smtit"><span>{{$t('koagiSpc')}}</span></p>
              </div>
            </div>
          </div>
          <p class="mmobile_tit">
            <a href="javascript:void(0);" @click="doAllMenu($event)">{{subMenuBreadcrumb == null || subMenuBreadcrumb =='' ? $t('allMenu') : $t(subMenuBreadcrumb)}}</a>
          </p>
          
          <transition name="slide" @before-leave="onBeforeLeave" @after-leave="onAfterLeave">
            <div id="menu_all" v-show="isShow" @mouseleave="doMobileMouseLeave($event)">
              <p class="mmobile_close">
                <a href="javascript:void(0);" @click="doAllMenu($event)">{{subMenuBreadcrumb == null || subMenuBreadcrumb =='' ? $t('allMenu') : $t(subMenuBreadcrumb)}}</a>
              </p>
              <div class="menu_cont">
                <template v-for="(itemMb, indexMb) in topMenu">
                  <dl v-if="itemMb.hiddenYn != 'Y'" :key="indexMb">
                    <dt>{{ $sanitize($t(itemMb.mulLangCd)) }}</dt>
                    <dd>
                      <ul>
                        <li v-for="(subItemMb, subIndexMb) in itemMb.subMenu" :key="subIndexMb">
                          <a href="javascript:void(0);" v-if="subItemMb.hiddenYn != 'Y'" @click="doSubLink(subItemMb)" v-html="$sanitize($t(subItemMb.mulLangCd))"></a>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </template>
              </div>
            </div>
          </transition>
          <div class="allbtn_w">
            <div class="allbtn">
              <router-link :to="{ name: 'siteMap', query: {menuId: 'M0002', upMenuId:'M0001', s0:null}}" @click.native="doMouseLeave()"  @focus.native="doSiteMapFocus()">
                <img src="@/assets/img/common/allbtn.gif" :alt="$t('goSiteMap')" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    },
    snsLinkList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters([
      'popupInfo',
      'subMenuBreadcrumb',
    ]),
  },
  data() {
    return {
      topMenu: [],
      rinkClass: [],
      searchParam: {
        ck_weather_str : '',
      },
      arr_day1: [],
      arr_day2: [],
      arr_day3: [],
      icon: '',
      subStyle: {display: 'none'},
      isShow: false,
      isWait: false,
      totalMenuCnt: 0,
    }
  }, 
  created(){
    this.rinkClass = ['oneD'];
    this.doTopMenuList();
    this.doWeather();
  },
  methods: {
    doWeather() {
      this.$tran('/site/external/weather/selectWeather', this.searchParam).then(response => {
        const arr_str = response.data.ck_weather_str?.split("##");
        if(arr_str != null) {
          this.arr_day1 = arr_str[0].split("@");
          this.arr_day2 = arr_str[1].split("@");
          this.arr_day3 = arr_str[2].split("@");

          this.arr_day1[1] = this.arr_day1[1].replaceAll(" ","_").replaceAll("/","");
          
          if(this.arr_day1[1] == 'Partly_Cloudy') {
            this.icon = 'icon01';
          }else if(this.arr_day1[1] == 'Mostly_Cloudy') {
            this.icon = 'icon02';
          }else if(this.arr_day1[1] == 'Cloudy') {
            this.icon = 'icon03';
          }else if(this.arr_day1[1] == 'Rain') {
            this.icon = 'icon04';
          }else if(this.arr_day1[1] == 'Snow') {
            this.icon = 'icon05';
          }else if(this.arr_day1[1] == 'SnowRain') {
            this.icon = 'icon06';
          }else{
            this.icon = 'icon07';
          }

        }
      })
    },
    // 전체메뉴 조회
    doTopMenuList() {
      this.$tran('/site/external/main/selectTopMenuList','').then(response => {
        this.topMenu = response.data.treeData;
        this.totalMenuCnt = response.data.totalMenuCnt;
        this.$store.dispatch('setTreeData', this.topMenu);
      })  
    },
    // 대메뉴 링크
    doTopLink(item){
      const menuTypeCd = item.menuTypeCd;
      const param = {};
      const firstSubMenu = Object.entries(item?.subMenu)[0];
  
      if(firstSubMenu != null && firstSubMenu.length > 0){
        param.query = {menuId: firstSubMenu[0], upMenuId: item.menuId, s0:firstSubMenu[1].firstGrpCd};  
      }else{
        param.query = {menuId: item.menuId, upMenuId: item.menuId, s0:null};
      }

      param.hash = '#cont_title';

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();
        
        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }

        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:item.menuId}

      if(routeQuery != nextQuery || menuTypeCd =='CMS007.link'){
        this.$router.push(param)
      }

      this.doMouseLeave();
    },
    // 하위메뉴 링크
    doSubLink(item){
      const menuTypeCd = item.menuTypeCd;
      const param = {};
      param.query = {menuId: item.menuId, upMenuId: item.upMenuId, s0:item.firstGrpCd};
      param.hash = '#cont_title';

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();
        
        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }

        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:item.menuId}

      if(routeQuery != nextQuery || menuTypeCd =='CMS007.link'){
        this.$router.push(param)
      }

      this.isShow = false;

      this.doMouseLeave();
    },
    doMouseEnter(item,index) {
      Object.keys(this.topMenu).forEach((e) => {
        if(index != e){
          this.$set(this.topMenu[e], 'rinkClass', ['oneD'])
        }else{
          this.$set(item, 'rinkClass', ['oneD', 'over', 'active'])
        }
      }) 

      this.subStyle.display='block';
      this.$emit('onOverlay');
    },
    doMouseLeave(subDispYn=true, subMenuYn=false, e) {
      if(subMenuYn && e.offsetY < 0){
        return false;
      }
      if(subDispYn){
        this.doParentMouseLeave()
        this.$emit('onOverlay', 'none');
      }
    },
    doMobileMouseLeave(e) {
      if(e.offsetY < 0){
        return false;
      }
      
      if(!this.isWait && this.isShow){
        this.isShow = !this.isShow
      }
    },
    doParentMouseLeave() {
      this.subStyle.display='none';
      Object.keys(this.topMenu).forEach((e) => {
        this.$set(this.topMenu[e], 'rinkClass', ['oneD'])
      }) 
    },
    onBeforeLeave(e) {
      this.isWait = true
    },
    onAfterLeave(e){
      this.isWait = false
    },
    doAllMenu(e) {
      e.stopPropagation();
      e.preventDefault();
      this.isShow = !this.isShow
    },
    doTopLinkFocus(item, index) {
      this.doMouseEnter(item, index);
    },
    doSiteMapFocus() {
      this.doMouseLeave();
    },
    openWindow(url, newWinYn){
      if(url == null || url == ''){
        return 
      }
      
      if(newWinYn != 'Y'){
        window.location.href = url
      }else{
        window.open(url)
      }
    },
    doSnsKeyDown(e, url) {
      if(e.keyCode == 13){
        this.openWindow(url)
      }
    }
  }
}
</script>

<style scoped>
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-enter-to, .slide-leave {
   max-height: 100vh;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>