<template>
  <div class="board-list__pagination">
    <button type="button" class="prev-btn" @click="doPage(pagingInfo.prevPage)" @keydown="doKeydownLink($event, pagingInfo.prevPage)" :disabled="pagingInfo.prevPage < 1" title="Prior to page 10"></button>
    <div class="paginations">
      <template v-for="(index) in (pagingInfo.lastPage - pagingInfo.firstPage) + 1">
        <span v-if="(index+pagingInfo.firstPage)-1 == currentPage" class="pagination-btn active" :key="index">
          {{(index+pagingInfo.firstPage)-1}}
        </span>
        <button v-else @click="doPage((index+pagingInfo.firstPage)-1)" class="pagination-btn" @keydown="doKeydownLink($event, (index+pagingInfo.firstPage)-1)" :key="index" :title="`${(index+pagingInfo.firstPage)-1} Page`">
          {{(index+pagingInfo.firstPage)-1}}
        </button>
      </template>
    </div>
    <button type="button" class="next-btn" @click="doPage(pagingInfo.nextPage)" @keydown="doKeydownLink($event, pagingInfo.nextPage)" :disabled="pagingInfo.nextPage < 1" title="Next on page 10"></button>
  </div>
</template>

<script>
export default {
  props: {
    pagingInfo: {
      type: Object
    },
    currentPage: {
    },
  },
  methods: {
    doPage(index) {
      this.$emit("input", index)
    },
    doKeydownLink(event, index) {
      if(event.keyCode == 13) {
        this.doPage(index);
      }
    }
  }
}
</script>