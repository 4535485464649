<template>
  <div class="agree_ok" v-if="terms != null">
    <div style="overflow: scroll;height: 448px;" v-html="$sanitize(terms.ctnt)"></div>
    
    <div class="gray_box mt10">
      <strong>[{{$t('requ')}}]{{terms.termsNm}}{{$t('message.requTermsAgree')}}</strong>
      <input type="radio" ref="agreeOk1Y" id="agree_ok1_Y" name="agree_ok1" v-model="requApprYn" value="Y" style="margin-left: 20px;" @change="onChange('requApprYn', $event)" >
        <label for="agree_ok1_Y">{{$t('agree')}}</label>
      <input type="radio" id="agree_ok1_N" name="agree_ok1" v-model="requApprYn" value="N" @change="onChange('requApprYn', $event)" >
        <label for="agree_ok1_N">{{$t('noAgree')}}</label>
    </div>
    <div class="gray_box mt10">
      <div class="agroup">
        <strong>[{{$t('select')}}]{{terms.termsNm}}{{$t('message.selTermsAgree')}}</strong>
        <input type="radio" id="agree_ok2_Y" name="agree_ok2" v-model="selApprYn" value="Y" style="margin-left: 20px;" @change="onChange('selApprYn',$event)" >
          <label for="agree_ok2_Y">{{$t('agree')}}</label>
        <input type="radio" id="agree_ok2_N" name="agree_ok2" v-model="selApprYn" value="N" @change="onChange('selApprYn', $event)" >
          <label for="agree_ok2_N">{{$t('noAgree')}}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
  created() {
    this.doTerms()
  },
  data() {
    return {
      searchParam: {
        termsTypeCd: 'CMS010.01'
      },
      terms: {
        ctnt: '',
        termsNm: '',
      },
      requApprYn: 'N',
      selApprYn: 'N'
    }
  },
  methods: {
    doTerms() {
      this.$tran('/site/external/contents/selectTerms', this.searchParam).then(response => {
        this.terms = response.data.terms
        this.$emit('input', response.data.terms.termsNo)
      })
    },
    onChange(name, event) {
      this.$emit("changeAgreeYn", name, event.target.value)
    },
    focus(){
      this.$refs.agreeOk1Y.focus()
    }
  },
}
</script>
