<template>
  <!-- Header -->
  <header class="c-header">
    <div class="layout">
      <h1 class="logo">
        <a href="/eng" title="Main Page">
          <img src="@/assets/img/eng/logo_horizontal_wh.svg" alt="KOREA ARBORETA AND GARDENS INSTITUTE Logo"/>
        </a>
      </h1>
      <nav class="gnb-wrap" :class="topRinkClass">
        <button type="button" class="m-menu__close" @click="hideMobileGNB();" title="Close Menu"></button>
        <ul class="gnb-list" @mouseleave="doParentMouseLeave">
          <template v-for="(item, index) in topMenu">
            <li v-if="item.hiddenYn != 'Y'" @mouseenter="doMouseEnter(item, index)" :key="index">
              <a href="javascript:void(0);" @click="doTopLink(item)" @focus="doTopLinkFocus(item, index)" @keydown="doKeydownEnter($event, item, index)" :title="`${item.menuNm}_Move Page`">
                {{ item.menuNm }}
              </a>
              <ul class="sub-nav" :class="item.rinkClass">
                <template v-for="(item2, index2) in item.subMenu">
                  <li v-if="item2.hiddenYn != 'Y'" :key="index2">
                    <a href="javascript:void(0);" @click="doSubLink(item2)" @keydown="doKeydownSubLink($event, item2)" :title="`${item2.menuNm}_Move Page`">
                      {{ item2.menuNm }}
                    </a>
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>
        <ul class="gnb-utils">
          <li v-for="(item, index) in snsLinkList" :key="index">
            <a href="javascript:void(0);" :key="index" v-html="$sanitize(item.content)" @click="openWindow(item.url, item.newWinYn)" :title="`${item.title}_${item.newWinYn!='Y'?'Move Page':'New window opening'}`"></a>
          </li>
        </ul>
      </nav>
      <button type="button" class="m-menu__btn" @click="showMobileGNB();" title="Open Menu"></button>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    snsLinkList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      topMenu: [],
      topRinkClass: [],
      subStyle: {display: 'none'},
      isShow: false,
      isMobile: false,
    }
  },
  mounted() {
    // 컴포넌트가 마운트되면 모바일 여부를 확인하는 함수 호출
    this.checkIfMobile();

    // 윈도우 크기가 변경될 때마다 모바일 여부를 다시 확인
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    // 컴포넌트가 파괴되기 전에 이벤트 리스너를 제거
    window.removeEventListener('resize', this.checkIfMobile);
  },
  created(){
    this.doTopMenuList();
  },
  methods: {
    doTopMenuList() {  
      this.$tran('/site/external/main/selectTopMenuList','').then(response => {
        this.topMenu = response.data.treeData;
        this.totalMenuCnt = response.data.totalMenuCnt;
        this.$store.dispatch('setTreeData', this.topMenu);
      })  
    },
    doTopLink(item){
      const menuTypeCd = item.menuTypeCd;
      const param = {};
      let firstSubMenu = null;
      
      if(item?.subMenu != null) {
        firstSubMenu = Object.entries(item?.subMenu)[0];
        if(firstSubMenu != null && firstSubMenu.length > 0){
          param.query = {menuId: firstSubMenu[0], upMenuId: item.menuId, s0:firstSubMenu[1].firstGrpCd};  
        }else{
          param.query = {menuId: item.menuId, upMenuId: item.menuId, s0:null};
        }
      }else{
        param.query = {menuId: item.menuId, upMenuId: item.menuId, s0:null};
      }
      

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'eng_content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();
        
        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }

        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:item.menuId}

      if(routeQuery != nextQuery || menuTypeCd =='CMS007.link'){
        this.$router.push(param)
      }

      if(this.isMobile) {
        this.hideMobileGNB();
      }
    },
    //하위메뉴
    doSubLink(item){ 
      const menuTypeCd = item.menuTypeCd;
      const param = {};
      param.query = {menuId: item.menuId, upMenuId: item.upMenuId, s0:item.firstGrpCd};

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'eng_content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'eng_dept';
      }else{
        let link = item?.link.trimStart();
        
        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }

        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:item.menuId}

      if(routeQuery != nextQuery || menuTypeCd =='CMS007.link'){
        this.$router.push(param)
      }

      if(this.isMobile) {
        this.hideMobileGNB();
      }
    },
    doMouseEnter(item,index) {
      if(!this.isMobile) {
        Object.keys(this.topMenu).forEach((e) => {
          if(index != e){
            this.$set(this.topMenu[e], 'rinkClass', [])
          }else{
            if(this.topMenu[e].subMenu == null || this.topMenu[e].subMenu == '') {
              this.topRinkClass = []
            }else{
              this.topRinkClass = ['active'];
              this.$set(item, 'rinkClass', ['active'])
            }
          }
        })
      }
    },
    checkIfMobile() {
      // 윈도우 너비가 일정 값보다 작으면 모바일로 간주
      this.isMobile = window.innerWidth < 1121;
    },
    doParentMouseLeave() {
      if(!this.isMobile) {
        Object.keys(this.topMenu).forEach((e) => {
          this.$set(this.topMenu[e], 'rinkClass', [])
        }) 
        this.topRinkClass = [];
      }
    },
    openWindow(url, newWinYn){
      if(url == null || url == ''){
        return 
      }
      if(newWinYn != 'Y'){
        window.location.href = url
      }else{
        window.open(url)
      }
    },
    hideMobileGNB() {
      this.topRinkClass = [];
    },
    showMobileGNB() {
      this.topRinkClass = ['m-active'];
    },
    doTopLinkFocus(item, index) {
      this.doMouseEnter(item, index);
    },
    doKeydownEnter(event, item) {
      if(event.keyCode == 13) {
        this.doTopLink(item);
      }
    },
    doKeydownSubLink(event, item) {
      if(event.keyCode == 13) {
        this.doSubLink(item);
      }
    },
    doSnsKeyDown(e, url) {
      if(e.keyCode == 13){
        this.openWindow(url)
      }
    }
  }
}

</script>