<template>
  <div id="wrapper">
    <template v-if="showTopBanner">
      <a :href="topBanner.url" :target="topBanner.newWinYn=='Y'?'_blank':'_self'">
        <div :style="doBannerStyle(topBanner)">
          <div v-html="$sanitize(topBanner.content)"></div> 
        </div>
      </a>
      <div class="title">
        <a href="javascript:void(0);" class="topBannerClose" @click="doBannerClose()"></a>
      </div> 
    </template>
    <div id="head" @mouseleave="doMouseLeave()" :class="popupInfo=='Y'?'':headClass" :style="$route.path!='/'?{'border-bottom':'1px solid #dfdfdf'}:{}">
      <LayoutLinkTop @onOverlay="onOverlay" ref="topLink"  :topLinkList="topLinkList"/>
      <LayoutTop :dispYn="dispYn" @onOverlay="onOverlay" ref="topMenu"  :snsLinkList="snsLinkList"/>
    </div>
    <Main v-if="$route.path=='/'" id="container" />
    <div v-if="$route.path!='/'" id="container" style="text-align: center;">
      <div class="wsize">
        <!-- 사이드바영역-->
        <LayoutLeft />
        <!-- 컨텐츠영역 -->
        <div id="contents">
          <div id="print_wrap">
            <!-- 제목영역 -->
            <div id="cont_head">
              <div id="location">
                <ul>
                  <li>
                    <img src="@/assets/img/common/home_ic.gif" alt="home"/>
                  </li>
                  <li v-if="subMenuBreadcrumb != null && subMenuBreadcrumb != ''" class="breadCrumbBr" v-html="$sanitize($t(subMenuBreadcrumb))"></li>
                  <li v-if="subMenuTitle != null && subMenuTitle != ''" class="breadCrumbBr" v-html="$sanitize($t(subMenuTitle))"></li>
                  <li v-if="subMenuBreadcrumbTitle != null && subMenuBreadcrumbTitle != ''" class="breadCrumbBr" v-html="$sanitize($t(subMenuBreadcrumbTitle))"></li>
                </ul>
              </div>
              <h2 id="cont_title"  v-html="$sanitize($t(subMenuTitle))"></h2>
            </div>
            <div id="cont_wrap">
              <!--내용영역-->
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="quick" v-if="$route.path!='/'" :style="popupInfo=='Y'?'z-index:-1':''" >
      <div class="cont">
        <a href="javascript:void(0);" @click="doScrollTop()">
          <img src="@/assets/img/common/top_up.gif" :alt="$t('moveTop')"/>
        </a>
      </div>
    </div>
    <FooterSiteMap v-if="$route.path=='/'" />
    <LayoutFooter />
    <div class="header_dimmed" :style="overlayStyle"></div>
    <template v-if="$route.path =='/'">
      <template v-for="(item, index) in layerPopupList">
        <div v-if="doCookie(item)" class="layer-popup lplp" :style="item.layerStyle != null? item.layerStyle :doLayerStyle(item)" :key="index">
          <div v-html="item.content" class="layerCont"></div>
          <div class="today-close">
            <ul>
              <input type="checkbox" v-if="item.nextCloseYn == 'Y'" v-model="item.layerNextCloseYn" true-value="Y" false-value="N" >
              <label for="next_close" v-if="item.nextCloseYn == 'Y'">{{ item.nextCloseDd }}{{$t('nextCloseDdText')}}</label>
              <input type="button" value="닫기" @click="doNextClose(item, item.layerNextCloseYn)" class="ct_bt01" >
            </ul>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LayoutTop from '@/views/layouts/LayoutTop'
import LayoutFooter from '@/views/layouts/LayoutFooter'
import FooterSiteMap from '@/views/layouts/LayoutFooterSiteMap'
import LayoutLinkTop from '@/views/layouts/LayoutLinkTop'
import LayoutLeft from '@/views/layouts/LayoutLeft'
import Main from '@/views/Main'

export default {
  metaInfo() {
    return {
      title: this.metaInfo.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: this.metaInfo.ogTitle }, 
        { property: 'og:url', content: this.metaInfo.ogUrl },
        { property: 'og:description', content: this.metaInfo.ogDescription },
        { property: 'og:image', content: this.metaInfo.ogImage },
      ]
    }
  },
  components: {
    LayoutTop,
    LayoutLinkTop,
    LayoutLeft,
    LayoutFooter,
    FooterSiteMap,
    Main,
  },
  computed: {
    ...mapGetters([
      'subMenuTitle',
      'subMenuBreadcrumb',
      'subMenuBreadcrumbTitle',
      'popupInfo',
      'metaInfo'
    ]),
  },
  created() {
    this.doMainContent();
  },
  data() {
    return {
      dispYn: 'none',
      overlayStyle: {display:'none'},
      headClass: ['over'],
      topBanner: {},
      showTopBanner: false,
      layerPopupList: [],
      windowPopupList: [],
      topLinkList:[],
      snsLinkList:[],
    }  
  },
  methods: {
    doMouseLeave() {
      this.onOverlay('none')
    },
    doScrollTop() {
      window.scrollTo({top:0, behavior:'smooth'});
    },
    onOverlay(displayBlock='block'){
      this.overlayStyle.display=displayBlock;
      this.dispYn=displayBlock
      if(displayBlock != 'block'){
        this.$refs.topMenu.doParentMouseLeave()
      }
    },
    doMainContent() {
      this.$tran('/site/external/main/selectMainContent', '').then(response => {
        this.topBanner = response.data.topList[0];
        this.topLinkList = response.data.topLinkList;
        this.snsLinkList = response.data.snsLinkList;
        this.layerPopupList = response.data.layerPopupList;
        this.windowPopupList = response.data.windowPopupList;

        this.$nextTick(() => {
          const bannerNo = this.topBanner?.bannerNo;
          if(bannerNo != null && bannerNo != '') {
            this.showTopBanner = true;
          }

          if(this.windowPopupList != null && this.windowPopupList.length > 0 && this.$route.path =='/') {
            this.doWindowPopup();
          }
        })
      })
    },
    doBannerStyle(item) {
      const bannerStyle = {
        backgroundImage: `url(${encodeURI(item?.imgUrl)})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100px',
        position: 'relative',
        zIndex: '999999',
        overflow: 'inherit',
      }
      return bannerStyle;
    },
    doBannerClose() {
      this.showTopBanner = false;
    },
    doCookie(item) {
      const data = this.$cookies.get(item.popupNo);

      if(data != null) {
        return false;
      }
      return true;
    },
    doNextClose(item, layerNextCloseYn) {
      if(layerNextCloseYn == 'Y') {
        this.$cookies.set(item.popupNo, 'Y', `${item.nextCloseDd}d`);
      }
      
      this.doLayerClose(item);
    },
    doLayerClose(item) {
      item.layerStyle.display = 'none';
    },
    doLayerStyle(item) {
      const width = item.widthPixel;
      const height = item.heightPixel;
      const left = item.leftPixel;
      const top = item.topPixel;

      const layerStyle = {
        backgroundImage: `url(${encodeURI(item?.imgUrl)})`,
        width: `${width}px`,
        height: `${Number(height)+30}px`,
        left: `${left}px`,
        top: `${top}px`,
        display: 'block',
        'background-size': 'contain',
        backgroundRepeat: 'no-repeat'
      }

      this.$set(item, 'layerStyle', layerStyle);
    },
    doWindowPopup() {
      for(let i=0; i < this.windowPopupList.length; i++) {
        const getCookie = this.doCookie(this.windowPopupList[i]);

        if(getCookie) {
          const data = this.windowPopupList[i]
          const popupNo = data.popupNo;
          const imgUrl = data.imgUrl;
          const width = data.widthPixel;
          let height = data.heightPixel;
          const left = data.leftPixel;
          const top = data.topPixel;
          const content = data.content;
          const nextCloseDd = data.nextCloseDd;
          const nextCloseYn = data.nextCloseYn;

          
          if(height != null && typeof height == 'string'){
            const intData = height.replaceAll(/(-)[^\d.]/gi, '')
            if(intData != null && intData != ''){
              height = (parseFloat(intData) + 30) + height.replaceAll(/[-\d.]/gi,'')
            }
          }
          
          const win = window.open('', popupNo, `location=no,width=${width},height=${height},top=${top},left=${left}`);

          if(win.document.querySelector('.newPopupDiv') != null) {
            win.document.querySelector('.newPopupDiv').remove();
            win.document.querySelector('.popup').remove();
          }

          if(win.document.querySelector('.footer') != null) {
            win.document.querySelector('.footer').remove();
          }

          if(win.document.querySelector('script') != null) {
            win.document.querySelector('script').remove();
          }

          if(win.document.querySelector('style') != null) {
            win.document.querySelector('style').remove();
          }
          
          const htmlTag = `
                    <style>
                      body {
                        margin: 0px;
                      }

                      img {
                        width: 100%;
                      }

                      p br{
                        display:none;
                      }

                      table, pre,div {
                        color: #575757; 
                        font-size: 1em;
                      }

                      .newPopupDiv {
                        height: calc(100% - 30px);
                        width: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-image: url(${encodeURI(imgUrl)});
                      }

                      #next_close {
                        margin-top: 9px;
                      }

                      div.popup {
                        clear: both;
                        position: relative;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        text-align: right;
                      }

                      div.popup .ct_bt01 {
                        margin-right:10px;
                        margin-left:10px;
                      }

                      @media(max-width: 736px) {
                        .mmimg_w {
                          width:100% !important;
                          height: calc(100% - 30px) !important;
                          top: 0 !important;
                          left: 0 !important;
                        }

                        .mmimg_w, .mmimg_in, .mmmimg{
                          width: 100% !important;
                          height: calc(100% - 30px) !important;
                        }

                        .mmmimg p, .mmmimg img {
                          width: 100% !important;
                          height: auto;
                        }
                      }
                  </style>
                  <div>
                    <div class="mmmimg newPopupDiv">
                      ${content==null?'':content}
                    </div> 
                    <div class="popup">
                      ${nextCloseYn=='Y'? `<input type="checkbox" id="next_close">${nextCloseDd} ${this.$t('nextCloseDdText')}`:''}
                      <input type="button" value="닫기" onClick="closeToday()" class="ct_bt01">
                    </div>  
                  </div> 
                  <script type="text/javascript">
                    function closeToday() {
                      ${nextCloseYn=='Y'? `var isNextClose = document.getElementById('next_close').checked;

                      if(isNextClose) {
                        var todayDate = new Date();
                        todayDate.setDate( todayDate.getDate() + ${nextCloseDd} );
                        document.cookie = name + "= Y" + "; path=/; expires=" + todayDate.toGMTString() + ";"
                      }
                      `:''}
                      window.close();
                    }
                  <\/script>`;
          win.document.write(htmlTag);
        }
      }
    }, 
  },
}
</script>

<style scoped>
#container .wsize{display:inline-block;}
</style>