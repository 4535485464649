<template>
  <table cellspacing="0" :summary="summary" class="tableH">
    <caption>{{title}}</caption>
    <template>
      <colgroup v-if="!isModify">
        <slot name="colgroup">
          <col v-for="(item, index) in fields" :key="`col_${index}`" :class="setMoblieClass(item)" :width="item.width">
        </slot>
      </colgroup>
      <thead v-if="!isModify">
        <tr>
          <th scope="col" v-for="(item, index) in fields" :key="`th_${index}`" :class="setMoblieClass(item)">{{$t(item.label)}}</th>
        </tr>
      </thead>
      <thead v-else>
        <tr>
          <th scope="col" class="th_end" colspan="3">{{$t('write')}}</th>
        </tr>
      </thead>
      <tbody v-if="!isModify">
        <template v-if="items.length > 0">
          <tr v-for="(item, index) in items" :key="`tr_${index}`">
            <td v-for="(item2, index2) in fields" :key="`td_${index2}`" :class="tdClass(item, item2)" :style="setTdStyle(item2)">
              <slot name="body" :data="getItem(item, item2)" :tindex="11"></slot>
            </td>
          </tr>
        </template>  
        <tr v-else>
          <td class="center" :colspan="fields.length">{{$t('noContent')}}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <slot name="body"></slot>
      </tbody>
    </template>
  </table>  
</template>

<script>
export default {
  props: {
    summary: {
      type: String
    },
    title: {
      type: String
    },
    fields: {
    },
    items: {
    },
    isModify: {
      type: Boolean,
      default: false,
    },
    board: {

    }
  },
  methods: {
    getItem(item, item2){
      item.value=item[item2.key]
      item.key=item2.key
      item.type=item2.type
      item.fieldData = item2.fieldData

      return item
    },
    tdClass(item, item2) {
      const key = item2.key;
      const type = item2.type;
      const align = item2.align;
      let result = [align];

      // 공지여부
      if(item.noticeDispOver == 'N' && this.board.noticeYn == 'Y') {
        result.push('notice');

        // 게시판컬럼:제목 
        if(key == 'field03') {
          result.push('title');
        }
      }

      if(key == 'image') {
        result.push('mobile_no pic');
      }

      if(item2.mobileExclYn == 'Y') {
        result.push('mobile_no');
      }
      
      return result
    },
    setTdStyle(item) {
      let align = item.align;

      if(align == null || align == '') {
        align = 'center'
      }

      return {
        'text-align': align
      }
    },
    setMoblieClass(item) {
      if(item.key == 'image' || item.mobileExclYn == 'Y') {
        return 'mobile_no'
      }
      
      return 
    },
  },
}
</script>