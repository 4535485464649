import WindowSize from "./WindowSize";

class PageSize{
  constructor(){
    this.win = new WindowSize();
    this.w = 0;
    this.h = 0;
    return this.update();
  }

  update(){
    const d = document;
    this.w = 
      (window.innerWidth && window.scrollMaxX) ? window.innerWidth + window.scrollMaxX
    : (d.body.scrollWidth > d.body.offsetWidth) ? d.body.scrollWidth
    : d.body.offsetWidt;
    this.h = 
      (window.innerHeight && window.scrollMaxY) ? window.innerHeight + window.scrollMaxY
    : (d.body.scrollHeight > d.body.offsetHeight) ? d.body.scrollHeight
    : d.body.offsetHeight;
    this.win.update();
    if (this.w < this.win.w) this.w = this.win.w;
    if (this.h < this.win.h) this.h = this.win.h;

    return this;
  };
}
export default PageSize