<template>
  <!--하단 사이트맵-->
	<div class="foot_sitemap">
		<div class="area">
			<div class="fl">
				<div class="title">
					<p class="stxt">{{$t('koagi')}}</p>
					<p class="btxt">{{$t('goService')}}</p>
				</div>
				<div class="fsnslink_w">
					<ul class="fsnslink">
						<li class="blog">
              <a href="https://blog.naver.com/koagi1" target="_blank" >{{$t('orgnBlog')}}</a>
            </li>
						<li class="grow">
              <a href="@/assets/img/main2021/kiamgrowing_down.jpg" target="_blank">{{$t('growPlants')}}</a>
            </li>
					</ul>
				</div>
				<div class="select">
					<div class="box">
            <a href="javascript:void(0);" @click="doSiteWClick()" >{{$t('relatedSite')}}</a>
          </div>
          <transition name="slide">
            <div class="selct_cnt" v-show="isShow">
              <ul>
                <li>
                  <a href="http://www.forest.go.kr/" target="_blank" :title="`${$t('forest')}_새창열림`">{{$t('forest')}}</a>
                </li>
                <li>
                  <a href="https://www.kofpi.or.kr/" target="_blank" :title="`${$t('kofpi')}_새창열림`">{{$t('kofpi')}}</a>
                </li>
                <li>
                  <a href="http://www.fowi.or.kr/" target="_blank" :title="`${$t('fowi')}_새창열림`">{{$t('fowi')}}</a>
                </li>
                <li>
                  <a href="https://kna.forest.go.kr/" target="_blank" :title="`${$t('nationalForest')}_새창열림`">{{$t('nationalForest')}}</a>
                </li>
                <li>
                  <a href="http://daslim.fowi.or.kr/" target="_blank" :title="`${$t('daslimFowi')}_새창열림`">{{$t('daslimFowi')}}</a>
                </li>
                <li>
                  <a href="http://www.ipet.re.kr/" target="_blank" :title="`${$t('ipet')}_새창열림`">{{$t('ipet')}}</a>
                </li>
                <li>
                  <a href="http://www.rda.go.kr/" target="_blank" :title="`${$t('rda')}_새창열림`">{{$t('rda')}}</a>
                </li>
                <li>
                  <a href="http://www.nature.go.kr/" target="_blank" :title="`${$t('natureInfo')}_새창열림`">{{$t('natureInfo')}}</a>
                </li>
                <li>
                  <a href="http://www.nature.go.kr/kpni/index.do" target="_blank" :title="`${$t('natureKpni')}_새창열림`">{{$t('natureKpni')}}</a>
                </li>
                <li>
                  <a href="http://gis.kofpi.or.kr/gis/main.do" target="_blank" :title="`${$t('kofpiGis')}_새창열림`">{{$t('kofpiGis')}}</a>
                </li>
                <li>
                  <a href="http://fgis.forest.go.kr" target="_blank" :title="`${$t('forestSpaceInfo')}_새창열림`">{{$t('forestSpaceInfo')}}</a>
                </li>
                <li>
                  <a href="https://ftis.forest.go.kr/ftisWebApp/main/main.do" target="_blank" :title="`${$t('forestScience')}_새창열림`">{{$t('forestScience')}}</a>
                </li>
              </ul>
            </div>
          </transition>
				</div>
			</div>
			<div class="sitemap">
        <template v-for="(item, index) in treeData">
          <dl v-if="item.hiddenYn != 'Y'" :key="`dl_${index}`"> 
            <dt>{{$t(item.mulLangCd)}}</dt>
            <dd v-for="(subItem, subIndex) in item.subMenu" :key="`dd_${subIndex}`">
              <a href="javascript:void(0);" v-if="subItem.hiddenYn != 'Y'" @click="doLink(subItem)"  v-html="$sanitize($t(subItem.mulLangCd))" :title="subItem.menuNm"></a>
            </dd>
          </dl>
        </template>
			</div>

			<!-- 하단_신고센터 -->
			<div class="foot_report">
				<ul>
					<li>
            <a href="https://ncp.clean.go.kr/cmn/secCtfcKMC.do?menuCode=acs&mapAcs=Y&insttCd=B554620" target="_blank" class="ico01" >
              {{$t('nationalCommittee')}}<br/>{{$t('CorruptionReport')}}
            </a>
          </li>
					<li>
            <a href="javascript:void(0);" @click="doContentLink('M0013', 'M0012', 4767)" class="ico02">
              {{$t('koagi')}}<br/>{{$t('cleanReportCenter')}}
            </a>
          </li>
				</ul>
			</div>
			<!-- //하단_신고센터 -->
	  </div>

  </div>
  <!--//하단 사이트맵-->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapGetters([
      'treeData',
    ]),
  },
  data() {
    return {
      isShow: false      
    }
  },
  methods: {
    doContentLink(menuId, upMenuId, depth) {
      this.$router.push({name:'content', query: {menuId: menuId, upMenuId: upMenuId, s0: depth}})
    },  
    doSiteWClick() {
      this.isShow = !this.isShow
    },
    doSiteFocus(event) {
      if(event.keyCode == 13) {
        this.doSiteWClick()
      }
    },
    doLinkFocus(event, item) {
      if(event.keyCode == 13) {
        this.doLink(item);
      }
    },
    // 메뉴링크
    doLink(item) {
      const menuTypeCd = item.menuTypeCd;
      const param = {};
      param.query = {menuId: item.menuId, upMenuId: item.upMenuId, s0:item.firstGrpCd};

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        param.path=item.link??'/'
      }
      
      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)

      if(routeQuery != nextQuery){
        this.$router.push(param)
      }
    },
    doKeydownContentLink(event, menuId, upMenuId, depth) {
      if(event.keyCode == 13) {
        this.doContentLink(menuId, upMenuId, depth);
      }
    },
  },
}
</script>

<style scoped>
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-enter-to, .slide-leave {
   max-height: 297px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>