<template>
  <!-- Header -->
  <header class="sv-header">
    <div class="layout">
      <button type="button" class="hd-btn menu-btn" @click="doMenu"></button>
      <h1 class="logo">
        <a href="https://english.forest.go.kr/" target="_blank">
          <img src="@/assets/img/seed/logo_forest.png" alt="KOREA Forest Service Logo"/>
        </a>
        <a href="/seed">
          <img src="@/assets/img/seed/logo_BGSV.png" alt="Baekdudaegan Global Seed Vault (BGSV)"/>
        </a>
        <a href="/eng" target="_blank">
          <img src="@/assets/img/seed/logo_horizontal.svg" alt="KOREA ARBORETA AND GARDENS INSTITUTE"/>
        </a>
      </h1>
      <button type="button" class="hd-btn search-btn" @click="onTotalSearch"></button>
    </div>
    <LayoutLeft v-model="isMenu"/>
  </header>
</template>

<script>

import LayoutLeft from '@/views_seed/layouts/LayoutLeft'

export default {
  components:{
    LayoutLeft
  },
  data() {
    return {
      isMenu: false
    }
  }, 
  created(){
    this.doTopMenuList();
  },
  methods: {
    doMenu(){
      this.isMenu=!this.isMenu

      this.$nextTick(() => {
        if(this.isMenu) {
          document.querySelector("#gnb-close__btn").focus();
        }
      })
    },
    // 전체메뉴 조회
    doTopMenuList() {
      this.$tran('/site/external/main/selectTopMenuList','').then(response => {
        this.topMenu = response.data.treeData;
        this.totalMenuCnt = response.data.totalMenuCnt;
        this.$store.dispatch('setTreeData', this.topMenu);
      })  
    },
    onTotalSearch() {
      this.$emit('onTotalSearch');
      this.$nextTick(() => {
        document.querySelector("#searchInput").focus();
      })
    }
  }
}
</script>