<template>
  <input type="text" size="141" class="board_input postInput" v-model="listData" :maxlength="getMaxLength(fieldData.fieldCdNm)" > 
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    },
    fieldData: {
      type: Object
    },
    listIndex: {

    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    listData: {
      handler(newVal) {
        if(this.value[this.fieldData.fieldCdNm] == null){;
          this.value[this.fieldData.fieldCdNm] = []
        }
        this.value[this.fieldData.fieldCdNm][this.listIndex] = newVal
      },
    },
    value: {
      handler(newVal) {
        const data = newVal[this.fieldData.fieldCdNm][this.listIndex];
        if(data !=null && data != ''){ 
          this.listData = data
        }
      }
    }
  },
  data(){
    return {
      listData: ""
    }
  },
  methods: {
    getMaxLength(fieldNo){
      if(fieldNo == 'field03') {
        return 200
      }
      return 1000;
    },
  }
}
</script>