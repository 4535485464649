<template>
  <div style="padding-top:80px;">
    <a :href="koglData?.connCd1" target="_blank">
      <img :alt="koglData?.connCd2" :src="koglData?.connCd3" style="display:inline;">
    </a>
    <p style="padding-top:10px;">{{koglData?.connCd4}}</p>
  </div>
</template>

<script>
export default {
  props: {
    koglCd: {
      type: String
    },
  },
  watch: {
    koglCd(newVal) {
      this.koglData = this.getCodeData(newVal)
    }
  },
  data() {
    return {
      condCode: [
        {cd: 'CMS015', useYn: 'Y', listNm: 'koglCdList'}
      ],
      koglCdList: [],
      koglData: {},
    }
  },
  mounted() {
    this.$codeLists(this, this.condCode).then(() => {
      this.koglData = this.getCodeData(this.koglCd)
    });
  },
  methods: {
    getCodeData(data) {
      return this.koglCdList.find((e) => {
        return e.cmnCd == data
      })
    }
  }
}
</script>