<template>
  <div id="print_wrap" :style="$route.name.indexOf('direct') == 0?{'margin-left':'60px','margin-right':'60px','margin-bottom':'60px'}:{}">
    <div id="cont_head" v-if="$route.name.indexOf('direct') == 0">
      <h2 v-html="$sanitize($t(boardInfo.boardNm, boardDefaults))"></h2>
    </div>
    <div>
      <div class="board_total">
        <!-- 그룹 탭 -->
        <ChildTab v-if="isTab()" :grpList="grpList" v-model="currentTab" @changeTab="changeTab" :isSameAllow="true" :depth="depth" />
      </div>
      <template v-if="isPwChk=='N'">
        <div :id="boardInfo.boardTypeCd == 'CMS002.img' || boardInfo.boardTypeCd == 'CMS002.imgThum'?'imageBoard':'dynamicBoard'" style="width:100%; margin-top: 20px;">
          <div class="pwd_check">
            {{$t('message.L000004')}}<br><br>
            <ul>
              <li>
                <label for="c_pwd" class="text">{{$t('pw')}} :</label> 
                <input type="password" size="15" :title="$t('pwInput')" v-model="searchParam.inputPw" @keydown="doPwEnter" class="board_input" style="vertical-align:middle;" autocomplete="off" >
                <img src="@/assets/img/board/nninc_simple/img/command_ok.gif" width="34" height="20" :alt="$t('confirm')" style="vertical-align:middle;padding-left:3px;" @click="doChkPw" @keydown="doKeydownChkPw($event)" />
              </li>
            </ul>
          </div>
          <div class="pwd_check_button">
            <span>
              <a href="javascript:void(0);" @click="goBack" class="cbtn cbtn_g" >{{$t('back')}}</a>
            </span>
          </div>
        </div>
      </template>
      <template v-if="isPwChk=='Y'"> 
        <div id="dynamicBoard" style="width:100%;">
          <!-- 보기 테이블 -->
          <div class="table_bview">
            <table cellspacing="0" :summary="`${$t(title)}`+`${$t('of')}`+ `${summary}`+`${$t('ckIt')}`">
            <caption>{{`${$t(title)}-${detailMain.field03}`}}</caption>
              <colgroup>
                <col width="6">
                <col width="">
                <col width="6">
              </colgroup>
              <thead>
                <tr style="word-break: break-all;overflow-wrap: break-word;">
                  <th scope="col" colspan="3" v-if="detailMain.field03 != null">
                    <span v-if="(detailMain.grpNm != null && detailMain.grpNm != '') && boardInfo.grpYn == 'Y' && grpSize > 0" class="category_color">[{{detailMain.grpNm}}]</span>
                    <span v-if="detailMain.field03 != null && detailMain.field03 != ''">{{detailMain.field03}}</span>
                  </th>
                </tr>
              </thead>
              <tbody style="word-break: break-all;overflow-wrap: break-word;">
                <tr v-if="detailMain.field17 != null || detailMain.field02 != null || detailMain.field18 != null">
                  <td></td>
                  <td>
                    <dl class="info"  style="flex-wrap: wrap;">
                      <template v-if="detailMain.field17 != null && detailMain.field17 != ''">
                        <dt>{{$t('crtDt')}}</dt>
                        <dd>{{detailMain.field17}}</dd>
                      </template>
                      <template v-if="detailMain.field02 != null && detailMain.field02 != ''">
                        <dt>{{$t('writer')}}</dt>
                        <dd>{{detailMain.field02}}</dd>
                      </template>
                      <template v-if="detailMain.field18 != null && detailMain.field18 != ''">
                        <dt>{{$t('viewCnt')}}</dt>
                        <dd>{{detailMain.field18}}</dd>
                      </template>
                    </dl>
                  </td>
                  <td></td>
                </tr>
                <template v-for="(item, index) in fieldList">
                  <!-- 상단 고정컬럼들 제외 -->
                  <tr v-if="detailMain[item.fieldCdNm] != null && detailMain[item.fieldCdNm] != '' &&  item.fieldCdNm != 'field08' && item.fieldCdNm != 'field03' && item.fieldCdNm != 'field02' && item.fieldCdNm != 'field17' && item.fieldCdNm != 'field18'" :key="index">
                    <td></td>
                    <td>
                      <li style="float:left">
                        <dl class="info">
                          <dt>{{item.fieldNm}}</dt>
                          <!-- 컬럼: 요약글 -->
                          <dd v-if="item.fieldCdNm == 'field22'" v-html="$sanitize(decodeHTML(detailMain.field22), boardDefaults)"></dd>
                          <!-- 입력 종류: 체크박스 -->
                          <dd v-else-if="item.fieldType=='CMS003.c'">
                            <template v-for="(listItem, listIndex) in detailMain[item.fieldCdNm]">
                              <span class="badge" :key="listIndex">{{ listItem }}</span>
                            </template>
                          </dd>
                          <!-- 입력 종류: 태그,에디터 -->
                          <dd v-else-if="item.fieldType ==  'CMS003.t' || item.fieldType ==  'CMS003.e' || item.fieldType =='CMS003.ta' || item.fieldType == 'CMS003.i'" v-html="$sanitize(decodeHTML(detailMain[item.fieldCdNm]), boardDefaults)"></dd>
                          <!-- 입력 종류: 리스트 -->
                          <dd v-else-if="item.fieldType == 'CMS003.l'">
                            <div v-for="(listItem, listIndex) in item.fieldData.split(',')" :key="`${listIndex}`" style="display:flex">
                              <dt>{{ listItem }}</dt>
                              <dd >{{ detailMain[item.fieldCdNm][listIndex] }}</dd>
                            </div>
                          </dd>
                          <!-- 입력 종류: 링크리스트 -->
                          <dd v-else-if="item.fieldType == 'CMS003.ll'" class="background_no" style="width: 100%;">
                            <boardLinkButton v-model="detailMain[item.fieldCdNm]" :fieldData="item.fieldData" />
                          </dd>
                          <!-- 입력 종류: 날짜 -->
                          <dd v-else-if="item.fieldType == 'CMS003.d'">
                            {{ doDateFormat(detailMain[item.fieldCdNm]) }}
                          </dd>
                          <!-- 입력 종류: 일시 -->
                          <dd v-else-if="item.fieldType == 'CMS003.dt'">
                            {{ doDateTimeFormat(detailMain[item.fieldCdNm]) }}
                          </dd>
                          <!-- 입력 종류: 기간 -->
                          <dd v-else-if="item.fieldType == 'CMS003.p'">
                            {{ doDateFormat(detailMain[item.fieldCdNm][0]) }} ~ {{ doDateFormat(detailMain[item.fieldCdNm][1]) }}
                          </dd>
                          <dd v-else-if="item.fieldType =='CMS003.s' || item.fieldType  =='CMS003.r'"> 
                            <boardDetailSelect v-model="detailMain[item.fieldCdNm]" :fieldData="item.fieldData"/>
                          </dd>
                          <dd v-else-if="item.fieldType == 'CMS003.a'">
                            <template v-for="item in detailMain[item.fieldCdNm]">
                              {{item}}
                            </template>
                          </dd>
                          <dd v-else>{{detailMain[item.fieldCdNm]}}</dd>
                        </dl> 
                      </li>
                    </td>
                    <td></td>
                  </tr>
                </template>
                <!-- 첨부파일 -->
                <tr v-if="boardInfo.attcYn == 'Y' && detailMain.attcCnt > 0">
                  <td colspan="3">
                    <fileDownload :attcNo="detailMain.attcNo" />
                  </td>
                </tr>
                <!-- 이미지 첨부파일 -->
                <template v-if="imageList != null && imageList.length > 0">
                  <tr>
                    <td colspan="3">
                      <imageDownload :boardNo="detailMain.boardNo" :postNo="detailMain.postNo" :excludeMain="boardInfo.boardTypeCd == 'CMS002.imgThum'?true:false"/>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="content">
                      <template v-for="(item, index) in imageList">
                        <a href="javascript:void(0);" :name="`${index}_img`" rel="lightbox" @click="doImageDetail(index)" :key="`${index}_img`" >
                          <img :key="index" :src="encodeURI(item?.imgUrl)" class="msimg" width="600" style="height: auto;" :alt="item.imgNm">
                        </a>
                        <br :key="`${index}_br1`"/>
                        <br :key="`${index}_br2`"/> 
                      </template>
                    </td>
                  </tr>
                </template>
                <tr v-if="(detailMain.field08 != null && detailMain.field08 != '') || (detailMain.koglCd != null && detailMain.koglCd != '')">
                  <td colspan="3" class="content" style="word-break: break-all;">
                    <div v-if="detailMain.field08 != null && detailMain.field08 != ''" v-html="$sanitize(detailMain.field08, boardDefaults)"></div>
                    <Kogl v-if="detailMain.koglCd != null && detailMain.koglCd != ''" :koglCd="detailMain.koglCd" />
                  </td>
                </tr>
                <tr v-if="(detailMain.staffDept != null && detailMain.staffDept != '') || (detailMain.staffTel != null && detailMain.staffTel != '') || (detailMain.staffNm != null && detailMain.staffNm != '')">
                  <td></td>
                  <td>
                    <li style="float:right">
                      <dl class="dept_info" v-if="detailMain.staffDept != null && detailMain.staffDept != ''">
                        <dt>{{$t('dept')}}</dt>
                        <dd>{{ detailMain.staffDept }}</dd>
                      </dl>
                      <dl class="dept_info" v-if="detailMain.staffTel != null && detailMain.staffTel != ''">
                        <dt>{{$t('tel')}}</dt>
                        <dd>{{ detailMain.staffTel }}</dd>
                      </dl>
                      <dl class="dept_info" v-if="detailMain.staffNm != null && detailMain.staffNm != ''">
                        <dt>{{$t('manager')}}</dt>
                        <dd>{{ detailMain.staffNm }}</dd>
                      </dl>
                    </li>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- 버튼 -->
          <postButtonList v-model="detailMain" :board="board" :nextNo="nextNo" :prevNo="prevNo" :routeDiv="routeDiv" @doPw="doPw()" />
          <!-- 댓글 -->
          <Comment v-if="boardInfo.commYn == 'Y'" :delYn="detailMain.delYn" :board="board" />
        </div>
      </template>
    </div>
    <component v-if="board.boardNo != null && board.boardTypeCd == 'CMS002.priv'" :is="isComponent" :board="board" :grpList="grpList" :depth="depth" ></component>   
  </div>
</template>

<script>
import LightBox from '@/libs/lightBox/LightBox';
import HDate from '@/libs/date';
import { boardDefaults } from '@/libs/sanitize';
import {decodeHTMLEntities} from '@/libs/comm/util'
import { mapGetters } from 'vuex';

import ChildTab from '@/views/component/ChildTab'
import fileDownload from '@/views/component/fileDownload'
import imageDownload from '@/views/component/ImageDownload'
import Comment from '@/views/component/Comment'
import Kogl from '@/views/component/Kogl'
import boardLinkButton from '@/views/component/BoardLinkButton'
import boardDetailSelect from "@/views/component/BoardDetailSelect";
import postButtonList from '@/views/component/PostButtonList'
import dynamicBoard from '@/views/board/dynamicBoard'
import dynamicImage from '@/views/board/dynamicImage'

export default {
  components: {
    ChildTab,
    fileDownload,
    imageDownload,
    Comment,
    Kogl,
    boardLinkButton,
    boardDetailSelect,
    postButtonList,
    dynamicBoard,
    dynamicImage
  },
  props: {
    board: {
      type: Object,
      default: () => new Object()
    },
    depth: {
      type: Number,
      default: 0
    },
    grpList: {
      type: Array,
      default: () => []
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapGetters([
      'subMenuTitle',
    ]),
    grpSize() {
      return this.grpList.reduce((e,cur) => {
                  return cur.hiddenYn!="Y"?e+1:0
              },0)
    },
    isComponent(){
      if(this.board.boardTypeCd == 'CMS002.img' || this.board.boardTypeCd == 'CMS002.imgThum'){
        return 'dynamicImage';
      }else{
        return 'dynamicBoard';
      }
    }
  },
  watch: {
    grpList(newVal){
      if(newVal != null && newVal.length > 0) {
        this.setCurrentTab()
      }
    },
    '$route.query': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.searchParam.contentNo = this.board.boardNo;
          this.searchParam.postNo = newVal.postNo;
          this.searchParam.grpCd = newVal[`s${this.depth}`]

          if(this.grpList != null && this.grpSize > 0) {
            this.setCurrentTab()
          } 
          this.doBoard();
        }
      }
    }
  },
  data() {
    return {
      searchParam: {
        contentNo : '',
        postNo: '',
        attcNo: '',
        inputPw: null,
        grpCd: null,
        modifyYn: null,
        originBoardNo: null,
        boardDepth: 0,
      },
      isPwChk: null,
      fieldList:[],
      boardInfo: {
        boardNo: null,
        boardTypeNm: null,
        accCd: null,
        boardNm: null,
        boardTypeCd: null,
        grpDispCd: null,
        writeYn:'Y',
        certYn:'N',
        pwYn:'Y',
        grpYn:'N',
        attcYn:'N',
        attcLimitNo:0,
        attcLimitSize:0,
        attcAllowExt: '',
        attcAllowExts: null,
        replyYn:'N',
        commYn:'N',
        ndisYn:'N',
        ndisRequYn: 'N',
        useTeamYn:'N',
        useStDt:'',
        useEnDt:'',
        useTeamOverYn:'N',
        useYn:'Y',
        delFlagYn:'N',
        limitWord:'',
        postTeamYn:'N',
        postDd:0,
        pagePerNo:0,
        newIconDispDd:0,
        noticeYn:'N',
        mainDispYn:'N',
        defContent:'',
        koglYn: 'N',
      },
      detailMain: {
        contentNo: null,
        postNo: null,
        termsNo: 0,
        unLimitedYn: 'N',
        nextCloseYn: 'N' ,
        popupTypeCd: null,
        accCd: null,
        dispStDt:null,
        dispEnDt:null,
        useYn: null,
        nextCloseDd:null,
        widthPixel: null,
        heightPixel: null,
        topPixel:'0',
        leftPixel:'0',
        content:'<p></p>',
        noticeYn:'N',
        mainYn: 'N',
        ndisYn: 'N',
        grpCd: null,
        grpNm: null,
        id: null,
        pw: null,
        field01: null,
        field02: null,
        field03: null,
        field04: null,
        field05: null,
        field06: null,
        field07: null,
        field08: null,
        field09: null,
        field10: null,
        field11: null,
        field12: null,
        field13: null,
        field14: null,
        field15: null,
        field16: null,
        field17: null,
        field18: null,
        field19: null,
        field20: null,
        field21: null,
        field22: null,
        field23: null,
        field24: null,
        postStDt: null,
        postEnDt: null,
        postStTm: null,
        postEnTm: null,
        mainDispStDt: null,
        mainDispEnDt: null,
        mainDispStTm: null,
        mainDispEnTm: null,
        mainTermLimitYn: 'N',
        noticeDispStDt: null,
        noticeDispEnDt: null,
        noticeDispStTm: null,
        noticeDispEnTm: null,
        noticeTermLimitYn: 'N',
        orginPostNo: null,
        requApprYn: 'N',
        selApprYn: 'N',
        attcNo: null,
        delYn: 'N',
        koglCd: null,
        staffDept: null,
        staffNm: null,
        staffTel: null,
      },
      imageList: [],
      prevNo: null,
      nextNo: null,
      currentTab: 'none',
      title: '',
      summary: '',
      subContentType: 'A',
      isInit: true,
      routeDiv: 'dynamic',
      boardDefaults
    }
  },
  mounted() {
    if(this.$route.name.indexOf('direct') == 0) {
      this.routeDiv = 'direct'
    }

    if(this.grpList != null && this.grpSize > 0) {
      this.setCurrentTab()
    }  

    this.searchParam.contentNo = this.board.boardNo;
    this.searchParam.postNo = this.$route.query.postNo;
    this.searchParam.grpCd = this.$route.query[`s${this.depth}`]

    if(this.board != null && this.board != '' && Object.keys(this.board).length > 0) {
      this.boardInfo = this.board;
    }

    this.doBoard();
  },
  methods: {
    setCurrentTab() {
      this.currentTab = this.$route.query[`s${this.depth}`]
    },
    isTab(){
      // 그룹 탭 리스트 
      const grpList = this.grpList;
      // 그룹 사용 컴포넌트 코드(탭 / 선택상자)
      const grpDispCd = this.boardInfo.grpDispCd;
      // 그룹사용여부
      const grpYn = this.boardInfo.grpYn;
      // 비공개 여부
      const ndisYn = this.boardInfo.ndisYn;
      // 비공개 필수 여부
      const ndisRequYn = this.boardInfo.ndisRequYn;

      if(grpList == null || this.grpSize < 1){
        return false;
      }

      if(grpYn != 'Y' || grpDispCd != 'CMS009.tab'){
        return false;
      }

      if(ndisYn == 'Y' && ndisRequYn == 'Y'){
        return false;
      }

      return true;
    },
    changeTab(data, contentType, key, isSameAllow=false) { 
      this.searchParam.contentNo = this.board.boardNo;
      this.searchParam.postNo = this.$route.query.postNo;
      this.searchParam.grpCd = key

      const query = {...this.$route.query}
      query[`s${this.depth}`] = key
      
      if(this.searchParam.contentNo != null && this.searchParam.contentNo != ''){
        if(this.isInit){
          this.isInit = false
        }else{
          if(isSameAllow) {
            const param = {query:{...query}, name:'content'};
              
            delete param.query.postNo;
            delete param.query.page;

            //주소가 같이 않으면 주소 변경
            if(JSON.stringify(this.$route.query) != JSON.stringify(param.query)) {
              this.$router.push(param);
            }
          }
        }
      }
    },
    setMetaInfo() {
      // 현재 URL
      const url = document.location.href;
      // 그룹이름
      const grpNm = this.detailMain.grpNm;
      // 그룹사용여부
      const grpYn = this.boardInfo.grpYn;
      let img = '/logo.png';
      // 게시판명
      const boardNm = this.$t(this.subMenuTitle);
      // 제목
      const title = this.detailMain.field03;
      // 내용
      const ctnt = this.$sanitize(this.detailMain.field08, {allowedTags:[]}).replace(/(\n|\t|\r)/g, "");

      if(this.imageList != null && this.imageList.length > 0) {
        const mainImg = this.imageList.find(e => e.mainYn == 'Y');

        if(mainImg != null) {
          img = mainImg.imgUrl;
        }
      }

      const metaInfoData = {
        title: title,
        ogTitle: title,
        ogUrl: url,
        ogDescription: ctnt,
        ogImage: img,
      };

      metaInfoData.title = `${this.$t('koagi')}: ${boardNm}-${this.$t('detail')}`;

      if((grpNm != null && grpNm != '') && grpYn == 'Y') {
        metaInfoData.title = `${this.$t('koagi')}: ${boardNm} > ${grpNm}-${this.$t('detail')}`
      }

      this.$store.dispatch('setMetaInfo', metaInfoData);
    },
    doBoard() {
      this.$nextTick(() => {
        this.title = this.subMenuTitle;
        
        if(this.boardInfo.ndisYn == 'Y') {
          this.isPwChk = 'N';
          this.searchParam.inputPw = null;
          this.$tran('/site/external/contents/selectIsValid', this.searchParam).then(response => {
            if(response.data.isToken){
              this.doDetail();
            }
          })
        }else{
          this.isPwChk = 'Y';
          this.doDetail();
        }
      })
    },
    doDetail(){
      let summaryData = '';
      this.$tran('/site/external/contents/selectPostDetail', this.searchParam).then(response => {
        if(response.data == null || response.data == ''){
          return false;
        }
        this.fieldList = response.data.fieldList;
        this.detailMain = response.data.post;
        this.prevNo = response.data.prevNextPost?.prevPostNo;
        this.nextNo = response.data.prevNextPost?.nextPostNo;
        this.imageList = this.getImageListFilter(response.data.imageList);

        this.isPwChk = 'Y';

        this.$nextTick(() => {
          this.title = this.subMenuTitle;

          if(this.board == null || this.board == '' || Object.keys(this.board).length == 0) {
            this.boardInfo = response.data.boardInfo;
          }

          if(this.boardInfo.boardTypeCd == 'CMS002.img' || this.boardInfo.boardTypeCd == 'CMS002.imgThum') {
            this.lightbox = new LightBox({
              loadingimg:'/nanum/site/board/nninc_photo/img/lt_loading.gif',
              expandimg:'/nanum/site/board/nninc_photo/img/lt_expand.gif',
              shrinkimg:'/nanum/site/board/nninc_photo/img/lt_shrink.gif',
              previmg:'/nanum/site/board/nninc_photo/img/lt_prev.gif',
              nextimg:'/nanum/site/board/nninc_photo/img/lt_next.gif',
              effectimg:'/nanum/site/board/nninc_photo/img/lt_zzoop.gif',
              effectpos:{x:-40,y:-20},
              effectclass:'effectable',
              closeimg:'/nanum/site/board/nninc_photo/img/lt_close.gif',
              resizable:true,
              imageList: this.imageList
            })
          }

          this.setMetaInfo();
          
          for(let i=0; i < this.fieldList.length; i++){
            if(summaryData == null || summaryData == '') {
              summaryData += this.fieldList[i].fieldNm
            }else{
              summaryData += ', ' + this.fieldList[i].fieldNm
            }
            this.summary = summaryData
          }
        })
      })
    },
    goBack() {
      let name = 'content'
      const query = {...this.$route.query}
      delete query.postNo
      
      if(this.routeDiv == 'direct') {
        name = 'directBoard'
      }

      this.$router.push({name: name, query: query})
    },
    doChkPw() {
      const inputPw = this.searchParam.inputPw;

      if(inputPw == null || inputPw == '') {
        this.$swal.fire(this.$t('error'), this.$t('message.L000004') , 'error')
        return;
      }
      this.doDetail()
    },
    doPwEnter(event) {
      if(event.keyCode == 13) {
        this.doChkPw();
      }
    },
    doImageDetail(index) {
      this.lightbox._genOpener(index)
    },
    doDateFormat(date) {
      const hdate = new HDate();
      let formatDate = '';

      if(date != null && date != '') {
        formatDate = hdate.getMaskFormatString(hdate.strToDate(date),'yyyy-MM-dd');
      }

      return formatDate
    },
    doDateTimeFormat(date) {
      const hdate = new HDate();
      let formatDate = '';

      if(date != null && date != '') {
        formatDate = hdate.getMaskFormatString(hdate.strToDate(date),'yyyy-MM-dd HH:mm:ss');
      }

      return formatDate
    },
    decodeHTML(str) {
      if(str != null && str != '') {
        return decodeHTMLEntities(str)
      }
      return
    },
    doPw() {
      this.searchParam.inputPw=null;
      this.doBoard();
    },
    doKeydownChkPw(event) {
      if(event.keyCode == 13) {
        this.doChkPw();
      }
    },
    getImageListFilter(imageList){
      if(this.board.boardTypeCd == 'CMS002.imgThum'){
        return imageList.filter((e)=>{if(e.mainYn!='Y'){return true} return false})
      }
      return imageList
    }
  }
}
</script> 