<template>
  <div>
    <div style="margin-top:7px;"></div>
    <div style="margin-bottom:7px;"></div>
    <div id="NFU_file_control">
      <input id="NFU_add_file_tmp" type="file" ref="formFile" style="display: none;" @change="onFileChange" :multiple="fileInfo.multiple" :accept="acceptFileExt">
      <div id="NFU_div_img">
        <img id="NFU_image" :src="selected.viewSrc" :alt="$t('imgPrv')">
      </div>
      <div id="NFU_div_file_list" @dragenter="onDragenter" @dragover="onDragover" @dragleave="onDragleave" @drop="onDrop" @dblclick="onClickList">
        <table id="NFU_file_list_img" >
          <tbody>
            <tr id="NFU_drag_msg" v-if="completList == null || completList.length < 1">
              <td style="border: none; text-align: center;">
                <span>{{this.$t('message.F000008')}}</span>
              </td>
            </tr>
            <tr :id="'file_list_'+index" v-for="(val, index) in completList" :key="`B-${index}`" @click="showImage(index)" :class="index==selected.index?'file_selected':''"> <!-- class="file_selected" -->
              <td>
                <a href="javascript:void(0);" class="fileClick" v-if="!fileInfoInner.fileComplet" @click="fileRemove($event, val, index)" >
                	 <svg width="13px" height="13px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.72 5.72a.75.75 0 011.06 0L12 10.94l5.22-5.22a.75.75 0 111.06 1.06L13.06 12l5.22 5.22a.75.75 0 11-1.06 1.06L12 13.06l-5.22 5.22a.75.75 0 01-1.06-1.06L10.94 12 5.72 6.78a.75.75 0 010-1.06z"/></svg>
                </a>
              </td>
              <td :style="val.delYn=='Y'?{'text-decoration':'line-through'}:{}">{{val.imgNm}}</td>
              <td>
                <span class="fileClick">
                  <label :for="'mainYn'+index">{{$t('mainYn')}}<input type="checkbox" :id="'mainYn'+index" v-model="val.mainYn" true-value="Y" false-value="N" @change="doChange(val)" ></label>
                </span>
              </td>
              <td>
                <span class="">
                  <input type="text" v-model="val.ord" @change="doOrd(val)" >
                </span>
              </td>
              <td>{{byteToKBorMB(val.attcFileSize)}}</td>
              <td v-if="val.imgNo != null && val.imgNo != ''">
                <span>
                	  <svg width="20px" height="22px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 -2 30 30" xml:space="preserve">
                      <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="28,8 16,20 11,15 "/>
                      <path d="M26.7,13.5c0.2,0.8,0.3,1.6,0.3,2.5c0,6.1-4.9,11-11,11S5,22.1,5,16S9.9,5,16,5c3,0,5.7,1.2,7.6,3.1l1.4-1.4
                        C22.7,4.4,19.5,3,16,3C8.8,3,3,8.8,3,16s5.8,13,13,13s13-5.8,13-13c0-1.4-0.2-2.8-0.7-4.1L26.7,13.5z"/>
                    </svg>
                </span>
              </td>
              <td v-else><span class="fileLoader" v-if="spinner"></span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div id="div_multi_btn" style="padding-top: 5px; display: block;">
      <input type="button" :value="$t('searchFile')" class="NFU_cbtn NFU_cbtn_point" @click="onClick" >
      <input type="button" :value="$t('deleteAll')" class="NFU_cbtn NFU_cbtn_g ml5" @click="fileRemoveAll" >
      <input type="button" :value="$t('downloadAll')" class="NFU_cbtn NFU_cbtn_g ml5" @click="fileDownloadAll" >
    </div>
    <div>
		  <span style="font-size:12px;color:#DF0101;">{{$t('allowFiles')}} :{{fileInfo.fileCnt}}{{$t('ea')}} <br>{{$t('attcLimitVol')}} : {{byteToKBorMB(fileInfo.fileSize)}} <br>{{$t('allowFileIs')}} {{showFileExt(fileInfo.fileExt)}}</span>
	  </div>
  </div>
</template>

<script>
import { guid, downloadResponse } from '@/libs/comm/util';

export default {
  props: {
    boardNo: {
      type: String,
      default: ''
    },
    postNo: {
      type: [String, Number],
      default: ''
    },
    fileInfo:{
      type: Object
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    fileInfo: {
      handler(value) {
        if(value == null){
          this.fileInfoInner = {
            fileComplet: false,
            fileSize: '5242880',
            fileExt: ['jpg', 'jpeg', 'png', 'bmp', 'svg', 'gif'],
            fileCnt: 2,
            multiple: false,
            fileDownloadNm: this.$t('attcFile'),
          }
        } else {
          this.fileInfoInner = value
        }
      },
      deep: true
    },
    postNo(newVal) {
      if(newVal != null && newVal != '') {
        this.getCompletList(this.boardNo, newVal)
      }
    },
    boardNo(newVal) {
      if(newVal != null && newVal != '') {
        this.getCompletList(newVal, this.postNo)
      }
    }
  },
  mounted() {
    this.fileInfoInner = this.fileInfo;
    this.acceptFileExt = this.fileInfoInner.fileExt.map(ext => '.'+ext);
    this.selected.viewSrc = this.selected.defaultImg;
    this.getCompletList(this.postNo);
  },
  data() {
    return {
      fileList: [],
      completList: [],
      isDragged: false,
      deleteList: [],
      fileInfoInner : {
        fileComplet: false,
        fileSize: '5242880',
        fileExt: ['jpg', 'jpeg', 'png', 'bmp', 'svg', 'gif'],
        fileCnt: 2,
        multiple: false,
        fileDownloadNm: this.$t('attcFile'),
      },
      spinner: false,
      acceptFileExt: [],
      selected: {
        viewSrc: '',
        index: -1,
        defaultImg: require("@/assets/img/common/no.gif"),
      },
    }
  },
  methods: {
    fileRemoveAll(){
      if(this.fileList.length > 0){
        this.fileList = [];
      }
      for(let i = this.completList.length-1 ; i >= 0 ; i--){
        const item = this.completList[i]
        const imgNo = item.imgNo
        if(imgNo == null || imgNo == ''){
          this.completList.splice(i,1);
        } else {
          item.delYn = 'Y'
          this.$set(this.completList, i, item)
          this.deleteList.push(item)
        }
      }
      this.$refs.formFile.value = null;
      this.selected.index = -1;
      this.selected.viewSrc = this.selected.defaultImg;
    },
    fileRemove(event, val, index){
      event.preventDefault()
      if(val.imgNo == null || val.imgNo == ''){
        this.completList.splice(index,1);
        const fileIdx = this.fileList.findIndex(e => e.fileIdx == val.fileIdx)
        if(fileIdx >= 0){
          this.fileList.splice(fileIdx,1);
        }
      } else {
        val.delYn = 'Y'
        this.$set(this.completList, index, val)
        this.deleteList.push(val)
      }
      this.$refs.formFile.value = null;
      this.selected.index = -1;
      this.selected.viewSrc = this.selected.defaultImg;
    },
    onClick(){
      this.$refs.formFile.click();
    },
    onClickList(){
      if(this.completList == null || this.completList.length < 1){
        this.$refs.formFile.click();
      }
    },
    onDragenter (event) {
      // class 넣기
      this.isDragged = true
    },
    onDragleave (event) {
      // class 삭제
      this.isDragged = false
    },
    onDragover (event) {
      // 드롭을 허용하도록 prevetDefault() 호출
      event.preventDefault()
    },
    onDrop (event) {
      // 기본 액션을 막음 (링크 열기같은 것들)
      event.preventDefault()
      this.isDragged = false
      const files = event.dataTransfer.files
      if(this.checkFileExt(files)){
        this.$refs.formFile.value = null;
        return;
      }
      this.addFiles(files)
    },
    onFileChange (event) {
      const files = event.target.files;
      if(this.checkFileExt(files)){
        this.$refs.formFile.value = null;
        return;
      }
      this.addFiles(files)
    },
    getFile() {
      const formData = new FormData();
      const insertList = [];
      for (let i = 0; i < this.fileList.length; i++) {
        const file = this.fileList[i];
        formData.append('imgList', file);
        insertList.push({name:file.name, mainYn:file.mainYn, ord: file.ord})
      }
      const updateList = [...this.completList];

      for (let i = 0; i < updateList.length; i++) {
        const file = {...updateList[i]};
        file.src=null
        file.imgUrl=null
        updateList[i] = file
      }

      formData.append('updateImgList', JSON.stringify(updateList));
      formData.append('insertImgList', JSON.stringify(insertList));
      this.fileList = [];
      return formData
    },
    async addFiles (files) {
      const maxCnt = this.fileInfoInner.fileCnt
      let delCnt = 0;
      if(this.deleteList != null){
        delCnt = this.deleteList.length;
      }

      if((files.length -delCnt > maxCnt) || (files.length + this.fileList.length - delCnt > maxCnt) || (files.length + this.completList.length- delCnt > maxCnt)){
        this.$swal.fire(this.$t('warning'), this.$t('message.F000005',{cnt: maxCnt}), 'warning');
        this.$refs.formFile.value = null;
        return;
      }

      let size = 0;
      const filesize = Number(this.fileInfoInner.fileSize);
      const pushList = [...this.completList];
      for(let i = 0; i < files.length; i++) {
        const file = files[i];
        size = Number(size) + Number(file.size);
        if(filesize < size){
          this.$swal.fire(this.$t('warning'), this.$t('message.F000004',{maxSize: this.byteToKB(filesize) + 'kb', size: this.byteToKB(size) + 'kb'}), 'warning');
          return;
        }
        
        const key = guid()
        const src = await this.readFiles(file)

        file.src = null
        file.fileIdx = key
        file.mainYn = 'N'
        file.ord = '0'
        this.fileList.push(file);

        const fileRow = {
          imgNm: file.name,
          attcFileSize: file.size,
          imgNo: '',
          imgUrl: src,
          delYn: '',
          userId: '',
          fileIdx: key,
          mainYn: file.mainYn,
          ord: file.ord,
          file: file
        }
        pushList.push(fileRow);
      }
      this.completList = [...pushList];
    },
    async readFiles (files) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = async (e) => {
          resolve(e.target.result) 
        }
        reader.readAsDataURL(files)
      })
    },
    getCompletList(boardNo, postNo){
      if(!!this.boardNo && !!this.postNo){
        let paramData = {
          boardNo: this.boardNo,
          postNo: this.postNo
        };
        this.$tran('/site/file/attchImageList', paramData, '').then(response => {
          this.completList = response.data.imageList;
        }).catch(error => {
            this.response = error.message
        })
      }
    },
    getExt(fileNm){
      return fileNm.substr(fileNm.lastIndexOf('.')+1);
    },
    getFileNm(fileNm){
      return fileNm.substr(0, fileNm.lastIndexOf('.'));
    },
    fileDownload(imgNo){
      const paramData = {
          imgNo: imgNo,
        };
      this.$tranFileDown('/site/file/downloadFile', paramData).then(response => {
        downloadResponse(response);
      }).catch(error => {
          this.response = error.message
      })
    },
    fileDownloadAll(){
      const firstList = this.completList.find(element => element != undefined);
      const paramData = {
          imgNo: firstList.imgNo,
          fileDownloadNm: this.fileInfoInner.fileDownloadNm
        };
      this.$tranFileDown('/site/file/downloadFile', paramData).then(response => {
        downloadResponse(response);
      }).catch(error => {
          this.response = error.message
      })
    },
    byteToKB(byteVal){
      return Math.trunc(Number(byteVal) / 1024);
    },
    byteToKBorMB(byteVal){
      const KB = Math.trunc(Number(byteVal) / 1024);
      const MB = Math.trunc(Number(KB) / 1024)
      if (KB > 1024){
        return MB+' MB';
      } else if (KB == 0){
        return byteVal+' KB';
      } else {
        return KB+' KB';
      }
    },
    checkFileExt(files, message=true){
      for(let i = 0; i < files.length; i++){
        const ext = this.getExt(files[i].name).toLowerCase();
        const fileInfo = this.fileInfoInner.fileExt;
        const accept = fileInfo.some(element => {
          return element.toLowerCase() == ext;
        })
        if(!accept) {
          if(message){
            this.$swal.fire(this.$t('warning'), this.$t('message.F000003',{ext: ext, allowExts: fileInfo.join(', ')}), 'warning');
          }
          return true;
        }
      }
      return false;
    },
    showFileExt(fileExt){
      return fileExt.join(',').replace().replaceAll('.','')
    },
    showImage(index){
      if(this.selected.index == index){
        this.selected.index = -1;
        this.selected.viewSrc = this.selected.defaultImg;
      } else {
        this.selected.index = index;
        if(!!this.completList[index].imgUrl){
          this.selected.viewSrc = this.completList[index].imgUrl;
        } else {
          this.selected.viewSrc = this.selected.defaultImg;
        }
      }
    },
    doChange(item) {
      for(let index in this.completList){
        const vo = this.completList[index]
        
        // mainYn 대상 이외 모두 N
        if(JSON.stringify(vo) != JSON.stringify(item)){
          vo.mainYn = 'N'
        }

        //file.mainYn으로 데이터베이스 데이터 변경
        if(item.hasOwnProperty('file')) {
          item.file.mainYn = vo.mainYn
        }
      }
    },
    doOrd(item) {
      if(item.hasOwnProperty('file')) {
        item.file.ord = item.ord.replace(/[^0-9]/g, '');
      }
    },
  }
}
</script>