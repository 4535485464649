<template>
  <!-- GNB 파넬 -->
  <aside v-if="value" :class="leftMenuClass">
    <button type="button" id="gnb-close__btn" class="gnb-close__btn" @click="closeGNB" @keydown="doCloseGNBBackKeyDown"></button>
    <div class="gnb-img"></div>
    <div class="gnb-wrap">
      <nav class="gnb-nav">
        <ul class="gnb-nav__list">
          <template v-for="(item, idx, index) in treeData">
            <li v-if="item.hiddenYn != 'Y'" :key="index"> 
              <a class="nav-title">{{item.menuNm}}</a>
              <div class="nav-items">
                <template v-for="(item2, idx2, index2) in item.subMenu">
                  <a href="javascript:void(0)" style="cursor: pointer;" @click="doLeftLink(item2, index2)" @keydown="doLeftLinkKeyDown($event, item, index, item2, index2)" v-if="item2.hiddenYn != 'Y'" :key="index2" :id="`${idx}_${idx2}`">
                    {{item2.menuNm}}
                  </a>
                </template>
              </div>
            </li>
          </template>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    upMenuId(){
      return this.$route.query?.upMenuId;
    },
    menuId(){
      return this.$route.query?.menuId;
    },
    ...mapGetters({
      treeData: 'treeData',
    }),
    leftMenuClass() {
      const result = ['sv-gnb']
      if(this.value){
        result.push('overflow-hidden')
        result.push('active')
      }
      return result
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  created() {
    // const upMenuId = this.$route.query?.upMenuId;
    // const menuId= this.$route.query?.menuId;

    // if(upMenuId != null && upMenuId != ''){
    //   this.searchParam.upMenuId = upMenuId
    // }
    // if(menuId != null && menuId != ''){
    //   this.searchParam.menuId = menuId
    // }
    
    // this.doLeftMenuList()
  },
  methods: {
    closeGNB() {
        this.$emit('input', false)
    },
    doLeftLink(item, index, isMobile=true) {
      this.closeGNB()
      const menuTypeCd = item.menuTypeCd;
      const param = {};
      param.query = {menuId: item.menuId, upMenuId: item.upMenuId, s0:item.firstGrpCd};

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'seed_content';
      }else{
        let link = item?.link.trimStart();
        
        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }

        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:item.menuId}

      if(routeQuery != nextQuery || menuTypeCd =='CMS007.link'){
        this.$router.push(param)
      }
    },
    doLeftLinkKeyDown(e, item, index, item2, index2) {
      if(!e.shiftKey && e.keyCode == 9) {
        const treeList = Object.values(this.treeData);
        const depth1Cnt = treeList.filter((e) => e.hiddenYn != 'Y').length;

        if((depth1Cnt-1) == index) {
          const depth2Cnt = Object.values(treeList[index].subMenu).length;
          
          if((depth2Cnt-1) == index2) {
            e.preventDefault();
            document.querySelector("#gnb-close__btn").focus();
          }
        }
      }
    },
    doCloseGNBBackKeyDown(e) {
      if(e.shiftKey && e.keyCode == 9) {
        const depth1List = Object.values(this.treeData).filter((e) => e.hiddenYn != 'Y');
        const depth1TotalCnt = depth1List.length;
        const depth1 = depth1List[depth1TotalCnt-1];

        const depth2List = Object.values(depth1.subMenu).filter((e) => e.hiddenYn != 'Y');
        const depth2TotalCnt = depth2List.length;
        const depth2 = depth2List[depth2TotalCnt-1];

        const focusId = `#${depth1.menuId}_${depth2.menuId}`;

        e.preventDefault();
        document.querySelector(focusId).focus();
      }
    }
  }
}
</script>