import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import {setI18n} from '@/libs/i18n'
import { sendPost, selectAccCdList, selectCommCodeList, selectComCdLists, sendPostFile, sendPostFileDown } from '@/libs/comm/transection';

import router from './router/index';
import VueCookies from "vue-cookies";

import '@/libs/sweet-alerts'
import '@/libs/sanitize'
import LoadScript from 'vue-plugin-load-script';
import VTooltip from 'v-tooltip'

import contContent from '@/views/contents/content/contContent'
import boardContent from '@/views/contents/content/boardContent'
import seedMainContent from '@/views_seed/contents/mainContent'
import seedContContent from '@/views_seed/contents/content/contContent'
import seedBoardContent from '@/views_seed/contents/content/boardContent'
import engMainContent from '@/views_eng/contents/mainContent'
import engContContent from '@/views_eng/contents/content/contContent'
import engBoardContent from '@/views_eng/contents/content/boardContent'
import deptTable from '@/views/dept/deptTable'
import mainContent from '@/views/contents/mainContent'
import deptContent from '@/views/dept/dept'

import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta'

Vue.component('cont-content',contContent)
Vue.component('board-content',boardContent)
Vue.component('main-content',mainContent) 
Vue.component('dept-content',deptContent) 

Vue.component('seed-main-content',seedMainContent)
Vue.component('seed-cont-content',seedContContent)
Vue.component('seed-board-content',seedBoardContent)


Vue.component('eng-main-content',engMainContent)
Vue.component('eng-cont-content',engContContent)
Vue.component('eng-board-content',engBoardContent)

Vue.component('deptTable',deptTable)

Vue.use(VueCookies);
Vue.use(LoadScript);
Vue.use(VTooltip);

Vue.use(VueGtag, {
  config: {
      id: 'G-GM56Y3TS2T',  // Google Analytics의 Tracking ID
      params: {
        send_page_view: false,
      },
  }
});
Vue.use(VueMeta)

let css = () => import('@/assets/scss/style.scss')

if(window.location.pathname.indexOf('/seed')==0){
  css = () => import('@/assets/scss/seed/style.scss')
  store.dispatch('setSiteContext', "seed");
}else if(window.location.pathname.indexOf('/eng')==0){
  css = () => import('@/assets/scss/eng/style.scss')
  store.dispatch('setSiteContext', "koagiE");
}else{
  store.dispatch('setSiteContext', "koagi");
}

const getComponent = function (comp) {
  if(comp != null && comp != ''){
   return () => import(`@/views${comp}.vue`) ;
  }
  return null;
}

Vue.prototype.$comp = getComponent;
Vue.prototype.$tran = sendPost;
Vue.prototype.$tranFile = sendPostFile;
Vue.prototype.$tranFileDown = sendPostFileDown;
Vue.prototype.$codeList = selectCommCodeList;
Vue.prototype.$codeLists = selectComCdLists;
Vue.prototype.$accList = selectAccCdList;


Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue();

css().then(() => {
  setI18n().then((i18n)=>{
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  })
})