<template>
  <!-- 게시판 상세 페이지 -->
  <div class="board-detail__wrap">
    <div class="detail-top" v-if="(detailMain.field17 != null && detailMain.field17 != '') || (detailMain.field02 != null && detailMain.field02 != '') || (detailMain.field18 != null && detailMain.field18 != '')">
      <!-- 제목 -->
      <h2 class="detail-title">{{detailMain.field03}}</h2>
      <div class="detail-infos">
        <dl v-if="detailMain.field17 != null && detailMain.field17 != ''">
          <!-- 등록일 -->
          <dt>Date</dt>
          <dd>{{ dateEng }}</dd>
        </dl>
        <dl v-if="detailMain.field02 != null && detailMain.field02 != ''">
          <!-- 글쓴이 -->
          <dt>Write</dt>
          <dd>{{detailMain.field02}}</dd>
        </dl>
        <dl v-if="detailMain.field18 != null && detailMain.field18 != ''">
          <!-- 조회수 -->
          <dt>View</dt>
          <dd>{{detailMain.field18}}</dd>
        </dl>
      </div>
    </div>
    <!-- 이미지 첨부파일 -->
    <div class="detail-imgs__wrap" v-if="(imageList != null && imageList.length > 0) || detailMain.attcCnt > 0">
      <imageDownload v-if="imageList != null && imageList.length > 0" :boardNo="detailMain.boardNo" :postNo="detailMain.postNo"/>
      <!-- 첨부파일 -->
      <fileDownload v-if="boardInfo.attcYn == 'Y' && detailMain.attcCnt > 0" :attcNo="detailMain.attcNo"/>
      <div class="detail-imgs" v-if="imageList != null && imageList.length > 0">
        <img v-for="(item, index) in imageList" :src="item?.imgUrl" :key="index" :alt="item.imgNm">
      </div>
    </div>
    <!-- 상단 고정컬럼들 제외 -->
    <div class="detail-content">
      <template v-for="(item, index) in fieldList">
        <template v-if="detailMain[item.fieldCdNm] != null && detailMain[item.fieldCdNm] != '' && item.fieldCdNm != 'field03' && item.fieldCdNm != 'field17' && item.fieldCdNm != 'field02' && item.fieldCdNm != 'field18'">
          <!-- 입력 종류: 체크박스 -->
          <!-- 입력 종류: 태그,에디터 -->
          <!-- 입력 종류: 리스트 -->
          <!-- 입력 종류: 링크리스트 -->
          <!-- 입력 종류: 날짜 -->
          <div v-if="item.fieldType == 'CMS003.d'" :key="index">
            {{ doDateFormat(detailMain[item.fieldCdNm]) }}
          </div>
          <!-- 입력 종류: 일시 -->
          <div v-else-if="item.fieldType == 'CMS003.dt'" :key="`dt_${index}`">
            {{ doDateTimeFormat(detailMain[item.fieldCdNm]) }}
          </div>
          <!-- 입력 종류: 기간 -->
          <div v-else-if="item.fieldType == 'CMS003.p'" :key="`p_${index}`">
            {{ doDateFormat(detailMain[item.fieldCdNm][0]) }} ~ {{ doDateFormat(detailMain[item.fieldCdNm][1]) }}
          </div>
          <!-- 입력 종류: 태그,에디터 -->
          <div v-else-if="item.fieldType == 'CMS003.t' || item.fieldType ==  'CMS003.e' || item.fieldType =='CMS003.ta' || item.fieldType == 'CMS003.i'" v-html="$sanitize(decodeHTML(detailMain[item.fieldCdNm]), boardDefaults)" :key="`ta_${index}`"></div>
          <div v-else :key="`text_${index}`">{{detailMain[item.fieldCdNm]}}</div>
        </template>
      </template>
    </div>
    <!-- 담당자 영역 -->
    <div class="detail-infos" v-if="(detailMain.staffDept != null && detailMain.staffDept != '') || (detailMain.staffTel != null && detailMain.staffTel != '') || (detailMain.staffNm != null && detailMain.staffNm != '')">
      <dl v-if="detailMain.staffDept != null && detailMain.staffDept != ''">
        <dt>Department</dt>
        <dd>{{ detailMain.staffDept }}</dd>
      </dl>
      <dl v-if="detailMain.staffTel != null && detailMain.staffTel != ''">
        <dt>Telephone</dt>
        <dd>{{ detailMain.staffTel }}</dd>
      </dl>
      <dl v-if="detailMain.staffNm != null && detailMain.staffNm != ''">
        <dt>Contact Person</dt>
        <dd>{{ detailMain.staffNm }}</dd>
      </dl>
    </div>
    <!-- 버튼 -->
    <postButtonList v-model="detailMain" :board="board" :nextNo="nextNo" :prevNo="prevNo" @doPw="doPw()"/>
  </div>
</template>

<script>
import HDate from '@/libs/date';
import { boardDefaults } from '@/libs/sanitize';
import {decodeHTMLEntities} from '@/libs/comm/util'
import { mapGetters } from 'vuex';

import fileDownload from '@/views_eng/component/fileDownload'
import imageDownload from '@/views_eng/component/ImageDownload'
import postButtonList from '@/views_eng/component/PostButtonList'

export default {
  components: {
    fileDownload,
    imageDownload,
    postButtonList,
  },
  props: {
    board: {
      type: Object,
      default: () => new Object()
    },
    depth: {
      type: Number,
      default: 0
    },
    grpList: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    ...mapGetters([
      'subMenuTitle',
    ]),
    grpSize() {
      return this.grpList.reduce((e,cur) => {
                  return cur.hiddenYn!="Y"?e+1:0
              },0)
    },
    dateEng() {
      const strDate = this.detailMain.field17
      if(strDate != null && strDate != ''){
        return this.hdate.getMaskFormatString(this.hdate.strToDate(strDate.replaceAll('-','')),"dd, MMMM yyyy",'eng')
      }
      return ''
    }
  },
  watch: {
    grpList(newVal){
      if(newVal != null && newVal.length > 0) {
        this.setCurrentTab()
      }
    },
    '$route.query': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.searchParam.contentNo = this.board.boardNo;
          this.searchParam.postNo = newVal.postNo;
          this.searchParam.grpCd = newVal[`s${this.depth}`]

          if(this.grpList != null && this.grpSize > 0) {
            this.setCurrentTab()
          } 
          this.doBoard();
        }
      }
    }
  },
  data() {
    return {
      searchParam: {
        contentNo : '',
        postNo: '',
        attcNo: '',
        inputPw: null,
        grpCd: null,
        modifyYn: null,
        originBoardNo: null,
        boardDepth: 0,
      },
      isPwChk: null,
      fieldList:[],
      boardInfo: {
        boardNo: null,
        boardTypeNm: null,
        accCd: null,
        boardNm: null,
        boardTypeCd: null,
        grpDispCd: null,
        writeYn:'Y',
        certYn:'N',
        pwYn:'Y',
        grpYn:'N',
        attcYn:'N',
        attcLimitNo:0,
        attcLimitSize:0,
        attcAllowExt: '',
        attcAllowExts: null,
        replyYn:'N',
        commYn:'N',
        ndisYn:'N',
        ndisRequYn: 'N',
        useTeamYn:'N',
        useStDt:'',
        useEnDt:'',
        useTeamOverYn:'N',
        useYn:'Y',
        delFlagYn:'N',
        limitWord:'',
        postTeamYn:'N',
        postDd:0,
        pagePerNo:0,
        newIconDispDd:0,
        noticeYn:'N',
        mainDispYn:'N',
        defContent:'',
        koglYn: 'N',
      },
      detailMain: {
        contentNo: null,
        postNo: null,
        termsNo: 0,
        unLimitedYn: 'N',
        nextCloseYn: 'N' ,
        popupTypeCd: null,
        accCd: null,
        dispStDt:null,
        dispEnDt:null,
        useYn: null,
        nextCloseDd:null,
        widthPixel: null,
        heightPixel: null,
        topPixel:'0',
        leftPixel:'0',
        content:'<p></p>',
        noticeYn:'N',
        mainYn: 'N',
        ndisYn: 'N',
        grpCd: null,
        grpNm: null,
        id: null,
        pw: null,
        field01: null,
        field02: null,
        field03: null,
        field04: null,
        field05: null,
        field06: null,
        field07: null,
        field08: null,
        field09: null,
        field10: null,
        field11: null,
        field12: null,
        field13: null,
        field14: null,
        field15: null,
        field16: null,
        field17: null,
        field18: null,
        field19: null,
        field20: null,
        field21: null,
        field22: null,
        field23: null,
        field24: null,
        postStDt: null,
        postEnDt: null,
        postStTm: null,
        postEnTm: null,
        mainDispStDt: null,
        mainDispEnDt: null,
        mainDispStTm: null,
        mainDispEnTm: null,
        mainTermLimitYn: 'N',
        noticeDispStDt: null,
        noticeDispEnDt: null,
        noticeDispStTm: null,
        noticeDispEnTm: null,
        noticeTermLimitYn: 'N',
        orginPostNo: null,
        requApprYn: 'N',
        selApprYn: 'N',
        attcNo: null,
        delYn: 'N',
        koglCd: null,
        staffDept: null,
        staffNm: null,
        staffTel: null,
      },
      imageList: [],
      prevNo: null,
      nextNo: null,
      currentTab: 'none',
      title: '',
      summary: '',
      subContentType: 'A',
      isInit: true,
      boardDefaults,
      hdate: null
    }
  },
  mounted() {
    this.hdate = new HDate();

    if(this.grpList != null && this.grpSize > 0) {
      this.setCurrentTab()
    }  

    this.searchParam.contentNo = this.board.boardNo;
    this.searchParam.postNo = this.$route.query.postNo;
    this.searchParam.grpCd = this.$route.query[`s${this.depth}`]

    if(this.board != null && this.board != '' && Object.keys(this.board).length > 0) {
      this.boardInfo = this.board;
    }

    this.doBoard();
  },
  methods: {
    setCurrentTab() {
      this.currentTab = this.$route.query[`s${this.depth}`]
    },
    isTab(){
      // 그룹 탭 리스트 
      const grpList = this.grpList;
      // 그룹 사용 컴포넌트 코드(탭 / 선택상자)
      const grpDispCd = this.boardInfo.grpDispCd;
      // 그룹사용여부
      const grpYn = this.boardInfo.grpYn;
      // 비공개 여부
      const ndisYn = this.boardInfo.ndisYn;
      // 비공개 필수 여부
      const ndisRequYn = this.boardInfo.ndisRequYn;

      if(grpList == null || this.grpSize < 1){
        return false;
      }

      if(grpYn != 'Y' || grpDispCd != 'CMS009.tab'){
        return false;
      }

      if(ndisYn == 'Y' && ndisRequYn == 'Y'){
        return false;
      }

      return true;
    },
    changeTab(data, contentType, key, isSameAllow=false) { 
      this.searchParam.contentNo = this.board.boardNo;
      this.searchParam.postNo = this.$route.query.postNo;
      this.searchParam.grpCd = key

      const query = {...this.$route.query}
      query[`s${this.depth}`] = key
      
      if(this.searchParam.contentNo != null && this.searchParam.contentNo != ''){
        if(this.isInit){
          this.isInit = false
        }else{
          if(isSameAllow) {
            const param = {query:{...query}, name:'eng_content'};
              
            delete param.query.postNo;
            delete param.query.page;

            //주소가 같이 않으면 주소 변경
            if(JSON.stringify(this.$route.query) != JSON.stringify(param.query)) {
              this.$router.push(param);
            }
          }
        }
      }
    },
    setMetaInfo() {
      // 현재 URL
      const url = document.location.href;
      // 그룹이름
      const grpNm = this.detailMain.grpNm;
      // 그룹사용여부
      const grpYn = this.boardInfo.grpYn;
      let img = '/logo.png';
      // 게시판명
      const boardNm = this.boardInfo.boardNm;
      // 제목
      const title = this.detailMain.field03;
      // 내용
      const ctnt = this.$sanitize(this.detailMain.field08, {allowedTags:[]}).replace(/(\n|\t|\r)/g, "");

      if(this.imageList != null && this.imageList.length > 0) {
        const mainImg = this.imageList.find(e => e.mainYn == 'Y');

        if(mainImg != null) {
          img = mainImg.imgUrl;
        }
      }

      const metaInfoData = {
        title: title,
        ogTitle: title,
        ogUrl: url,
        ogDescription: ctnt,
        ogImage: img,
      };

      metaInfoData.title = `${this.$t('koagiE')}: ${boardNm}-${this.$t('detail')}`;

      if((grpNm != null && grpNm != '') && grpYn == 'Y') {
        metaInfoData.title = `${this.$t('koagiE')}: ${boardNm} > ${grpNm}-${this.$t('detail')}`
      }

      this.$store.dispatch('setMetaInfo', metaInfoData);
    },
    doBoard() {
      this.$nextTick(() => {
        this.title = this.subMenuTitle;
        
        if(this.boardInfo.ndisYn == 'Y') {
          this.isPwChk = 'N';
          this.searchParam.inputPw = null;
          this.$tran('/site/external/contents/selectIsValid', this.searchParam).then(response => {
            if(response.data.isToken){
              this.doDetail();
            }
          })
        }else{
          this.isPwChk = 'Y';
          this.doDetail();
        }
      })
    },
    doDetail(){
      let summaryData = '';
      this.$tran('/site/external/contents/selectPostDetail', this.searchParam).then(response => {
        if(response.data == null || response.data == ''){
          return false;
        }
        this.fieldList = response.data.fieldList;
        this.detailMain = response.data.post;
        this.prevNo = response.data.prevNextPost?.prevPostNo;
        this.nextNo = response.data.prevNextPost?.nextPostNo;
        this.imageList = this.getImageListFilter(response.data.imageList);

        this.isPwChk = 'Y';

        this.$nextTick(() => {
          this.title = this.subMenuTitle;

          if(this.board == null || this.board == '' || Object.keys(this.board).length == 0) {
            this.boardInfo = response.data.boardInfo;
          }

          this.setMetaInfo();
          
          for(let i=0; i < this.fieldList.length; i++){
            if(summaryData == null || summaryData == '') {
              summaryData += this.fieldList[i].fieldNm
            }else{
              summaryData += ', ' + this.fieldList[i].fieldNm
            }
            this.summary = summaryData
          }
        })
      })
    },
    doDateFormat(date) {
      let formatDate = '';

      if(date != null && date != '') {
        formatDate = this.hdate.getMaskFormatString(this.hdate.strToDate(date),'dd. MMMM yyyy', 'eng');
      }

      return formatDate
    },
    doDateTimeFormat(date) {
      const hdate = new HDate();
      let formatDate = '';

      if(date != null && date != '') {
        formatDate = hdate.getMaskFormatString(hdate.strToDate(date),'dd. MMMM yyyy HH:mm:ss', 'eng');
      }

      return formatDate
    },
    decodeHTML(str) {
      if(str != null && str != '') {
        return decodeHTMLEntities(str)
      }
      return
    },
    doPw() {
      this.searchParam.inputPw=null;
      this.doBoard();
    },
    getImageListFilter(imageList){
      if(this.board.boardTypeCd == 'CMS002.imgThum'){
        return imageList.filter((e)=>{if(e.mainYn!='Y'){return true} return false})
      }
      return imageList
    }
  }
}
</script> 