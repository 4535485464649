<template>
  <dl class="info imgDl">
    <dt class="imgDt">{{$t('attcFile')}}</dt>
    <dd class="imgDd">
      <a href="javascript:void(0);" v-for="(val, index) in completList" :key="index" :title="`${val.attcFileNm}.${val.attcFileExt} 파일 다운로드`" @click="fileDownload(val.attcNo, val.seqNo)" >
        <img :src="getFileImgPath(val.attcFileExt)" :alt="`${val.attcFileNm}.${val.attcFileExt} 파일 다운로드`"/>
        <span class="fileClick">{{val.attcFileNm}}.{{val.attcFileExt}}</span>
        <span class="size"> ({{byteToKBorMB(val.attcFileSize)}})</span>
      </a>
    </dd>
  </dl>
</template>

<script>
import { downloadResponse } from '@/libs/comm/util';

export default {
  mounted() {
    this.getCompletList(this.attcNo);
  },
  components: {
  },
  props: {
    attcNo: {
      type: String
    },
    fileInfo:{
      type: Object
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    attcNo(newVal) {
      if(newVal != null && newVal != '') {
        this.getCompletList(newVal)
      }
    }
  },
  data() {
    return {
      completList: [],
      imgPath: '',
    }
  },
  methods: {
    getCompletList(attcNo){
      if(attcNo != null && attcNo != ''){
        let paramData = {
          attcNo: attcNo
        };
        return this.$tran('/site/file/attchFileList', paramData, '').then(response => {
          this.completList = response.data.file;
        }).catch(error => {
            this.response = error.message
        })
      }
    },
    fileDownload(attcNo, seqNo){
      const paramData = {
        attcNo: attcNo,
        seqNo: seqNo
      };
      this.$tranFileDown('/site/file/downloadFile', paramData).then(response => {
        downloadResponse(response);
      }).catch(error => {
          this.response = error.message
      })
    },
    fileDownloadAll(){
      const firstList = this.completList.find(element => element != undefined);
      const paramData = {
        attcNo: firstList.attcNo,
        fileDownloadNm: this.fileInfo.fileDownloadNm
      };
      this.$tranFileDown('/site/file/downloadFile', paramData).then(response => {
        downloadResponse(response);
      }).catch(error => {
          this.response = error.message
      })
    },
    byteToKBorMB(byteVal){
      const KB = Math.trunc(Number(byteVal) / 1024);
      const MB = Math.trunc(Number(KB) / 1024)
      if (KB > 1024){
        return MB+' MB';
      } else if (KB == 0){
        return byteVal+' KB';
      } else {
        return KB+' KB';
      }
    },
    getFileImgPath(attcFileExt){
      const fileExt = attcFileExt.toLowerCase();
      const existImg = ['avi','doc','gif','hwp','jpg','pdf','ppt','txt','wmv','xls','zip','xlsx', 'pptx']
      if(existImg.indexOf(fileExt) < 0){
        return require('@/assets/img/board/nninc_simple/img/file/icon_no.png')
      } else {
        return require('@/assets/img/board/nninc_simple/img/file/icon_'+fileExt+'.png')
      }
    },
  }
}
</script>