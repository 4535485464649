<template>
  <!-- Footer -->
  <footer class="sv-footer">
    <div class="layout">
      <img src="@/assets/img/seed/logo_vertical.svg" alt="KOREA ARBORETA AND GARDENS INSTITUTE Logo"/>
      <div class="ft-info">
        <h5>Baekdudeagan Global Seed Vault</h5>
        <p class="info-txt">
          2160-53 Munsu-ro, <br/>
          Chunyang-Myeon, Bonghwa-gun, Gyeongsangbuk-do, <br/>
          Republic of Korea, Post Code : 36209
        </p>
        <p class="copyright">ⓒKorea Arboreta and Gardens Institute. All Rights Reserved.</p>
      </div>
      <div class="ft-contact">
        <h5>Contact info</h5>
        <p>Tel)+82-54-679-2789</p>
        <p>seedvault@koagi.or.kr</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  
}
</script>
