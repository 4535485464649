<template>
  <div id="cont_wrap">
    <!-- 상단 컨텐츠 -->
    <template v-for="(item, index) in contentList">
      <main-content v-if="item.ord < 100" :subYn="true" :contents="[{contentNo: item.contentNo, contentType: item.contentType}]" :key="index"/>
    </template> 

    <div class="organ_wrap">
      <div class="organ_map" v-html="$sanitize(content)" ref="mapDiv">
      </div>
      <template v-for="item in deptList">
        <div :key="`room${item.deptNo}`" :class="['s0601_cont', `cl_buseoCode_${item.deptNo}`]" :id="`room${item.deptNo}`" style="display:none;">
          <div :class="['s0601_cont', `cl_buseoCode_${item.deptNo}`]" :id="`room${item.deptNo}`" style="display:none;">
            <h3 class="tit">{{item.deptNm}}</h3>
            <table v-if="item.memberlist != null && item.memberlist.length > 0" class="table1 mt20" :summary="`${item.deptNm}`+`${$t('orgGuide')}`">
              <caption>{{item.deptNm}} {{$t('info')}}</caption>
              <colgroup>
                <col width="*" />
                <col width="30%" />
                <col width="30%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">{{$t('responsibilities')}}</th>
                  <th scope="col">{{$t('nm')}}</th>
                  <th scope="col">{{$t('extensionTel')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item2, index2) in item.memberlist" :key="index2">
                  <th scope="row" v-html="$sanitize(item2.workintro2)"></th>
                  <td>{{item2.nm}}</td>
                  <td>{{item2.tel}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <template v-for="(item2) in item.buseolist">
          <div :key="`room${item.deptNo}_${item2.deptNo}`" v-if="item2.useYn == 'Y'" :class="[`s0601_cont`,`cl_buseoCode_${item.deptNo}`,`cl_buseoCode_${item2.deptNo}`]" :id="`room${item.deptNo}_${item2.deptNo}`" style="display:none;">
            <h3 class="tit">{{item2.deptNm}}</h3>
            <table v-if="item2.memberlist != null && item2.memberlist.length > 0" class="table1 mt20" :summary="`${item2.deptNm}`+`${$t('orgGuide')}`">
              <caption>{{item2.deptNm}} {{$t('info')}}</caption>
              <colgroup>
                <col width="*" />
                <col width="30%" />
                <col width="30%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">{{$t('responsibilities')}}</th>
                  <th scope="col">{{$t('nm')}}</th>
                  <th scope="col">{{$t('extensionTel')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item3, index3) in item2.memberlist" :key="`room${item.deptNo}_${item2.deptNo}_${item3.deptNo}_table1${index3}`">
                  <th scope="row" v-html="$sanitize(item3.workintro2)"></th>
                  <td>{{item3.nm}}</td>
                  <td>{{item3.tel}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <template v-for="(item3) in item2.buseolist">
            <div :key="`room${item.deptNo}_${item2.deptNo}_${item3.deptNo}`" v-if="item3.useYn == 'Y'" :class="[`s0601_cont`, `cl_buseoCode_${item.deptNo}`, `cl_buseoCode_${item2.deptNo}`, `cl_buseoCode_${item3.deptNo}`]" :id="`room${item.deptNo}_${item2.deptNo}_${item3.deptNo}`" style="display:none;">
              <h4 class="tit h4_t">{{item3.deptNm}}</h4>
              <table v-if="item3.memberlist != null && item3.memberlist.length > 0" class="table1 mt20" :summary="`${item3.deptNm}`+`${$t('orgGuide')}`">
                <caption>{{item3.deptNm}} {{$t('info')}}</caption>
                <colgroup>
                  <col width="*" />
                  <col width="30%" />
                  <col width="30%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">{{$t('responsibilities')}}</th>
                    <th scope="col">{{$t('nm')}}</th>
                    <th scope="col">{{$t('extensionTel')}}</th>				
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item4, index4) in item3.memberlist" :key="`room${item.deptNo}_${item2.deptNo}_${item3.deptNo}_table2${index4}`">
                    <th scope="row" v-html="$sanitize(item4.workintro2)"></th>
                    <td>{{item4.nm}}</td>
                    <td>{{item4.tel}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </template>
      </template>
    </div>
    <!-- 하단 컨텐츠-->
    <template v-for="(item, index) in contentList">
      <main-content v-if="item.ord > 100" :subYn="true" :contents="[{contentNo: item.contentNo, contentType: item.contentType}]" :key="index"/>
    </template> 
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ImageMap from "image-map";

export default {
  computed: {
    ...mapGetters([
      'subMenuTitle',
    ]),
    upMenuId(){
      return this.$route.query?.upMenuId;
    },
    menuId(){
      return this.$route.query?.menuId;
    },
  },
  watch:{
    'content': {
      handler() {
        this.$nextTick(() => {
          this.doMoblie();
          this.setClickEvent();
          this.setMetaInfo()
        })
      },
      deep:true
    }
  },
  data() {
    return {
      searchParam: {
        menuId: '',
      },
      content: null,
      deptList: [],
      contentList: [],
    }
  },
  mounted() {
    this.doContentList()
  },
  methods: {
    setMetaInfo() {
      const url = document.location.href;
      // 컨텐츠 태그 제거
      const content = "조직도를 통한 조직 연락처안내"; 
      let contentNm = `${this.$t(this.subMenuTitle)}`

      const metaInfoData = {
        title: `${this.$t('koagi')}: ${contentNm}`,
        ogTitle: contentNm,
        ogUrl: url,
        ogDescription: content,
        ogImage: '/logo.png'
      }

      this.$store.dispatch('setMetaInfo', metaInfoData); 
    },
    doContentList() {
      this.searchParam.menuId = this.menuId;
      this.$tran('/site/external/dept/selectDeptList', this.searchParam).then(response => {
        this.content = response.data.cms;
        this.deptList = response.data.buseolist;
        this.contentList = response.data.menuContentList;
      }) 
    },
    setClickEvent(){
      document.querySelectorAll('map').forEach((mapDiv) => {
        const mapChildNode = mapDiv.childNodes;    
        mapChildNode.forEach(e2 => {
          if(e2.nodeName == "AREA"){
            e2.addEventListener('keydown', function(event) {
              if(event.keyCode == 13) {
                const e = new Event('click');
                e2.dispatchEvent(e);
              }
            })

            e2.addEventListener('click', function(event){
              const classList = event.target.classList;

              const contList = Object.entries(document.querySelectorAll('.s0601_cont'))

              for(const item in contList){
                const cont = contList[item];
                cont[1].style.display ='none'
              }

              if(classList.contains('site_tab_btn')){
                const dpContList = Object.entries(document.querySelectorAll('.dp_cls'))

                for(const item in dpContList){
                  const cont = dpContList[item];
                  cont[1].style.display ='none'
                }

                const site = event.target.getAttribute('data-site')
                const siteCont = document.querySelector(`#${site}_id`);
                siteCont.style.display = 'block'
              }
              if(classList.contains('buseo_tab_btn')){
                const idx = event.target.getAttribute('data-idx')
                const deptContList = Object.entries(document.querySelectorAll(".cl_buseoCode_"+idx))
                
                for(const item2 in deptContList){
                  const deptCont = deptContList[item2];
                  deptCont[1].style.display = 'block'
                }
              }
            }); 
          }
        })
      })
    },
    doMoblie() {
      const isMobile = () => { return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)}

      if(isMobile()) {
        ImageMap('img[usemap]');
      } 
    }
  },
}
</script>
