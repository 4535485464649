<template>
  <div class="detail-bottom">
    <div class="detail-bottom__paging">
      <template>
        <button v-if="nextNo != null && nextNo != ''" @click="doPrevNext(nextNo)" type="button" class="up" title="nextPost"></button>
        <button v-else type="button" class="up" disabled></button>
      </template>
      <template>
        <button v-if="prevNo != null && prevNo != ''" @click="doPrevNext(prevNo)" type="button" class="down" title="prevPost"></button>
        <button v-else type="button" class="down" disabled></button>
      </template>
    </div>
    <a href="javascript:void(0);" @click="doList()" class="c-btn lined-btn" title="Back to List">Back to List</a>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    },
    nextNo: {

    },
    prevNo: {

    },
    board: {
      type: Object
    },
  },
  data() {
    return {
      searchParam: {
        contentNo: null,
        postNo: null,
        attcNo: null,
        originBoardNo: null,
        boardDepth: null,
      },
    }
  },
  methods: {
    doPrevNext(postNo) {
      if(postNo != null && postNo != '') {

        this.searchParam.postNo = postNo;
        const qeury = {...this.$route.query}
        qeury.postNo = postNo.toString();

        this.$router.push({query: qeury});
      }
    },
    doList() {
      let name = 'eng_content'
      const query = {...this.$route.query};

      delete query.postNo
      delete query.orginPostNo

      this.$router.push({name: name, query: query})
    },
    doKeydownList(event) {
      if(event.keyCode == 13) {
        this.doList();
      }
    },
  }
}
</script>