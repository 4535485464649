//사이트링크
class rotatecontents{
  
  constructor(object){
    this.pagingobject=null;
    this.pagingstart='';
    this.pagingdelimiter='/';
    this.pagingend='';
    this.rotatemethod=null;
    this.maxzindex=1;
    this.speed=5;
    this.autoplay=true;
    this.autoplaytime=10;

    this.buttonobject=null;
    this.buttontype='text';
    this.buttononclassname='on';
    this.buttononimagename='';
    
    this.itemsnum=[];
    this.items=[];
    this.btns=[];
    this.btnsmover=[];
    this.btnsmout=[];
    let speed;
    this.canimove=true;
    this.timer;
    this.nowno=0
    this.isie=(navigator.userAgent.toLowerCase().indexOf('msie')!=-1)? true : false;

    object.$rolling = this

    this.initialize=function(){
      let isfirst=true;
      const removeobjs=new Array();
      let childs=object.childNodes;
      for(let i=0,j=-1,max=childs.length; i<max; i++){
        if(childs[i].nodeType==1){
          j++;
          if(!isfirst) childs[i].style.display='none';
          this.items.push(childs[i]);
          if(this.rotatemethod=='overlap' || this.rotatemethod=='slide' || this.rotatemethod=='fadein' || this.rotatemethod=='vertical'){
            speed=this.speed;
            object.style.position='relative';
            childs[i].style.position='absolute';
            childs[i].style.left='0px';
            childs[i].style.top='0px';
            childs[i].style.width=object.offsetWidth+'px';
            childs[i].style.height=object.offsetHeight+'px';
            if(this.rotatemethod=='fadein'){
              setopacity(childs[i],(isfirst)? 100 : 0);
            }
          }
          if(isfirst) isfirst=false;
        }
      }
      this.itemsnum=this.items.length;
      if(removeobjs[0]){
        for(let i=0,max=removeobjs.length; i<max; i++) object.removeChild(removeobjs[i]);
      }

      if(this.pagingobject) this.pagingobject=document.getElementById(this.pagingobject);
      this.settitlenpaging(this.nowno);
      object.style.width=object.offsetWidth+'px';
      object.style.height=object.offsetHeight+'px';
      object.style.overflow='hidden';
      object.onmouseover =function(){
        clearTimeout(this.$rolling.autoplaytimer);
      }
      object.onmouseout=function(){
        this.$rolling.autoplayaction();
      }
      if(this.buttonobject){
        this.buttonobject=document.getElementById(this.buttonobject);
        childs=this.buttonobject.childNodes;
        for(let i=0,j=0,max=childs.length; i<max; i++){
          if(childs[i].nodeType==1){
            childs[i].myno=j;
            if(childs[i].onmouseover) this.btnsmover.push(childs[i].onmouseover);
            if(childs[i].onmouseout) this.btnsmout.push(childs[i].onmouseout);
            this.btns.push(childs[i]);
            j++;
          }
        }
        if(this.items.length!=this.btns.length) this.buttonobject=null;
        else this.setbtns();
      }
      this.autoplayaction();
    }

    this.settitlenpaging=function(no){
      if(this.pagingobject) this.pagingobject.innerHTML=this.pagingstart+(no+1)+this.pagingdelimiter+this.itemsnum+this.pagingend;
    }

    this.setzindex=function(no){
      for(let i=0; i<this.itemsnum; i++){
        if(i==no){
          this.items[i].style.zIndex=this.maxzindex+3;
          this.items[i].style.display='';
        }else if(i==this.nowno){
          this.items[i].style.zIndex=this.maxzindex+2;
        }else{
          this.items[i].style.zIndex=this.maxzindex+1;
          this.items[i].style.display='none';
        }
      }
    }

    this.setbtns=function(no){
      if(!no && no!==0) no=this.nowno;
      for(let i=0; i<this.itemsnum; i++){
        if(i==no){
          this.setbtnon(this.btns[i]);
          this.btns[i].onmouseover=null;
          this.btns[i].onmouseout=null;
        }else{
          this.setbtnoff(this.btns[i]);
          this.btns[i].onmouseover=function(){
            this.setbtnon(this);
            if(this.btnsmover[this.myno]) this.btnsmover[this.myno]();
          }
          this.btns[i].onmouseout=function(){
            this.setbtnoff(this);
            if(this.btnsmout[this.myno]) this.btnsmout[this.myno]();
          }
        }
      }
    }

    this.setbtnon=function(target){
      let classes,exist=false;
      if(this.buttontype=='image'){
          let btnimg=target.getElementsByTagName('img')[0];
          let btnimgexp=btnimg.src.substring(btnimg.src.lastIndexOf('.'));
          if(btnimg.src.indexOf(this.buttononimagename+btnimgexp)==-1) btnimg.src=btnimg.src.replace(btnimgexp,this.buttononimagename+btnimgexp);
      }
      classes=target.className.split(' ');
      for(let i=0; i<classes.length; i++) if(classes[i]==this.buttononclassname) exist=true;
      if(!exist) target.className=target.className+' '+this.buttononclassname;
    }

    this.setbtnoff=function(target){
      let classes;
      if(this.buttontype=='image'){
        let btnimg=target.getElementsByTagName('img')[0];
        let btnimgexp=btnimg.src.substring(btnimg.src.lastIndexOf('.'));
        if(btnimg.src.indexOf(this.buttononimagename+btnimgexp)!=-1) btnimg.src=btnimg.src.replace(this.buttononimagename+btnimgexp,btnimgexp);
      }
      if(target.className.indexOf(this.buttononclassname)!=-1){
        if(target.className.indexOf(' ')==-1) target.className=target.className.replace(this.buttononclassname,'');
        else{
          classes=target.className.split(' ');
          for(let i=0; i<classes.length; i++) if(classes[i]==this.buttononclassname) classes[i]='';
          target.className=classes.join(' ');
        }
      }
    }

    this.change=function(no){
      no=no-1;
      if(no!=this.nowno){
        if(this.rotatemethod=='overlap') this.moveaction('overlap',no,(no<this.nowno)? 'prev' : 'next');
        else if(this.rotatemethod=='slide') this.moveaction('slide',no,(no<this.nowno)? 'prev' : 'next');
        else if(this.rotatemethod=='vertical') this.moveaction('vertical',no,(no<this.nowno)? 'prev' : 'next');
        else if(this.rotatemethod=='fadein') this.fadeinaction(no);
        else this.hideitem(no);
        this.settitlenpaging(no);
        if(this.buttonobject) this.setbtns(no);
      }
    }

    this.stop = function() {
      clearTimeout(this.autoplaytimer);
      this.hideitem;
    };

    this.play = function() {
      if(!this.autoplay) return;
        this.autoplaytimer=setTimeout(this.next.bind(this),this.autoplaytime*1000);
    };

    this.viewall = function() {
    
      let banbtn = document.getElementById('lbtn'); 
      if (viewwrap.style.height == '' ) {	
        clearTimeout(this.autoplaytimer);
        let objList = document.getElementById(objectid);
        let objListul = objList.getElementsByTagName('ul');
        for (let i=0; i < objListul.length ; i++) {
          objListul[i].style.position = 'static';
          objListul[i].style.display = 'block';
        }	
        banbtn.style.display = 'none';
        banclose.style.display = 'none';
        object.onmouseover = "null";
        object.onmouseout = "null";

      }else {
        
        let objList = document.getElementById(objectid);
        let objListul = objList.getElementsByTagName('ul');
        for (let i=0; i < objListul.length ; i++) {
          objListul[i].style.position = 'absolute';
          objListul[i].style.display = '';
        }
        if(!this.autoplay) return;
        this.autoplaytimer=setTimeout(this.next.bind(this),this.autoplaytime*1000);
        banbtn.style.display = '';
        banclose.style.display = 'block'
      }
    };

    this.prev=function(){
      let prevno=(this.nowno==0)? this.itemsnum-1 : this.nowno-1;
      if(this.rotatemethod=='overlap') this.moveaction('overlap',prevno,'prev');
      else if(this.rotatemethod=='slide') this.moveaction('slide',prevno,'prev');
      else if(this.rotatemethod=='vertical') this.moveaction('vertical',prevno,'prev');
      else if(this.rotatemethod=='fadein') this.fadeinaction(prevno);
      else this.hideitem(prevno);
      this.settitlenpaging(prevno);
    }

    this.next=function(){
      let nextno=(this.nowno==(this.itemsnum-1))? 0 : this.nowno+1;
      if(this.rotatemethod=='overlap') this.moveaction('overlap',nextno,'next');
      else if(this.rotatemethod=='slide') this.moveaction('slide',nextno,'next');
      else if(this.rotatemethod=='vertical') this.moveaction('vertical',nextno,'next');
      else if(this.rotatemethod=='fadein') this.fadeinaction(nextno);
      else this.hideitem(nextno);
      this.settitlenpaging(nextno);
      if(this.buttonobject) this.setbtns(nextno);
    }

    this.autoplaytimer=null;
    this.autoplayaction=function(){
      if(!this.autoplay) return;
      this.autoplaytimer=setTimeout(this.next.bind(this),this.autoplaytime*1000);
    }

    this.hideitem=function(no){
      clearTimeout(this.autoplaytimer);
      for(let i=0; i<this.itemsnum; i++) this.items[i].style.display=(i==no)? '' : 'none';
      this.autoplayaction();
      this.nowno=no;
    }

    this.getleft=function(no){
      return parseInt(this.items[no].style.left);
    }
    this.setleft=function(no,value){
      this.items[no].style.left=value+'px';
    }

    this.gettop=function(no){
        return parseInt(this.items[no].style.top);
    }
    this.settop=function(no,value){
      this.items[no].style.top=value+'px';
    }

    this.moveaction=function(method,no,direction){
      clearTimeout(this.autoplaytimer);
      if(this.canimove){
        this.canimove=false;

        if(method=='slide') this.setleft(no,(direction=='prev')? -(object.offsetWidth) : object.offsetWidth);
        if(method=='vertical') this.settop(no,(direction=='prev')? -(object.offsetHeight) : object.offsetHeight);
        
        this.setzindex(no);
        let nowv,now,snow,to=0,mv=0;
        const action=function(){
          clearTimeout(this.timer);
          now=this.getleft(no);
          nowv=this.gettop(no);

          if(method=='slide') snow=this.getleft(this.nowno);
          if(method=='vertical') snow=this.gettop(this.nowno);
          if(now!=to){
            mv=(now-to)/speed;
            this.setleft(no,(direction=='prev')? Math.ceil(now-mv) : Math.floor(now-mv));                  
            if(method=='slide') this.setleft(this.nowno,(direction=='prev')? Math.ceil(snow-mv) : Math.floor(snow-mv));                   
            setTimeout(action.bind(this),10);
          } else if (nowv!=to) {
            mv=(nowv-to)/speed;
            this.settop(no,(direction=='prev')? Math.ceil(nowv-mv) : Math.floor(nowv-mv));           
            if(method=='vertical') this.settop(this.nowno,(direction=='prev')? Math.ceil(snow-mv) : Math.floor(snow-mv));
              setTimeout(action.bind(this),10);
          } else{
            this.autoplayaction();
            clearTimeout(this.timer);
            this.canimove=true;
            this.nowno=no;
          }
        }.bind(this);
        action();
      }
    }

    const getopacity=function(target){
      return (this.isie)? parseInt(target.style.filter.match(/alpha\(opacity=([0-9]+)\)/)[1]) : target.style.opacity*100;
    }

    const setopacity=function(target,value){
      if(this.isie) target.style.filter='alpha(opacity='+value+')';
      else target.style.opacity=value/100;
    }

    this.fadeinaction=function(no){
      clearTimeout(this.autoplaytimer);
      if(this.canimove){
        this.canimove=false;
        setopacity(this.items[no],0);
        this.setzindex(no);
        let nowo,to=0;
        const action=function(){
          clearTimeout(this.timer);
          nowo=getopacity(this.items[no]);
          if(nowo!=100){
            setopacity(this.items[no],Math.ceil(nowo+(100-nowo)/speed));
            setTimeout(action.bind(this),10);
          }else{
            this.autoplayaction();
            clearTimeout(this.timer);
            this.canimove=true;
            this.nowno=no;
          }
        }.bind(this);
        action();
      }
    }
  }
}export default rotatecontents