<template>
  <div class="board_paginate">
      <template v-if="pagingInfo.prevPage > 0">
        <a href="javascript:void(0);" @click="doPage(1)" class="pre" >{{$t('first')}}</a>
        <a href="javascript:void(0);" @click="doPage(pagingInfo.prevPage)" class="pre" >{{$t('before')}}</a>
      </template>
      <template v-for="(index) in (pagingInfo.lastPage - pagingInfo.firstPage) + 1">
        <strong v-if="(index+pagingInfo.firstPage)-1 == currentPage" :key="`selected_${index}`">{{(index+pagingInfo.firstPage)-1}}</strong>
        <a href="javascript:void(0);" v-else @click="doPage((index+pagingInfo.firstPage)-1)" :key="`noselected_${index}`" >{{(index+pagingInfo.firstPage)-1}}</a>
      </template>
      <template v-if="pagingInfo.nextPage > 0">
        <a href="javascript:void(0);" @click="doPage(pagingInfo.nextPage)" class="next" >{{$t('next')}}</a>
        <a href="javascript:void(0);" @click="doPage(pagingInfo.totalPage)" class="next" >{{$t('end')}}</a>
      </template>
    </div>
</template>

<script>
export default {
  props: {
    pagingInfo: {
      type: Object
    },
    currentPage: {
    },
    tabindex: {
      type: Number,
      default: 1
    },
  },
  methods: {
    doPage(index) {
      this.$emit("input", index)
    },
  }
}
</script>