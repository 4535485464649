class WindowSize {
  constructor() {
    this.w = 0;
	  this.h = 0;
	  return this.update();
  }

  update() {
    const d = document;
    this.w = 
      (window.innerWidth) ? window.innerWidth
    : (d.documentElement && d.documentElement.clientWidth) ? d.documentElement.clientWidth
    : d.body.clientWidth;
    this.h = 
      (window.innerHeight) ? window.innerHeight
    : (d.documentElement && d.documentElement.clientHeight) ? d.documentElement.clientHeight
    : d.body.clientHeight;
    return this;
  }
}
export default WindowSize