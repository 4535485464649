<template>
  <section class="sv-section main-content">
    <div>
      <h2 class="page-title" v-if="$route.name == 'seed_content' && depth==0">{{subMenuTitle}}</h2>
      <div class="layout" v-html="$sanitize(contentCtnt)"></div>
      <template>
        <!-- 탭사용시 -->
        <ChildTab v-if="content.tabUseYn == 'Y'" :grpList="grpList" v-model="currentTab" @changeTab="changeTab" :depth="depth" :isSameAllow="true"/>
        <!-- 탭 미사용시 이어서 컨텐츠 조회 -->
        <seed-main-content class="layout" v-else :subYn="true" :contents="getContentList(grpList)" :depth="depth+1"/>

        <!-- 하위 컨텐츠 유형이 컨텐츠 or 게시판일 경우 메인컨텐츠(재귀) -->
        <seed-main-content v-if="isContent" :subYn="true" :contents="[{contentNo: subContentNo, contentType: subContentType}]" :depth="depth+1"/>
      </template>
    </div>
  </section>
</template>

<script>
import ChildTab from '@/views/component/ChildTab'

export default {
  components: {
    ChildTab,
  },
  props: {
    //게시판 번호
    contentNo: {
      type: String,
      default: ''
    },
    // 현재 깊이 s?와 연관됨
    depth: {
      type: Number
    },
  },
  computed:{
    grpSize() {
      return this.grpList.reduce((e,cur) => {
                  return cur.hiddenYn!="Y"?e+1:0
              },0)
    },
    subMenuTitle() {
      const upMenu = this.$store.state.treeData[this.$route.query.upMenuId]
      if(upMenu != null) {
        let menuInfo = upMenu.subMenu[this.$route.query.menuId]

        if(this.$route.query.upMenuId == this.$route.query.menuId){
          menuInfo = Object.entries(upMenu.subMenu)[0][1];
        }
        return menuInfo?.mulLangCd
      }
    },
    contentCtnt(){
      return this.content?.content
    }
  },
  watch: {
    contentNo(newVal) {
      this.searchParam.contentNo = newVal
      this.subContentNo = null
      this.grpList = []
      this.doContent()
    },
    '$route.query': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          if(this.grpList != null && this.grpSize > 0) {
            this.setCurrentTab();
          }  
        }
      }
    }
  },
  created() {
    if(this.contentNo != null && this.contentNo != '') {
      this.searchParam.contentNo = this.contentNo
      this.grpList = []
      this.subContentNo = null
      this.doContent()
    }
  },
  data() {
    return {
      searchParam: {
        contentNo: '',
      },
      content: {
        accCd: null,
        accNm: null,
        content: null,
        contentNm: null,
        contentNo: null,
        editerYn: null,
        grpYn: 'N',
        tabUseYn: 'N',
        useYn: 'Y',
      },
      grpList: [],
      currentTab: 'none',
      currentTabIdx: 0,
      isContent: false,
      subContentNo: '',
      subContentType: 'A',
    }
  },
  methods: {
    setCurrentTab() {
      const isFirst = this.$route.params.isFirst
      
      if(isFirst != null&& isFirst != ''){
        const grpList = this.grpList

        if(grpList != null && this.grpSize > 0){
          const firstGrpList = grpList[0]
          const grpCd = firstGrpList.grpCd

          // 탑메뉴 or 사이드바에서 들어왔을 경우 첫번째 그룹 선택
          this.currentTab = grpCd;
        }
      }else{
        this.currentTab = this.$route.query[`s${this.depth}`]
      }
    },
    changeTab(data, contentType, key, isSameAllow=false) {
      const query = {...this.$route.query}
      query[`s${this.depth}`] = key
      
      //주소가 같이 않으면 주소 변경
      if(JSON.stringify(this.$route.query) != JSON.stringify(query)) {
        const param = {query};
        
        delete param.query.page;
        delete param.query.searchCD;
        delete param.query.keyword;

        this.$router.push(param);
      }

      //컨텐츠가 분류가아니면 하위 컴포넌트 조회(컨텐츠or게시판)
      if(contentType != 'A'){
        //하위컨텐츠의 타입 전달
        this.subContentType = contentType
        this.isContent = true;
        this.subContentNo = data;

        // 게시물 상세에서 탭 누를때 
        if(isSameAllow) {
          const param = {query:{...query}, name:'content'};
            
          delete param.query.postNo;
          delete param.query.page;

          this.$router.push(param);
        }
      }
    },
    doContent() {
      this.content = {}
      if(this.searchParam.contentNo != null && this.searchParam.contentNo != ''){
        this.$tran('/site/external/contents/selectContent', this.searchParam).then(response => {
          this.content = response.data.content;
          this.grpList = response.data.grpList;

          if(this.content != null){
            if(this.content.tabUseYn == 'Y') {
              this.setCurrentTab();
            } 

            this.$nextTick(()=>{
              let script = this.content?.content?.match(/<script.*?>(.*?)<\/script>*/s);
              
              if(script != null) {
                script = script[1];
                let scriptTag = document.getElementById(this.searchParam.contentNo);
                if(scriptTag != null){
                  document.head.removeChild(document.getElementById(this.searchParam.contentNo))
                }
                
                scriptTag = document.createElement('script');
                scriptTag.type= 'text/javascript';
                scriptTag.id= this.searchParam.contentNo;
                scriptTag.appendChild(document.createTextNode(script));
                document.head.appendChild(scriptTag);
              }

              const hash = this.$route?.hash;
              
              if(hash != null && hash != '') {
                this.$el.querySelector(hash)?.scrollIntoView()
              }
            })
            
            this.setMetaInfo();
          }
        })   
      }
    },
    getContentList(grpList){
      const contentList = []
      
      for(const index in grpList){
        const grp = grpList[index];
        const contentType =grp.contentType;
        const linkUrl = grp.linkUrl;
        if(contentType != 'A'){
          contentList.push({contentNo : linkUrl, contentType : contentType});
        }
      }
      return contentList;
    },
    setMetaInfo() {
      const url = document.location.href;
      // 컨텐츠 태그 제거
      const content = this.$sanitize(this.content.content, {allowedTags:[]}).replace(/(\n|\t|\r)/g, ""); 
      const contentNm = this.content.contentNm;

      const metaInfoData = {
        title: `Baekdudaegan Global Seed Vault (BGSV): ${contentNm}`,
        ogTitle: contentNm,
        ogUrl: url,
        ogDescription: content,
        ogImage: '/logo.png'
      }

      this.$store.dispatch('setMetaInfo', metaInfoData); 
    },
  }
}
</script>