<template>
  <div ref="editorWrapper" class="editorWrapper"  @focus="doFocus">
    <editor ref="tuieditor" :initialValue="value" :options="defaultOptions" initialEditType='wysiwyg' @change="doChange"></editor>
  </div>
</template>

<script>
import { Editor } from '@toast-ui/vue-editor';
import { sendPostImg } from '@/libs/comm/transection';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';


export default {
    props: {
        tabindex: {
          type: Number,
          default: -1
        },
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    components: {
        editor: Editor,
    },
    watch: {
        value(newVal) {
            if (newVal != null && newVal != '') {
              if(this.editor != null) {
                this.editor.setHTML(newVal, false)
              }
            }
        }
    },
    computed:{
      editor() {
        return this.$refs?.tuieditor?.editor
      }
    },
    data() {
        return {
            defaultOptions: {
              autofocus:false,
                minHeight: '200px',
                useCommandShortcut: true,
                usageStatistics: true,
                hideModeSwitch: false,
                placeholder: this.placeholder,
                toolbarItems: [
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'task', 'indent', 'outdent'],
                    ['table', 'link'],
                    ['code', 'codeblock'],
                    ['scrollSync'],
                ],
                plugins: [colorSyntax, tableMergedCell]
            },
        }
    },
    mounted() {
        this.$refs.tuieditor.invoke("addHook", "addImageBlobHook", function (
            file,
            callback
        ) {

            const extList = ["jpg", "jpeg", "png", "bmp", "svg", "gif"];
            const fileSize = 1024 * 1024 * 5;
            const checkExt = extList.find(element => file.type.indexOf(element) > -1);

            if (checkExt == null) {
                return new swal({
                    title: this.$t('imgFile'),
                    text: this.$t('imgKind'),
                    icon: "warning"
                });
            }

            if (file != null && file.size > fileSize) {
                return new swal({
                    title: this.$t('imgFile'),
                    text: this.$t('imgSize'),
                    icon: "warning"
                });
            }

            file.mainYn = 'N';
            file.ord = 0;

            const insertList = [];
            insertList.push({
                name: file.name,
                mainYn: file.mainYn,
                ord: file.ord
            });

            const formData = new FormData();
            formData.append('files', file);
            formData.append('insertImgList', JSON.stringify(insertList));

            sendPostImg("/site/file/uploadImg", {}, formData).then(response => {
                const imageList = response.data.imageList;
                callback(imageList[0]);
            })

        });

        this.$refs.tuieditor.editor.on('keydown', function(m,e){
          this.doKeyDown(m,e)
        })
    },
  methods: {
    doChange(e) {
      this.$emit('input',this.editor.getHTML())
    },
    doFocus(e){
      this.editor.focus()
    },
    doKeyDown(m,e){
      if(e.keyCode == 9 && !e.shiftKey){
        let curTabindex= this.$refs.editorWrapper.tabIndex
        const tabIdxEqList =[...document.querySelectorAll(`[tabindex="${curTabindex}"]`)]
        const nexttabindex =tabIdxEqList.findIndex((e)=>{return [...e.classList].indexOf('editorWrapper')> -1})

        if(tabIdxEqList.length-1 == nexttabindex){
          let targetEle = document.querySelector(`[tabindex="${curTabindex+1}"]`)
          while(targetEle == null){
            curTabindex++;
            targetEle = document.querySelector(`[tabindex="${curTabindex+1}"]`)
          }
          targetEle.focus()
        }else{
          tabIdxEqList[nexttabindex+1].focus()
        }
      }
    }
  },
  
}
</script>

<style>

</style>