<template>
  <div>
    <input type="date" v-model="listData[0]" format="yyyymmdd" > ~ <input type="date" v-model="listData[1]" format="yyyymmdd" >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    },
    fieldData: {
      type: Object
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    listData: {
      handler(newVal) {
        this.value[this.fieldData.fieldCdNm] = newVal
      }
    },
    value: {
      handler(newVal) {
        const data = newVal[this.fieldData.fieldCdNm];
        if(data !=null && data != ''){ 
          this.listData = data
        }
      }
    }
  },
  data(){
    return {
      listData: []
    }
  },
  methods: {
  }
}
</script>