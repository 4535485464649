<template>
  <div class="board layout">
    <!-- 게시판 컨텐츠 -->
    <!-- 주요역할 : 이미지게시판 / 일반게시판 분기 -- 게시판 설정 조회 및 전달 -->
    <template>
      <h3 class="page-title" v-if="$route.name == 'seed_content' && depth==0">{{subMenuTitle}}</h3>
      <component class="board-list-parent-div" v-if="board.boardNo != null && !isLoading" :is="isComponent" :board="board" :grpList="grpList" :depth="depth"></component>   
    </template> 
  </div>
</template>

<script>
import dynamicBoard from '@/views_seed/board/dynamicBoard'
import dynamicDetail from '@/views_seed/board/dynamicDetail'
import dynamicImage from '@/views_seed/board/dynamicImage'
import dynamicModify from '@/views_seed/board/dynamicModify'

export default {
  components: {
    dynamicBoard,
    dynamicDetail,
    dynamicImage,
    dynamicModify
  },
  props: {
    //게시판 번호
    contentNo: {
      type: String,
      default: ''
    },
    // 현재 깊이 s?와 연관됨
    depth: {
      type: Number
    },
  },
  watch: {
    //게시판 번호 변경시 동작
    contentNo(newVal) {
      this.doList(newVal)
    }
  },
  computed: {
    subMenuTitle() {
      const upMenu = this.$store.state.treeData[this.$route.query.upMenuId]
      if(upMenu != null) {
        let menuInfo = upMenu.subMenu[this.$route.query.menuId]

        if(this.$route.query.upMenuId == this.$route.query.menuId){
          menuInfo = Object.entries(upMenu.subMenu)[0][1];
        }
        return menuInfo?.mulLangCd
      }
    },
    menuId() {
      // 게시판 설정 조회시 사용
      return this.$route.query.menuId
    },
    isComponent(){
      const routeNm = this.$route.name;

      // 게시판의 경우 수정 / 상세 / 게시물리스트 / 이미지게시물 리스트로 분기
      if(routeNm == 'seed_dynamicDetail'){
        return 'dynamicDetail';
      }else if(routeNm == 'seed_dynamicModify'){
        return 'dynamicModify';
      }else{
        if(this.board.boardTypeCd == 'CMS002.img' || this.board.boardTypeCd == 'CMS002.imgThum'){
          return 'dynamicImage';
        }else{
          return 'dynamicBoard';
        }
      }
    }
  },
  created() {
    //게시판 컴포넌트 처음 진입시 동작(watch와는 중복 동작하지 않음)
    this.doList(this.contentNo)
  },
  data() {
    return {
      searchParam: {
        menuId: '',
        contentNo: '',
      },
      board: {
        boardNo: null,
        boardTypeNm: null,
        accNm: null,
        accCd: null,
        boardNm: null,
        boardTypeCd: null,
        grpDispCd: null,
        writeYn:'Y',
        certYn:'N',
        pwYn:'Y',
        grpYn:'N',
        attcYn:'N',
        attcLimitNo:0,
        attcLimitSize:0,
        attcAllowExt: '',
        attcAllowExts: null,
        replyYn:'N',
        commYn:'N',
        ndisYn:'N',
        ndisRequYn: 'N',
        useTeamYn:'N',
        useStDt:'',
        useEnDt:'',
        useTeamOverYn:'N',
        useYn:'Y',
        delFlagYn:'N',
        limitWord:'',
        postTeamYn:'N',
        postDd:0,
        pagePerNo:0,
        newIconDispDd:0,
        noticeYn:'N',
        mainDispYn:'N',
        defContent:'',
        imgWidth: '',
        imgHeight: '',
        imgRowPerCnt: 0,
      },
      isLoading: false,
      //현재 게시판(this.board.boardNo)에 그룹 
      grpList: [],
    }
  },
  methods: {
    doList(contentNo){
      this.isLoading=true
      //게시판 번호 널처리
      if(contentNo != null && contentNo != '') {
        //게시판 설정조회 세팅
        this.searchParam.contentNo = contentNo

        //게사판 설정 조회
        if(this.menuId != null && this.menuId != ''){
          this.searchParam.menuId = this.menuId
        }

        this.$tran('/site/external/contents/selectBoardDetail', this.searchParam).then(response => {
          this.$set(this, 'board', response.data.board)
          this.$set(this, 'grpList', response.data.grpList)
        }).catch(e => {
          this.$router.push("/404");
        }).finally(() => {
          this.isLoading=false
        })
      }
    }
  }
}
</script>