<template>
  <div class="app" :class="$route.path=='/seed'?['seed']:$route.path=='/eng'?['eng']:[]">
    <a :href="$route.path=='/'||$route.path=='/eng'||$route.path=='/seed'?'#container':'#cont_title'" class="sknavi" title="본문바로가기">본문으로 바로가기</a>
    <router-view/>
  </div>
</template>

<script>
export default {
}
</script>

<style>

</style>