<template>
  <dl class="info imgDl">
    <dt class="imgDt pl35">{{$t('img')}}</dt>
    <dd class="imgDd">
      <a href="javascript:void(0);" v-for="(val, index) in completList" :key="index" :title="`${val.imgNm} 파일 다운로드`" @click="fileDownload(val.imgNo)">
        <img :src="getFileImgPath(val.imgNm)" :alt="`${val.imgNm} 파일 다운로드`" />
        <span class="fileClick">{{val.imgNm}}</span>
        <span class="size"> ({{byteToKBorMB(val.attcFileSize)}})</span>
      </a>
    </dd>
  </dl>
</template>

<script>
import { downloadResponse } from '@/libs/comm/util';

export default {
  mounted() {
    this.getCompletList(this.boardNo, this.postNo);
  },
  components: {
  },
  props: {
    boardNo: {
      type: String,
      default: ''
    },
    postNo: {
      type: [String, Number],
      default: ''
    },
    fileInfo:{
      type: Object
    },
    tabindex: {
      type: Number,
      default: 1
    },
    excludeMain: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    postNo(newVal) {
      if(newVal != null && newVal != '') {
        this.getCompletList(this.boardNo, newVal)
      }
    },
    boardNo(newVal) {
      if(newVal != null && newVal != '') {
        this.getCompletList(newVal, this.postNo)
      }
    }
  },
  data() {
    return {
      completList: [],
      imgPath: '',
    }
  },
  methods: {
    getCompletList(boardNo, postNo){
      if(!!boardNo && !!postNo){
        let paramData = {
          boardNo: boardNo,
          postNo: postNo
        };
        return this.$tran('/site/file/attchImageList', paramData, '').then(response => {
          if(this.excludeMain){
            this.completList = this.getImageListFilter(response.data.imageList);
          }else{
            this.completList = response.data.imageList;
          }
        }).catch(error => {
            this.response = error.message
        })
      }
    },
    fileDownload(imgNo){
      const paramData = {
        imgNo: imgNo,
      };
      this.$tranFileDown('/site/file/downloadImage', paramData).then(response => {
        downloadResponse(response);
      }).catch(error => {
          this.response = error.message
      })
    },
    fileDownloadAll(){
      const imgNoList = this.completList.map(element => element.imgNo);
      const firstImgNo = this.completList.find(element => element != undefined);
      const paramData = {
        imgNo: firstImgNo.imgNo,
        imgNoList: imgNoList,
        fileDownloadNm: this.fileInfo.fileDownloadNm,
      };
      this.$tranFileDown('/site/file/downloadImage', paramData).then(response => {
        downloadResponse(response);
      }).catch(error => {
          this.response = error.message
      })
    },
    byteToKBorMB(byteVal){
      const KB = Math.trunc(Number(byteVal) / 1024);
      const MB = Math.trunc(Number(KB) / 1024)
      if (KB > 1024){
        return MB+' MB';
      } else if (KB == 0){
        return byteVal+' KB';
      } else {
        return KB+' KB';
      }
    },
    getFileImgPath(imgNm){
      const fileExt = this.getExtLowerCase(imgNm);
      const existImg = ['avi','doc','gif','hwp','jpg','pdf','ppt','txt','wmv','xls','zip']
      if(existImg.indexOf(fileExt) < 0){
        return require('@/assets/img/board/nninc_simple/img/file/icon_no.png')
      } else {
        return require('@/assets/img/board/nninc_simple/img/file/icon_'+fileExt+'.png')
      }
    },
    getExtLowerCase(fileNm){
      return fileNm.substr(fileNm.lastIndexOf('.')+1).toLowerCase();
    },
    getImageListFilter(imageList){
      return imageList.filter((e)=>{if(e.mainYn!='Y'){return true} return false})
    }
  }
}
</script>