<template>
  <div id="foot" :style="footStyle">
    <div class="wsize">
      <div class="flogo">
        <img src="@/assets/img/common/foot_logo.gif" :alt="$t('koreaKoagi')"/>
      </div>
      <div class="finfo">
        <div class="fmenu">
          <ul>
            <li>
              <router-link :to="{ name: 'dynamicDetail', query: {menuId: 'M0003', upMenuId:'M0001', s0:null, postNo: '-1'}}" >
                <span class="green">{{$t('privacy')}}</span>
              </router-link>
            </li>
            <li>
              <a href="javascript:void(0);" @click="doContentLink('M0004', 'M0001', null)" >{{$t('termsUse')}}</a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="doContentLink('M0005', 'M0001', null)" >{{$t('rejectCollectEmail')}}</a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="doContentLink('M0023', 'M0001', null)" >{{$t('homepageImprov')}}</a>
            </li>
          </ul>
        </div>
        <div class="ftext">
          <p class="adress">
            <strong class="mr10">{{$t('koagi')}}</strong>
            <span>
              <span class="eng">(30129)</span> 세종특별자치시 정부
              <span class="eng">2</span>청사로 
              <span class="eng">10</span>, 
              <span class="eng">SM</span>타워 
              <span class="eng">3</span>층 
              <span class="bu eng">044-270-5005</span>
            </span>
          </p>
          <p class="copy">
            ⓒKorea Arboreta and Gardens Institute.<br />All Rights Reserved.
          </p>
        </div>
      </div>
      <div class="foot_site" v-if="$route.path!='/'">
        <p class="btn">
          <a href="javascript:void(0);" @click="doSiteWClick()" >{{$t('relatedSite')}}</a>
        </p>
        <transition name="slide">
          <div id="site_all" v-show="isShow">
            <ul>
              <li>
                <a href="http://www.forest.go.kr/" target="_blank" title="새창열림">{{$t('forest')}}</a>
              </li>
              <li>
                <a href="https://www.kofpi.or.kr/" target="_blank" title="새창열림">{{$t('kofpi')}}</a>
              </li>
              <li>
                <a href="http://www.fowi.or.kr/" target="_blank" title="새창열림">{{$t('fowi')}}</a>
              </li>
              <li>
                <a href="http://www.forest.go.kr/" target="_blank" title="새창열림">{{$t('nationalForest')}}</a>
              </li>
              <li>
                <a href="http://daslim.fowi.or.kr/" target="_blank" title="새창열림">{{$t('daslimFowi')}}</a>
              </li>
              <li>
                <a href="http://www.ipet.re.kr/" target="_blank" title="새창열림">{{$t('ipet')}}</a>
              </li>
              <li>
                <a href="http://www.rda.go.kr/" target="_blank" title="새창열림">{{$t('rda')}}</a>
              </li>
              <li>
                <a href="http://www.nature.go.kr/" target="_blank" title="새창열림">{{$t('natureInfo')}}</a>
              </li>
              <li>
                <a href="http://www.nature.go.kr/kpni/index.do" target="_blank" title="새창열림">{{$t('natureKpni')}}</a>
              </li>
              <li>
                <a href="http://gis.kofpi.or.kr/gis/main.do" target="_blank" title="새창열림">{{$t('kofpiGis')}}</a>
              </li>
              <li>
                <a href="http://www.forest.go.kr/newkfsweb/kfs/idx/SubIndex.do?orgId=fgis&mn=KFS_02_04#link" target="_blank" title="새창열림">{{$t('forestSpaceInfo')}}</a>
              </li>
              <li>
                <a href="https://ftis.forest.go.kr/ftisWebApp/main/main.do" target="_blank" title="새창열림">{{$t('forestScience')}}</a>
              </li>
            </ul>
          </div>
        </transition>
      </div>
      <div class="fmark">
          <ul>
            <li>
              <a title="새창" href="https://www.wa.or.kr/board/list.asp?BoardID=0006" target="_blank">
                <img src="@/assets/img/common/wa_mark_240110.png" alt="(사)한국장애인단체총연합회 한국웹접근성인증평가원 웹 접근성 우수사이트 인증마크(WA인증마크)" />
              </a>
            </li>
            <li>
              <img src="@/assets/img/common/fmark_icon01.png" :alt="$t('familyOrg')"/>
            </li>
            <li>
              <img src="@/assets/img/common/fmark_icon02.png" :alt="$t('rdJob')"/>
            </li>
            <li>
              <img src="@/assets/img/common/fmark_icon03.png" :alt="$t('serviceCrt')"/>
            </li>
            <li>
              <img src="@/assets/img/common/fmark_icon04.png" :alt="$t('isrCrt')"/>
            </li>
          </ul>
        </div>
    </div>     
  </div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isShow: false,
      footStyle: {zIndex:1002},
    }
  },
  methods: {
    doContentLink(menuId, upMenuId, depth) {
      const bfrMenuId = this.$route.query?.menuId;
      if(menuId != bfrMenuId) {
        this.$router.push({name:'content', query: {menuId: menuId, upMenuId: upMenuId, s0: depth}})
      }
    }, 
    doSiteWClick() {
      this.isShow = !this.isShow

      if(this.isShow) {
        this.footStyle.zIndex=1002;
      }else{
        this.footStyle.zIndex=1002;
      }
    },
  },
}
</script>

<style scoped>
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-enter-to, .slide-leave {
   max-height: 297px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>