<template>
  <div>
    <layoutTop @onTotalSearch="onTotalSearch"/>
    <!-- 검색 파넬 -->
    <aside class="sv-search" :class="isTotalSearch?'active':''">
      <div class="dimmer"></div>
      <div class="search-wrap">
        <div class="search-input">
          <div class="input-wrap">
            <input type="text" id="searchInput" placeholder="Search" v-model="keyword" @keydown="doTotalSearchFocus($event)">
            <button type="button" class="search-btn" @click="doTotalSearch"></button>
          </div>
          <button type="button" id="searchClose" class="search-close__btn" @click="onTotalSearch" @keydown="doTotalSearchKeyDown($event)"></button>
        </div>
      </div>
    </aside>
    <!-- Main Container -->
    <article class="sv-container" :class="$route.name == 'seed_dynamicDetail'? 'board-detail-container': 'content-container'" v-if="$route.path!='/seed'">
      <router-view />
    </article>    
    <main class="sv-container main-container" v-if="$route.path=='/seed'">
      <!-- 메인 슬라이더 -->
      <section class="sv-main__slider sv-section">
        <template v-for="(item, index) in slideVisualList">
          <div class="slider-txt" v-html="$sanitize(item.content)" :key="`txt${index}`">
          </div>
        </template>
        <swiper ref="swiper" class="swiper-container" :options="swiperOption" >
          <template v-for="(item, index) in slideVisualList">
            <swiper-slide  :key="index" :style="`background-image:url(${item.imgUrl})`">
            </swiper-slide>
          </template>
        </swiper>
      </section>

      <!-- Seed Vault Status -->
      <section class="sv-status__wrap sv-section">
        <div class="sv-status__title">
          <div class="status-top">
              <h3 class="section-title">SEED STORAGE STATUS</h3>
          </div>
        </div>
        <div class="sv-status__content layout" v-html="$sanitize(svStatus)">
        </div>
        <div class="sv-status__bg">
          <svg class="polygon-1" width="920" height="804" viewBox="0 0 694 804" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-103.045 175.969C-130.54 73.3568 -36.643 -20.5402 65.9696 6.9548L589.507 147.236C692.12 174.731 726.488 302.997 651.371 378.115L268.115 761.371C192.997 836.488 64.7313 802.12 37.2362 699.507L-103.045 175.969Z" stroke="#DE9C69" stroke-opacity="0.5" stroke-width="4"/>
          </svg>

          <svg class="polygon-2" width="650" height="538" viewBox="0 0 518 538" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M170.422 71.2497C223.345 -20.4169 355.655 -20.4168 408.579 71.2499L557.102 328.5C610.026 420.167 543.871 534.75 438.023 534.75H140.977C35.1289 534.75 -31.0256 420.167 21.8982 328.5L170.422 71.2497Z" stroke="#449D7D" stroke-opacity="0.4" stroke-width="5"/>
          </svg>

          <div class="circle circle-1 bg-orange"></div>
          <div class="circle circle-2 bg-olive"></div>
          <div class="bar bar-1 bg-green"></div>
          <div class="bar bar-2 bg-orange"></div>
        </div>
      </section>

      <section class="sv-banner sv-section banner1"></section>

      <!-- News -->
      <!-- 2024.04.30. 시드볼트 메인 수정
      <section class="sv-news__wrap sv-section">
        <div class="layout">
          <h3 class="section-title">NEWS</h3>
          <ul class="news-list main-news__list">
            <li v-for="(item, index) in slideNewsList" :key="index">
              <a :href="`/seed/contents/dynamicDetail?menuId=M0012&upMenuId=M0004&s0&postNo=${item.postNo}`" class="news-content" :title="`${item.title}_Move Page`">
                <div class="news-img" :style="{'background-image':`url(${item.imgUrl})`}"></div>
                <div class="news-txt">
                  <h4 class="truncate-2">{{item.title}}</h4>
                  <p class="truncate-4">{{item.ctnt}}</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
      -->
      <section class="sv-news__wrap sv-section">
        <div class="layout">
          <ul class="news-list main-news__list">
            <li>
              <div class="news-img new-img-1"></div>
              <div class="news-txt">
                <h4 class="truncate-2">PURPOSE</h4>
                <p class="truncate-4">The Baekdudaegan Global Seed Vault in the Republic of Korea stores seeds of wild plants collected from around the world.</p>
                <a class="download-btn" href="/seed/contents/content?menuId=M0007&upMenuId=M0001&s0">Read More</a>
              </div>
            </li>
            <li>
              <div class="news-img new-img-2"></div>
              <div class="news-txt">
                <h4 class="truncate-2">FOR DEPOSITORS</h4>
                <p class="truncate-4">The Baekdudaegan Global Seed Vault stores and manages deposited seeds free of charge.</p>
                <a class="download-btn" href="/seed/contents/content?menuId=M0009&upMenuId=M0002&s0">Read More</a>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <!-- 2024.05.14. 시드볼트 메인 수정
      <section class="sv-banner sv-section banner2"></section>-->
    </main>
    <layoutFooter/>
    <div class="header_dimmed" :style="overlayStyle"></div>
    <template v-if="$route.path =='/seed'">
      <template v-for="(item, index) in layerPopupList">
        <div v-if="doCookie(item)" class="layer-popup lplp" :style="item.layerStyle != null? item.layerStyle :doLayerStyle(item)" :key="index">
          <div v-html="item.content" class="layerCont"></div>
          <div class="today-close">
            <ul>
              <input type="checkbox" v-if="item.nextCloseYn == 'Y'" v-model="item.layerNextCloseYn" true-value="Y" false-value="N" tabindex="1">
              <label for="next_close" v-if="item.nextCloseYn == 'Y'">{{ item.nextCloseDd }}{{$t('nextCloseDdText')}}</label>
              <input type="button" value="닫기" @click="doNextClose(item, item.layerNextCloseYn)" class="ct_bt01" tabindex="1">
            </ul>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import layoutTop from '@/views_seed/layouts/LayoutTop'
import layoutFooter from '@/views_seed/layouts/LayoutFooter'
import 'swiper/css/swiper.css'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'

export default {
  metaInfo() {
    return {
      title: this.metaInfo.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: this.metaInfo.ogTitle }, 
        { property: 'og:url', content: this.metaInfo.ogUrl },
        { property: 'og:description', content: this.metaInfo.ogDescription },
        { property: 'og:image', content: this.metaInfo.ogImage },
      ]
    }
  },
  components: {
    layoutTop,
    layoutFooter,
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters([
      'metaInfo'
    ])
  },
  data() {
    return {
      svStatus: '',
      swiperOption:{
        loop: true ,
        autoplay: {delay: 3000, disableOnInteraction: false,},
        speed:1000,
        slidesPerView: 1
      },
      swiperOption2:{
        slidesPerView: 1,
        spaceBetween: 45,
        navigation: {
          nextEl: '.sv-news__wrap .swiper-button-next',
          prevEl: '.sv-news__wrap .swiper-button-prev'
        },
        breakpoints: {
          750: {
            slidesPerView: 2
          },
          950: {
            slidesPerView: 3
          }
        }
      },
      slideVisualList:[],
      slideNewsList:[],
      isTotalSearch: false,
      keyword: '',
      layerPopupList: [],
      windowPopupList: [],
      overlayStyle: {display:'none'},
      dispYn: 'none',
      swiperOverCnt: 3
    }  
  },
  created(){
    window.addEventListener('DOMContentLoaded', this.sectionActiveByScroll);
    window.addEventListener('scroll', this.sectionActiveByScroll);
  },
  mounted(){
    this.doMain()
  },
  methods: {
    sectionActiveByScroll() {
      const scrollY = window.scrollY;
      const sections = document.querySelectorAll('.sv-section');
      sections.forEach(item => {
        const posY = item.getBoundingClientRect().top;
        if(scrollY >= posY - 100) {
          item.classList.add('active');
        }
      });
    },
    doMain() {
      this.$tran('/site/external/main/selectMainSeed', {}).then(response => {
        this.slideVisualList = response.data.mainSlideList;
        this.layerPopupList = response.data.layerPopupList;
        this.windowPopupList = response.data.windowPopupList;
        this.slideNewsList = response.data.noticeList.filter((e,i)=>{
                                                                        if(i < 3) {
                                                                          return true;
                                                                        }
                                                                    })

        this.setMetaInfo();
        this.$nextTick(() => {
          this.sectionActiveByScroll()
          if(this.windowPopupList != null && this.windowPopupList.length > 0 && this.$route.path =='/seed') {
            this.doWindowPopup();
          }
          if(this.slideVisualList == null || this.slideVisualList.length < 2){
            this.$refs.swiper.$swiper.allowTouchMove = false
            this.$refs.swiper.$swiper.autoplay.stop()
          }
          
          // if(this.$refs.swiper2.$swiper.currentBreakpoint == "750"){
          //   this.swiperOverCnt = 2
          // }else if(this.$refs.swiper2.$swiper.currentBreakpoint == "950"){
          //   this.swiperOverCnt = 3
          // }else{
          //   this.swiperOverCnt = 1
          // }

          // if(this.slideNewsList == null || this.slideNewsList.length < this.swiperOverCnt+1){
          //   this.$refs.swiper2.$swiper.allowTouchMove = false
          //   this.$refs.swiper2.$swiper.autoplay.stop()
          // }

          this.svStatus = response.data.status;
        })
      })
    },
    // 메타태그
    setMetaInfo() {
      const url = document.location.href;
      const img = '/logo.png';

      const metaInfoData = {
        title: `Baekdudaegan Global Seed Vault (BGSV)`,
        ogTitle: `Baekdudaegan Global Seed Vault (BGSV)`,
        ogUrl: url,
        ogDescription: `Baekdudaegan Global Seed Vault (BGSV)`,
        ogImage: img,
      };

      this.$store.dispatch('setMetaInfo', metaInfoData); 
    },
    onTotalSearch() {
      this.isTotalSearch = !this.isTotalSearch;
    },
    doTotalSearch() {
      if(this.keyword != null && this.keyword != '') {
        this.onTotalSearch();
        this.$router.push({name:'seed_totalSearch', query: {keyword: this.keyword, menuId: 'M0017', upMenuId: 'M0016'}});
      }
    },
    doTotalSearchFocus(event) {
      if(event.keyCode == 13) {
        this.doTotalSearch();
      }

      if(event.shiftKey && event.keyCode == 9){
        event.preventDefault();
        document.querySelector("#searchClose").focus()
      }
    },
    doWindowPopup() {
      for(let i=0; i < this.windowPopupList.length; i++) {
         
        const getCookie = this.doCookie(this.windowPopupList[i]);

        if(getCookie) {
          const data = this.windowPopupList[i]
          const popupNo = data.popupNo;
          const imgUrl = data.imgUrl;
          const width = data.widthPixel;
          let height = data.heightPixel;
          const left = data.leftPixel;
          const top = data.topPixel;
          const content = data.content;
          const nextCloseDd = data.nextCloseDd;
          const nextCloseYn = data.nextCloseYn;

          
          if(height != null && typeof height == 'string'){
            const intData = height.replaceAll(/(-)[^\d.]/gi, '')
            if(intData != null && intData != ''){
              height = (parseFloat(intData) + 30) + height.replaceAll(/[-\d.]/gi,'')
            }
          }
          
          const win = window.open('', popupNo, `location=no,width=${width},height=${height},top=${top},left=${left}`);

          if(win.document.querySelector('.newPopupDiv') != null) {
            win.document.querySelector('.newPopupDiv').remove();
            win.document.querySelector('.popup').remove();
          }

          if(win.document.querySelector('.footer') != null) {
            win.document.querySelector('.footer').remove();
          }

          if(win.document.querySelector('script') != null) {
            win.document.querySelector('script').remove();
          }

          if(win.document.querySelector('style') != null) {
            win.document.querySelector('style').remove();
          }
          
          const htmlTag = `
                    <style>
                      body {
                        margin: 0px;
                      }

                      img {
                        width: 100%;
                      }

                      p br{
                        display:none;
                      }

                      table, pre,div {
                        color: #575757; 
                        font-size: 1em;
                      }

                      .newPopupDiv {
                        height: calc(100% - 30px);
                        width: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-image: url(${encodeURI(imgUrl)});
                      }

                      #next_close {
                        margin-top: 9px;
                      }

                      div.popup {
                        clear: both;
                        position: relative;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        text-align: right;
                      }

                      div.popup .ct_bt01 {
                        margin-right:10px;
                        margin-left:10px;
                      }

                      @media(max-width: 736px) {
                        .mmimg_w {
                          width:100% !important;
                          height: calc(100% - 30px) !important;
                          top: 0 !important;
                          left: 0 !important;
                        }

                        .mmimg_w, .mmimg_in, .mmmimg{
                          width: 100% !important;
                          height: calc(100% - 30px) !important;
                        }

                        .mmmimg p, .mmmimg img {
                          width: 100% !important;
                          height: auto;
                        }
                      }
                  </style>
                  <div>
                    <div class="mmmimg newPopupDiv">
                      ${content==null?'':content}
                    </div> 
                    <div class="popup">
                      ${nextCloseYn=='Y'? `<input type="checkbox" id="next_close">${nextCloseDd} ${this.$t('nextCloseDdText')}`:''}
                      <input type="button" value="닫기" onClick="closeToday()" class="ct_bt01">
                    </div>  
                  </div> 
                  <script type="text/javascript">
                    function closeToday() {
                      ${nextCloseYn=='Y'? `var isNextClose = document.getElementById('next_close').checked;

                      if(isNextClose) {
                        var todayDate = new Date();
                        todayDate.setDate( todayDate.getDate() + ${nextCloseDd} );
                        document.cookie = name + "= Y" + "; path=/; expires=" + todayDate.toGMTString() + ";"
                      }
                      `:''}
                      window.close();
                    }
                  <\/script>`;
          win.document.write(htmlTag);
        }
      }
    }, 
    onOverlay(displayBlock='block'){
      this.overlayStyle.display=displayBlock;
      this.dispYn=displayBlock
      if(displayBlock != 'block'){
        this.$refs.topMenu.doParentMouseLeave()
      }
    },
    doCookie(item) {
      const data = this.$cookies.get(item.popupNo);

      if(data != null) {
        return false;
      }
      return true;
    },
    doNextClose(item, layerNextCloseYn) {
      if(layerNextCloseYn == 'Y') {
        this.$cookies.set(item.popupNo, 'Y', `${item.nextCloseDd}d`);
      }
      
      this.doLayerClose(item);
    },
    doLayerClose(item) {
      item.layerStyle.display = 'none';
    },
    doLayerStyle(item) {
      const width = item.widthPixel;
      const height = item.heightPixel;
      const left = item.leftPixel;
      const top = item.topPixel;

      const layerStyle = {
        backgroundImage: `url(${encodeURI(item?.imgUrl)})`,
        width: `${width}px`,
        height: `${Number(height)+30}px`,
        left: `${left}px`,
        top: `${top}px`,
        display: 'block',
        'background-size': 'contain',
        backgroundRepeat: 'no-repeat'
      }

      this.$set(item, 'layerStyle', layerStyle);
    },
    doTotalSearchKeyDown(e) {
      if(!e.shiftKey && e.keyCode == 9) {
        e.preventDefault();
        document.querySelector("#searchInput").focus();
      }
    }
  },
}
</script>