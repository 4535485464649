<template>
  <div class="sv-pagination">
    <button type="button" class="pagination-pn prev" @click="doPage(pagingInfo.prevPage)" @keydown="doKeydownLink($event, pagingInfo.prevPage)" :disabled="pagingInfo.prevPage < 1"></button>
    <div class="pagination-nums">
      <template v-for="(index) in (pagingInfo.lastPage - pagingInfo.firstPage) + 1">
        <a @click="doPage((index+pagingInfo.firstPage)-1)" @keydown="doKeydownLink($event, (index+pagingInfo.firstPage)-1)" :class="(index+pagingInfo.firstPage)-1 == currentPage?'active':''" :key="index">{{(index+pagingInfo.firstPage)-1}}</a>
      </template>
    </div>
    <button type="button" class="pagination-pn next" @click="doPage(pagingInfo.nextPage)" @keydown="doKeydownLink($event, pagingInfo.nextPage)" :disabled="pagingInfo.nextPage < 1"></button>
  </div>
</template>

<script>
export default {
  props: {
    pagingInfo: {
      type: Object
    },
    currentPage: {
    },
    tabindex: {
      type: Number,
      default: 1
    },
  },
  methods: {
    doPage(index) {
      this.$emit("input", index)
    },
    doKeydownLink(event, index) {
      if(event.keyCode == 13) {
        this.doPage(index);
      }
    }
  }
}
</script>