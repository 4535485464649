<template>
  <!-- Footer -->
  <footer class="c-footer">
    <div class="layout">
      <div class="ft-content">
        <img src="@/assets/img/eng/logo_vertical.svg" alt="KOREA ARBORETA AND GARDENS INSTITUTE Logo"/>
        <div class="ft-info">
        <h5>Korea Arboreta and Gardens Institute</h5>
        <p class="info-txt">
            3rd floor of SM Tower, 10 Jeongbu 2 Cheongsa-ro, Sejong city<br/>
            Republic of Korea, Post Code : 30129<br/>
            Contact info &nbsp;&nbsp;&nbsp;Tel : +82-44-270-5114 / Email : info@koagi.or.kr <!-- &nbsp;&nbsp;&nbsp;&nbsp;info@koagi.or.kr -->
        </p>
        </div>
        <div class="ft-combobox" :class="comboBoxClass">
          <button class="ft-combobox__btn" @click="toggleCombobox()">
            <p class="combobox-value">Family Site</p>
          </button>
          <ul class="ft-combobox__list">
            <li v-for="(item,index) in siteList" :key="index">
              <a :href="item.url" :target="item.newWinYn=='Y'?'_blank':'_self'" :data-value="item.title" :title="item.url != null && item.url != ''?(item.newWinYn=='Y'?'New window opening':'Move Page'):''">
                {{ item.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p class="copyright">
        ⓒKorea Arboreta and Gardens Institute. All Rights Reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    siteList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      comboBoxClass: '',

    }
  },
  methods: {
    toggleCombobox() {
      if(this.comboBoxClass == 'active') {
        this.comboBoxClass = ''
      }else{
        this.comboBoxClass = 'active'
      }
    }
  }
}
</script>
