import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    treeData: {},
    subMenuTitle: '',
    subMenuBreadcrumb: '',
    subMenuBreadcrumbTitle: '',
    certData: '',
    metaInfo: {},
    popupInfo: '',
    siteContext: ''
  },
  getters: {
    treeData(state) {
      return state.treeData
    },
    subMenuTitle(state) {
      return state.subMenuTitle
    },
    subMenuBreadcrumb(state) {
      return state.subMenuBreadcrumb
    },
    subMenuBreadcrumbTitle(state) {
      return state.subMenuBreadcrumbTitle
    },
    certData(state) {
      return state.certData
    },
    metaInfo(state) {
      return state.metaInfo
    },
    popupInfo(state){
      return state.popupInfo
    },
    siteContext(state) {
      return state.siteContext
    },
  },
  mutations: {
    setTreeData(state, __treeData){
      state.treeData = __treeData
    },
    setSubMenuTitle(state, __subMenuTitle){
      state.subMenuTitle = __subMenuTitle
    },
    setSubMenuBreadcrumb(state, __subMenuBreadcrumb){
      state.subMenuBreadcrumb = __subMenuBreadcrumb
    },
    setSubMenuBreadcrumbTitle(state, __subMenuBreadcrumbTitle){
      state.subMenuBreadcrumbTitle = __subMenuBreadcrumbTitle
    },
    setCertData(state, __certData) {
      state.certData = __certData
    },
    setMetaInfo(state, __metaInfo) {
      state.metaInfo = __metaInfo
    },
    setPopupInfo(state, __popupInfo) {
      state.popupInfo = __popupInfo
    },
    setSiteContext(state, __siteContext) {
      state.siteContext = __siteContext
    },
  },
  actions: {
    setTreeData({commit}, __treeData){
      return new Promise((resolve, reject) => {
        commit('setTreeData' , __treeData);
        resolve(__treeData);
      });
    },
    setSubMenuTitle({commit}, __subMenuTitle){
      return new Promise((resolve, reject) => {
        commit('setSubMenuTitle' , __subMenuTitle);
        resolve(__subMenuTitle);
      });
    },
    setSubMenuBreadcrumb({commit}, __subMenuBreadcrumb){
      return new Promise((resolve, reject) => {
        commit('setSubMenuBreadcrumb' , __subMenuBreadcrumb);
        resolve(__subMenuBreadcrumb);
      });
    },
    setSubMenuBreadcrumbTitle({commit}, __subMenuBreadcrumbTitle){
      return new Promise((resolve, reject) => {
        commit('setSubMenuBreadcrumbTitle' , __subMenuBreadcrumbTitle);
        resolve(__subMenuBreadcrumbTitle);
      });
    },
    setCertData({commit}, __certData) {
      return new Promise((resolve, reject) => {
        commit('setCertData', __certData);
        resolve(__certData)
      })
    },
    setMetaInfo({commit}, __metaInfo) {
      return new Promise((resolve, reject)=> {
        commit('setMetaInfo', __metaInfo);
        resolve(__metaInfo)
      })
    },
    setPopupInfo({commit}, __popupInfo) {
      return new Promise((resolve, reject)=> {
        commit('setPopupInfo', __popupInfo);
        resolve(__popupInfo)
      })
    },
    setSiteContext({commit}, __siteContext) {
      return new Promise((resolve, reject)=> {
        commit('setSiteContext', __siteContext);
        resolve(__siteContext)
      })
    },
  },
  modules: {
  }
})
